 <!-- 
<nz-tabset nzCentered (nzSelectedIndexChange)="tabChanged($event)">
 Dynamic tabs from tabImposta array 
  <nz-tab *ngFor="let tab of tabImposta" [nzTitle]="tab">
   
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </nz-tab>
</nz-tabset>
-->

<!-- Define Form Content as a Template -->

  <div class="form-container cardContent">
    <form (ngSubmit)="onSubmit()">
      <nz-avatar class="center-imposta" [nzSize]="120" nzIcon="user"></nz-avatar>

      <div class="form-item">
        <label class="form-label" for="firstName">Nominativo</label>
        <input type="text" id="firstName" [(ngModel)]="firstName" name="firstName" class="form-control" disabled="showPasswordModal">
      </div>

      <div class="form-item">
        <label class="form-label" for="email">Email</label>
        <input type="email" id="email" [(ngModel)]="email" name="email" class="form-control" disabled="showPasswordModal">
      </div>

      <div class="form-item">
        <label class="form-label" for="phone">Telefono</label>
        <input type="tel" id="phone" [(ngModel)]="phone" name="phone" class="form-control" disabled="showPasswordModal">
      </div>

      <div class="form-item">
        <button type="button" *ngIf="false" class="btn btn-primary" (click)="openPasswordModal()">Cambio Password</button>
      </div>

    </form>
  </div>



<div class="form-container cardContent">
<div class="modal" tabindex="2">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Imposta Password</h5>
        <button type="button" class="btn-close" (click)="closePasswordModal()"></button>
      </div>
      <div class="modal-body">
        <div class="form-item">
          <label class="form-label" for="newPassword">Nuova Password</label>
          <input type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" class="form-control">
        </div>
        <div class="form-item">
          <label class="form-label" for="confirmPassword">Conferma Password</label>
          <input type="password" id="confirmPassword" [(ngModel)]="confirmPassword" name="confirmPassword" class="form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closePasswordModal()">Annulla</button>
        <button type="button" class="btn btn-primary" (click)="setPassword()">Salva Password</button>
      </div>
    </div>
  </div>
</div>
</div>
