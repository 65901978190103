<div class="uiLoginContainer">
    <img class="uiUncollapsedLogo" src="../../../assets/logo-light.png" alt="logo">
    <div class="uiFormContainer">
        <p class="uiDescriptionText" [innerHTML]="'descriptionText' | translate"></p>
        <input type="text" nz-input [(ngModel)]="username" placeholder="Inserisci Email:" (keydown.enter)="onSubmit()" style="margin-bottom: 1rem;" />
        <div class="password-container">
            <input
              [type]="isPasswordVisible ? 'text' : 'password'"
              nz-input
              [(ngModel)]="password"
              placeholder="Inserisci Password: "
              (keydown.enter)="onSubmit()"
              class="password-input"
            />
            <i
              class="toggle-password-icon"
              [class]="isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
              (click)="togglePasswordVisibility()"
              aria-hidden="true"
            ></i>
          </div>          
        <!-- <a (click)="showRecoverModal()">Recupera la password</a> -->
        <!-- <br /> -->
        <button nz-button nzSize="large" (click)="onSubmit()" nzType="primary" class="loginBtn">{{'login' |
            translate}}</button>
        <br /><br />
        <button nz-button nzSize="large" (click)="goTo('pin')" nzType="primary" class="loginBtn marginBtn pinloginBtn">{{'pinlogin' |
            translate}}</button>
        <p *ngIf="showError" class="errorMessage">{{'wrongLogin' | translate}}</p>

    </div>
</div>

<nz-modal [(nzVisible)]="recoverVisible" nzTitle="" nzFooter="null" [nzContent]="recoverContent" nzWrapClassName="innerRecoverModal" (nzOnCancel)="handleCancel()">
    <ng-template #recoverContent>
        <h1>{{'recoverModalTitle' | translate}}</h1>
        <p class="modalDesc" [innerHTML]="'recoverModalText' | translate"></p>
        <input type="text" nz-input [(ngModel)]="username" placeholder="Email" />

        <p *ngIf="modalSuccess" class="successRecoverMessage">{{'successRecover' | translate}}</p>
        <p *ngIf="modalError" class="errorRecoverMessage">{{'errorRecover' | translate}}</p>

        <button nz-button nzSize="large" (click)="recover()" nzType="primary" class="loginBtn" [nzLoading]="isWaiting">{{'recover' |
            translate}}</button>
    </ng-template>
</nz-modal>