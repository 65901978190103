import { Component, HostListener, OnInit } from '@angular/core';
import { FireService } from 'src/app/services/fire.service';
import { GlobalService } from 'src/app/shared/global.service';
import * as env from "src/environments/env.json";

@Component({
  selector: 'app-forzatura',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  constructor(
    public g: GlobalService,
    private fire: FireService
  ) { }
  scroll = { y: '' };
  valText = '';
  version = '';
  quest = '';

  env: any = (env as any).default;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight()
  }

  setScrollHeight() {
    if (document.getElementsByClassName('uiQustAttendBody').length > 0) {
      let height = document.getElementsByClassName('uiQustAttendBody')[0].clientHeight;
      if (document.getElementsByClassName('uiQustAttendBody')[0].getElementsByClassName('ant-table-thead').length) {
        let headH = document.getElementsByClassName('uiQustAttendBody')[0].getElementsByClassName('ant-table-thead')[0].clientHeight;
        this.scroll = { y: height - headH + 'px' };
      } else {
        this.scroll = { y: height - 24 + 'px' };
      }
    }
  }

  private handleError(error): void {
    console.error('An error occurred:', error.message);
  }
  

  async ngOnInit(): Promise<void> {
    try {
      const quest = this.g.temp_quest_id;
      const version = this.g.activeId.split(quest)[1];
  
      if (!quest || !version) {
        throw new Error('Invalid quest or version. Cannot fetch document.');
      }
  
      const licenseData = await this.fetchLicenseData(quest, version);
      this.valText = licenseData?.emailMessaggio ?? '';
  
      this.adjustScrollHeight();
    } catch (error) {
      this.handleError(error);
    }
  }
  
  private async fetchLicenseData(quest: string, version: string): Promise<any> {
    const documentSnapshot = await this.fire.getDocOnce('questionari', quest, 'versioni', version).toPromise();
  
    if (!documentSnapshot.exists) {
      this.handleError(new Error('No document found for the given quest and version.'));
      return {};
    }
  
    return documentSnapshot.data();
  }
  
  private adjustScrollHeight(): void {
    setTimeout(() => {
      this.setScrollHeight();
    }, 500);
  }
  
 
  save(): void {
    try {
      if (!this.valText || this.valText.trim() === '') {
        this.handleError(new Error('Validation failed: valText is empty.'));
        return;
      }
  
      const updatePayload = { emailMessaggio: this.valText };
  
      this.fire.update('questionari', 'versioni', updatePayload, this.g.temp_quest_id, this.g.activeId.split(this.g.temp_quest_id)[1]);

      this.g.createSuccessNotification("Completato", "Il tuo messaggio è stato salvato");
    } catch (error) {
      this.handleError(error);
    }
  }
  




}
