import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { Component, HostListener, OnInit, TemplateRef, ViewContainerRef, DoCheck } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzUploadChangeParam } from "ng-zorro-antd/upload";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { InterfaceService } from "src/app/shared/interface.service";
import { ImgModalComponent } from "./img-modal/img-modal.component";
import { firstValueFrom } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
@Component({
  selector: "app-clienti",
  templateUrl: "./clienti.component.html",
  styleUrls: ["./clienti.component.scss"],
})
export class ClientiComponent implements OnInit, DoCheck {
  reps: any[] = [];
  loading = true;
  openFolder: any[] = [];
  openAreas: any[] = [];
  tableH: string = "";
  displayedData: any[] = [];
  search = "";
  editCacheR: { [key: string]: { edit: boolean; data: any; }; } = {};
  editCacheA: { [key: string]: { [key2: string]: { edit: boolean; data: any; }; }; } = {};
  editCacheU: { [key: string]: { [key2: string]: { [key3: string]: { edit: boolean; data: any; }; }; }; } = {};
  successUpload: boolean[] = [];
  uploadedFile: string[] = [];
  uploadURL: string = "";
  activeUpload: string = "";
  manualChange = false;
  showError: boolean = false;
  showPwdError: boolean = false;
  pwdForm: UntypedFormGroup;
  username: any;
  onAdd: boolean = false;
  scroll = { y: "" };

  utentiImport: any[] = [];
  aziendaCheck: any = {};
  repartoCheck: any = {};
  isImporting: boolean = false;
  utentiImportCount: number = 0;
  addUser = false;

  CryptoJS = require("crypto-js");

  // ######################################
  // FUNCTION: constructor
  constructor(
    private node: NodeService,
    public g: GlobalService,
    public face: InterfaceService,
    private translate: TranslateService,
    private fire: FireService,
    private http: HttpClient,
    private msg: NzMessageService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fb: UntypedFormBuilder,
    private notification: NzNotificationService
  ) { }

  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiClientiTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiClientiTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiClientiTableContainer")[0].getElementsByClassName("ant-table-thead").length
      ) {
        let headH = document
          .getElementsByClassName("uiClientiTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }

  // ######################################
  // FUNCTION: ngAfterViewInit
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("clienti");
    });
  }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.uploadURL = this.g.nodePath + "/clienti/upload";
    this.fire.getAllAsc("clienti", "codice").subscribe((res) => {
      this.reps = res;
      this.displayedData = this.reps.sort((a, b) => {
        const numA = parseInt(a.id.replace(/\D/g, ''));
        const numB = parseInt(b.id.replace(/\D/g, ''));
        return numB - numA;
      });


      if (res.length === 0) {
        this.updateEditCacheR();
      }
      this.updateEditCacheR();
    });
  }

  ngDoCheck(): void {
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: updateEditCacheR
  updateEditCacheR(): void {
    if (this.reps.length > 0) {
      this.reps.forEach((item) => {
        this.editCacheR[item.id] = {
          edit: false,
          data: { ...item },
        };
        this.editCacheA[item.id] = {};
        this.editCacheU[item.id] = {};

        if (item.reparti) {
          this.updateEditCacheA(item);
        } else {
          this.loading = false;
        }
      });
    } else {
      this.setScrollHeight();
    }
  }

  // ######################################
  // FUNCTION: updateEditCacheA
  updateEditCacheA(item): void {
    if (item.reparti && item.reparti.length > 0) {
      item.reparti.forEach((rep) => {
        this.editCacheA[item.id][rep.id] = {
          edit: false,
          data: { ...rep },
        };
        this.editCacheU[item.id][rep.id] = {};
        this.updateEditCacheU(item, rep);
      });
    } else if (item.reparti.length === 0) {
      this.loading = false;
      this.setScrollHeight();
    }
  }

  // ######################################
  // FUNCTION: updateEditCacheU
  updateEditCacheU(item, rep): void {
    if (rep.utenti && rep.utenti.length > 0) {
      rep.utenti.forEach((user, ui) => {
        this.editCacheU[item.id][rep.id][user.id] = {
          edit: false,
          data: { ...user },
        };
        if (ui === rep.utenti.length - 1) {
          this.loading = false;
          this.setScrollHeight();
        }
      });
    } else {
      this.loading = false;
      this.setScrollHeight();
    }
  }

  // ######################################
  // FUNCTION: onChangeSwitchR
  onChangeSwitchR(e: any, i: number) {
    this.reps[i].attivo = e;
    let r: any = {};
    r.codice = this.reps[i].codice;
    r.ragione_sociale = this.reps[i].ragione_sociale;
    r.attivo = this.reps[i].attivo;
    this.fire.updateCollection("clienti", r, this.reps[i].id);
  }

  // ######################################
  // FUNCTION: onChangeSwitchA
  onChangeSwitchA(e: any, i: number, ai: number) {
    let area: any = {};
    area.attivo = e;
    this.fire.update("clienti", "reparti", area, this.reps[i].id, this.reps[i].reparti[ai].id);
  }

  // ######################################
  // FUNCTION: onChangeType
  onChangeType(e: any, i: number, ai: number, ui: number) {
    let user = this.displayedData[i].reparti[ai].utenti[ui];
    user.sendtype = e;
    this.fire.updateSubDoc(
      "clienti",
      "reparti",
      "utenti",
      user,
      this.displayedData[i].id,
      this.displayedData[i].reparti[ai].id,
      user.id
    );
  }

  // ######################################
  // FUNCTION: onChangeSwitchU
  onChangeSwitchU(e: any, i: number, ai: number, ui: number) {
    let user = this.displayedData[i].reparti[ai].utenti[ui];
    if (!this.g.checkEmail(user.email)) {
      user.attivo = e;
      this.fire.updateSubDoc(
        "clienti",
        "reparti",
        "utenti",
        user,
        this.displayedData[i].id,
        this.displayedData[i].reparti[ai].id,
        user.id
      );
      this.fire.update("clienti", "utenti", user, this.displayedData[i].id, user.id);

      if (this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][user.id].edit) {
        this.onAdd = true;
        this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][user.id].data.attivo = e;
      }
    } else {
      user.attivo = false;
      this.g.createErrorNotification("Impossibile attivare l'utente", "Fornire un' email e una password");
    }
  }


  onChangeSwitchTitolare(e: any, i: number, ai: number, ui: number) {
    let user = this.displayedData[i].reparti[ai].utenti[ui];
    user.titolare = e;
    this.fire.updateSubDoc(
      "clienti",
      "reparti",
      "utenti",
      user,
      this.displayedData[i].id,
      this.displayedData[i].reparti[ai].id,
      user.id
    );
    this.fire.update("clienti", "utenti", user, this.displayedData[i].id, user.id);

    if (this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][user.id].edit) {
      this.onAdd = true;
      this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][user.id].data.titolare = e;
    }

  }

  // ######################################
  // FUNCTION: openArea
  async openArea(i: number) {
    this.openAreas[i] = [];
    // let e = this.reps[i];
    let e = this.displayedData[i];
    await this.fire.getCollection("clienti", e.id, "reparti").subscribe((res2) => {

      e.reparti = res2.sort(this.g.customSort);
      // console.log(e.reparti);
      if (res2.length === 0) {
        this.updateEditCacheR();
      }
      for (let j = 0; j < res2.length; j++) {
        let a = res2[j];
        this.fire.getSubCollection("clienti", e.id, "reparti", a.id, "utenti").subscribe((res3) => {
          if (!this.onAdd) {
            e.n_utenti = res3.length;
            a.utenti = res3;
            this.updateEditCacheR();
          } else {
            e.n_utenti = res3.length;
            a.utenti = res3;
          }
        });
      }
    });
    if (this.openFolder[i]) {
      this.openFolder[i] = false;
    } else {
      this.openFolder[i] = true;
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: openusers
  openusers(i: number, j: number) {
    if (this.openAreas[i][j]) {
      this.openAreas[i][j] = false;
    } else {
      this.openAreas[i][j] = true;
      this.loading = false;
    }
  }

  // ######################################
  // FUNCTION: editR
  editR(id) {
    this.editCacheR[id].edit = true;
  }

  // ######################################
  // FUNCTION: cancelEditR
  cancelEditR(id: string): void {
    const index = this.reps.findIndex((item) => item.id === id);
    this.editCacheR[id] = {
      data: { ...this.reps[index] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: saveEditR
  saveEditR(id: string): void {
    const index = this.reps.findIndex((item) => item.id === id);
    Object.assign(this.reps[index], this.editCacheR[id].data);
    this.editCacheR[id].edit = false;
    this.displayedData = [...this.reps];
    let r: any = {};
    r.codice = this.reps[index].codice;
    r.ragione_sociale = this.reps[index].ragione_sociale;
    this.fire.updateCollection("clienti", r, this.reps[index].id);
  }

  // ######################################
  // FUNCTION: editA
  editA(id1, id2) {
    this.editCacheA[id1][id2].edit = true;
  }

  // ######################################
  // FUNCTION: saveEditA
  saveEditA(i: number, ai: number, id1: string, id2: string): void {
    if (this.editCacheA[id1][id2].data.nome === "" || this.editCacheA[id1][id2].data.nome === undefined || this.editCacheA[id1][id2].data.nome.length > 30) {
      this.g.createErrorNotification("Nome reparto", "Il nome del reparto non può essere vuoto o superare i 30 caratteri.");
     }
    else {
      Object.assign(this.displayedData[i].reparti[ai], this.editCacheA[id1][id2].data);
      this.editCacheA[id1][id2].edit = false;
      this.displayedData = [...this.reps];

      let area = this.reps[i].reparti[ai];
      this.fire.update("clienti", "reparti", area, this.reps[i].id, this.reps[i].reparti[ai].id);
    }
  }

  // ######################################
  // FUNCTION: cancelEditA
  cancelEditA(i: number, ai: number, id1: string, id2: string): void {

    this.editCacheA[id1][id2] = {
      data: { ...this.reps[i].reparti[ai] },
      edit: false,
    };
  }

  // ######################################
  // FUNCTION: editU
  editU(id1, id2, id3) {
    this.editCacheU[id1][id2][id3].edit = true;
  }

  // ######################################
  // FUNCTION: saveEditU
  saveEditU(i: number, ai: number, ui: number, id1: string, id2: string, id3: string): void {
    this.addUser = false;
    const checkEmail: boolean = this.g.checkEmail(this.editCacheU[id1][id2][id3].data.email);
    const checkMobile: boolean = this.g.checkMobile(this.editCacheU[id1][id2][id3].data.mobile);

    if (!checkEmail && this.editCacheU[id1][id2][id3].data.email) {
      this.g.createErrorNotification("Problema", "L'indirizzo email inserito non è valido.");
    } else if (!checkMobile && this.editCacheU[id1][id2][id3].data.mobile) {
      this.g.createErrorNotification("Problema", "Il telefono cellulare inserito non è valido.");
    } else if (this.onAdd && (checkEmail || checkMobile)) {
      let newUser = this.editCacheU[id1][id2][id3].data;
      newUser.sendtype = newUser.email ? false : true;

      this.fire
        .addSubCollection(
          "clienti",
          "reparti",
          "utenti",
          newUser,
          this.displayedData[i].id,
          this.displayedData[i].reparti[ai].id,
          newUser.id
        )
        .then((newU) => {
          this.onAdd = true;
          this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][newUser.id].edit = false;
          if (this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][newUser.id].data.admin) {
            this.showManualChange(i, ai, ui);
          }
          this.onAdd = false;
        })
        .catch((err) => {
          console.error(err);
        });
      this.fire.addCollection("clienti", "utenti", newUser, this.displayedData[i].id, newUser.id);
    } else if (!checkEmail && !checkMobile) {
      this.g.createErrorNotification("Problema", "È necessario inserire un indirizzo email o un cellulare valido.");
    } else {
      Object.assign(this.reps[i].reparti[ai].utenti[ui], this.editCacheU[id1][id2][id3].data);
      this.editCacheU[id1][id2][id3].edit = false;
      this.displayedData = [...this.reps];

      let user = this.reps[i].reparti[ai].utenti[ui];
      user.sendtype = user.email ? false : true;

      this.fire.updateSubDoc(
        "clienti",
        "reparti",
        "utenti",
        user,
        this.reps[i].id,
        this.reps[i].reparti[ai].id,
        user.id
      );
      this.fire.update("clienti", "utenti", user, this.displayedData[i].id, user.id);
    }
  }

  // ######################################
  // FUNCTION: cancelEditU
  cancelEditU(i: number, ai: number, ui: number, id1: string, id2: string, id3: string): void {
    this.addUser = false;
    if (!this.onAdd) {
      this.editCacheU[id1][id2][id3] = {
        data: { ...this.displayedData[i].reparti[ai].utenti[ui] },
        edit: false,
      };
    } else {
      this.displayedData[i].reparti[ai].utenti.splice(ui, 1);
    }
  }

  // ######################################
  // FUNCTION: newR
  newR() {
    this.fire.getLastBy("clienti", "codice").then((res) => {
      let newRep = {
        attivo: true,
        codice: res + 1,
        id: "A" + (res + 1),
        ragione_sociale: "Nuovo cliente",
        reparti: [],
        admin: false,
        n_utenti: 0,
        logo: "",
      };
      this.openAreas[newRep.id] = [];
      this.editCacheR[newRep.id] = {
        edit: false,
        data: { ...newRep },
      };
      this.editCacheA[newRep.id] = {};
      this.editCacheU[newRep.id] = {};

      let appRep = {
        attivo: true,
        codice: res + 1,
        id: "A" + (res + 1),
        ragione_sociale: "Nuovo cliente",
        admin: false,
        n_utenti: 0,
        logo: "",
      };

      this.fire
        .add("clienti", appRep, appRep.id)
        .then()
        .catch((err) => {
          console.error(err);
        });
    });
  }

  // ######################################
  // FUNCTION: newA
  newA(i: number) {
    this.fire.getLastSubBy("clienti", this.displayedData[i].id, "reparti", "codice").then((res) => {
      let newArea = {
        attivo: true,
        codice: res + 1,
        id: "R" + (res + 1),
        nome: "Nuovo reparto",
        utenti: [],
      };
      this.editCacheA[this.displayedData[i].id][newArea.id] = {
        edit: false,
        data: { ...newArea },
      };
      this.fire
        .addCollection("clienti", "reparti", newArea, this.displayedData[i].id, newArea.id)
        .then((result: any) => {
          if (!this.openFolder[i]) this.openArea(i);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  // ######################################
  // FUNCTION: newU
  newU(i, ai) {
    this.addUser = true;
    this.fire
      .getLastSubSubBy(
        "clienti",
        this.displayedData[i].id,
        "reparti",
        this.displayedData[i].reparti[ai].id,
        "utenti",
        "codice"
      )
      .then((res) => {
        let newUser = {
          admin: false,
          attivo: true,
          codice: res + 1,
          descrizione: "Nuovo utente",
          email: "",
          mobile: "",
          sendtype: false,
          id: "U" + (res + 1),
          pwd: "",
          ultimo_accesso: {},
          reparto: this.displayedData[i].reparti[ai].id,
          titolare: false
        };

        this.displayedData[i].reparti[ai].utenti.unshift(newUser);
        this.onAdd = true;
        this.editCacheU[this.displayedData[i].id][this.displayedData[i].reparti[ai].id][newUser.id] = {
          edit: true,
          data: { ...newUser },
        };
        this.editU(this.displayedData[i].id, this.displayedData[i].reparti[ai].id, newUser.id);
        if (!this.openAreas[i][ai]) this.openusers(i, ai);
      });
  }

  // ######################################
  // FUNCTION: customFileReq
  customFileReq = (item: any) => {
    // Create a FormData here to store files and other parameters.
    const ext = item.file.name.substr(item.file.name.lastIndexOf("."));
    let filename = "";
    filename = this.activeUpload + "-logo" + ext;
    const formData = new FormData();
    formData.append("file", item.file as any);
    formData.append("filename", filename);
    const req = new HttpRequest("POST", item.action!, formData, {
      reportProgress: true,
    });

    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
        }
      },
      (err) => {
        item.onError!(err, item.file!);
      }
    );
  };

  // ######################################
  // FUNCTION: handleChange
  handleChange(info: NzUploadChangeParam, i: number): void {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      const ext = info.file.name.substring(info.file.name.lastIndexOf("."));
      this.uploadedFile[i] = this.displayedData[i].id + "-logo" + ext;
      this.displayedData[i].logo = this.uploadedFile[i];
      this.successUpload[i] = true;
      let r = {
        logo: this.displayedData[i].logo,
      };
      this.fire.updateCollection("clienti", r, this.displayedData[i].id);
    } else if (info.file.status === "error") {
      this.msg.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "removed") {
      this.msg.warning(`${info.file.name} file removed.`);
      this.successUpload[i] = false;
    }
  }

  // ######################################
  // FUNCTION: beforeUpload
  beforeUpload(code) {
    this.activeUpload = code;
  }

  // ######################################
  // FUNCTION: deleteFile
  deleteFile(i) {
    this.displayedData[i].logo = "";
    let r = {
      logo: this.displayedData[i].logo,
    };
    this.fire.updateCollection("clienti", r, this.displayedData[i].id);
  }

  // ######################################
  // FUNCTION: downloadFile
  downloadFile(path: string) {
    let params = { "filepath": path };
    this.node.getData(params, "/download").subscribe((data) => {
      if (data.status !== 200) {
        var fileURL: string = URL.createObjectURL(data);
        const modal = this.modal.create({
          nzTitle: "Visualizza logo",
          nzContent: ImgModalComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzClassName: "uiImgModal",
          nzFooter: null,
          nzComponentParams: {
            file: fileURL,
          },
          nzMaskClosable: true,
        });
      }
    });
  }

  // ######################################
  // FUNCTION: deleteR
  async deleteR(i: number) {
    let deleted = this.displayedData[i];
    // need for delete license
    try {
      const licensesList = await firstValueFrom(this.fire.getCollection("clienti", deleted.id, "licenze"));
      if (licensesList.some(license => license.attivo)) {
        this.notification.blank(
          'Impossibile cancellare',
          'Ci sono delle licenze anche attive, per procedere cancellale o disattivale',
          { nzDuration: 0 }
        );
        return;
      }
      licensesList.map(async license => {
        const deleteLicense = await this.fire.delete("clienti", "licenze", deleted.id, license.id);
      });
    } catch (err) {
      console.log(err);
      return;
    }
    this.fire
      .deleteDoc("clienti", deleted.id)
      .then((rD) => {
        deleted.reparti.forEach((a, aindex) => {
          let delRep = deleted.reparti[aindex];
          this.fire
            .delete("clienti", "reparti", deleted.id, deleted.reparti[aindex].id)
            .then((rA) => {
              delRep.utenti.forEach((u, uindex) => {
                this.fire
                  .deleteSubDoc("clienti", "reparti", "utenti", deleted.id, delRep.id, u.id)
                  .then()
                  .catch((err) => {
                    console.error(err);
                  });
              });
            })
            .catch((err) => {
              console.error(err);
            });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // ######################################
  // FUNCTION: deleteA
  deleteA(i: number, ai: number) {
    let deleted = this.displayedData[i];
    let delRep = this.displayedData[i].reparti[ai];
    this.fire
      .delete("clienti", "reparti", deleted.id, delRep.id)
      .then((rA) => {
        delRep.utenti.forEach((u, uindex) => {
          this.fire
            .deleteSubDoc("clienti", "reparti", "utenti", deleted.id, delRep.id, u.id)
            .then()
            .catch((err) => {
              console.error(err);
            });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // ######################################
  // FUNCTION: deleteU
  deleteU(i: number, ai: number, ui: number) {
    this.fire
      .deleteSubDoc(
        "clienti",
        "reparti",
        "utenti",
        this.displayedData[i].id,
        this.displayedData[i].reparti[ai].id,
        this.displayedData[i].reparti[ai].utenti[ui].id
      )
      .then()
      .catch((err) => {
        console.error(err);
      });
  }

  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.reps.filter(
      (r) =>
        r.id.toUpperCase().includes(this.search.toUpperCase()) ||
        r.ragione_sociale.toUpperCase().includes(this.search.toUpperCase())
    );
  }

  // ######################################
  // FUNCTION: showManualChange
  showManualChange(i: number, ai: number, ui: number) {
    this.username = this.displayedData[i].reparti[ai].utenti[ui];
    this.username.azienda = this.displayedData[i].id;
    this.manualChange = true;
    this.pwdForm = this.fb.group(
      {
        password: new UntypedFormControl("", [Validators.minLength(8)]),
        confirmPassword: new UntypedFormControl("", [Validators.required]),
      },
      { validators: this.checkPasswords }
    );
  }

  // ######################################
  // FUNCTION: checkPasswords
  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get("password")!.value;
    let confirmPass = group.get("confirmPassword")!.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  // ######################################
  // FUNCTION: handleCancel
  handleCancel() {
    if (this.username.admin && this.username.pwd === "") {
      this.g.createErrorNotification("Password obbligatoria per l'utente amministratore.", "");
    } else {
      this.manualChange = false;
      this.username = "";
    }
  }

  // ######################################
  // FUNCTION: recover
  recover() {
    const formData = {
      user: this.username.email,
    };

    this.node.recoverUser(formData).subscribe(
      (res) => {
        this.manualChange = false;
        this.username = "";
      },
      (err) => {
        this.manualChange = false;
        console.error("RECOVER", err);
      }
    );
  }

  // ######################################
  // FUNCTION: onSubmitManualChange
  async onSubmitManualChange() {
    this.showPwdError = false;
    if (!this.pwdForm.invalid) {
      this.username.pwd = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.pwdForm.value.password));
      const formData = {
        user: this.username,
      };
      this.node.editCustomerUser(formData).subscribe(
        (res) => {
          this.manualChange = false;
          this.username = "";
          this.g.createSuccessNotification("Password modificata con successo!", "");
        },
        (err) => {
          console.error("MANUAL CHANGE", err);
        }
      );
    } else {
      this.showPwdError = true;
    }
  }

  // ######################################
  // FUNCTION: changeUserAdmin
  changeUserAdmin(e: any, i: number, ai: number, ui: number) {
    let user = this.displayedData[i].reparti[ai].utenti[ui];
    if (e === true && user.pwd === "") {
      user.admin = true;
      this.fire.updateSubDoc(
        "clienti",
        "reparti",
        "utenti",
        user,
        this.displayedData[i].id,
        this.displayedData[i].reparti[ai].id,
        user.id
      );
      this.fire.update("clienti", "utenti", user, this.displayedData[i].id, user.id);
      this.showManualChange(i, ai, ui);
    } else {
      user.admin = false;
      user.pwd = "";
      this.fire.updateSubDoc(
        "clienti",
        "reparti",
        "utenti",
        user,
        this.displayedData[i].id,
        this.displayedData[i].reparti[ai].id,
        user.id
      );
      this.fire.update("clienti", "utenti", user, this.displayedData[i].id, user.id);
    }
  }

  // ######################################
  // FUNCTION: utentiXlsImport
  async utentiXlsImport(tplUserImport: TemplateRef<{}>, tplUserFooter: TemplateRef<{}>, azienda, repIndex) {
    this.utentiImport = [];

    this.aziendaCheck = azienda;
    this.repartoCheck = this.aziendaCheck.reparti[repIndex];
    if (!this.repartoCheck) return;

    this.modal.create({
      nzContent: tplUserImport,
      nzFooter: tplUserFooter,
      nzMaskClosable: false,
      nzClosable: false,
      nzComponentParams: {
        value: "Template Context",
      },
    });
  }
  // ######################################
  // FUNCTION: utentiGetClipboard
  async utentiGetClipboard() {
    const that = this;
    let clip: string = "";
    this.isImporting = true;
    let utentiLocal: any[] = [];
    let utentiCheck: any = this.repartoCheck.utenti;

    try {
      clip = await navigator.clipboard.readText();
    } catch (err) {
      this.isImporting = false;
      this.modal.create({
        nzTitle: "Permesso negato",
        nzContent: "Si prega di concedere all'applicazione il permesso di accedere gli appunti copiati.",
        nzOkText: null,
        nzCancelText: "Annulla",
      });
      return;
    }

    this.isImporting = false;
    let array: any = clip
      .split("\r\n")
      .filter(function (line) {
        if (!line) return false;
        return true;
      })
      .map(function (line) {
        const arr = line.split("\t");

        // console.log("LINE", line);
        // console.log("ARR", arr);

        // if (arr.length == 3) {
        // 	return {
        // 		nome: arr[0],
        // 		email: arr[1],
        // 		mobile: arr[2],
        // 		stato: "OK",
        // 	};
        // } else {
        // 	return {
        // 		nome: line,
        // 		stato: "Formato riga non corretto",
        // 	};
        // }

        if (arr.length > 1 && arr.length <= 3) {
          if (arr.length == 2) {
            const result = {
              nome: arr[0],
              email: that.g.checkEmail(arr[1]) ? arr[1] : null,
              mobile: that.g.checkMobile(arr[1]) ? arr[1] : null,
              stato: "OK",
            };
            if (result.email || result.mobile) {
              return result;
            } else {
              return {
                nome: line,
                stato: "Non è stato trovata un'email o un telefono validi",
              };
            }
          } else if (arr.length == 3) {
            const result = {
              nome: arr[0],
              email: that.g.checkEmail(arr[1]) ? arr[1] : that.g.checkMobile(arr[2]) ? arr[2] : null,
              mobile: that.g.checkMobile(arr[2]) ? arr[2] : that.g.checkMobile(arr[1]) ? arr[1] : null,
              stato: "OK",
            };
            if (result.email || result.mobile) {
              return result;
            } else {
              return {
                nome: line,
                stato: "Non è stato trovata un'email o un telefono validi",
              };
            }
          } else {
            return {
              nome: line,
              stato: "Non è stato trovata un'email o un telefono validi",
            };
          }
        } else {
          return {
            nome: line,
            stato: "Formato riga non corretto",
          };
        }
      });

    if (array.length < 1) {
      this.modal.create({
        nzTitle: "Dati non corretti",
        nzContent: "Prima di premere il pulsante si prega di copiare negli appunti i dati da importare.",
        nzOkText: null,
        nzCancelText: "Annulla",
      });
      return;
    }

    this.utentiImportCount = 0;
    array.forEach((e) => {
      if (e.stato == "OK") {
        if (!e.nome) {
          e.stato = "Nome non corretto";
        } else if (!e.email && !e.mobile) {
          e.stato = "Email e Telefono non corretti";
        }
      }

      if (e.stato == "OK") {
        var found = Object.keys(utentiCheck).filter(function (key) {
          return e.email ? utentiCheck[key].email === e.email : false;
        });
        if (found.length) {
          e.stato = "Email già presente";
        } else {
          if (e.email && utentiLocal.indexOf(e.email) >= 0) {
            e.stato = "Email già presente";
          } else {
            utentiLocal.push(e.email);
          }
        }
      }

      if (e.stato == "OK") {
        var found = Object.keys(utentiCheck).filter(function (key) {
          return e.mobile ? utentiCheck[key].mobile === e.mobile : false;
        });
        if (found.length) {
          e.stato = "Cellulare già presente";
        } else {
          if (e.mobile && utentiLocal.indexOf(e.mobile) >= 0) {
            e.stato = "Cellulare già presente";
          } else {
            utentiLocal.push(e.email);
          }
        }
      }

      if (e.stato == "OK") this.utentiImportCount++;
    });
    //this.utentiImportCount = utentiLocal.length;
    this.utentiImport = array;
  }

  // ######################################
  // FUNCTION: utentiSaveImport
  async utentiSaveImport(ref) {
    this.fire
      .getLastSubSubBy("clienti", this.aziendaCheck.id, "reparti", this.repartoCheck.id, "utenti", "codice")
      .then(async (res) => {
        let newUsers: any = [];

        this.utentiImport.forEach((utente) => {
          if (utente.stato === "OK") {
            res++;
            newUsers.push({
              admin: false,
              attivo: true,
              codice: res,
              descrizione: utente.nome,
              email: utente.email,
              mobile: utente.mobile,
              sendtype: utente.email ? false : true,
              id: "U" + res,
              pwd: "",
              ultimo_accesso: {},
              reparto: this.repartoCheck.id,
              titolare: false
            });
          }
        });
        await this.fire.addMultipleSubDoc(
          "clienti",
          "reparti",
          "utenti",
          this.aziendaCheck.id,
          this.repartoCheck.id,
          newUsers
        );
        ref.close();
        this.g.createSuccessNotification(
          "Importazione competata con successo",
          this.utentiImportCount > 1
            ? "Sono stati aggiunti " + this.utentiImportCount + " nuovi utenti."
            : "È stato aggiunto " + this.utentiImportCount + " nuovo utente."
        );
      });
  }
}
