<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiQuestTraitContent" *ngIf="g.temp_quest">
        <div nz-row class="uiQuestParagraphRow">
            <div class="uiParagraphLeft">
                DETTAGLIO TRATTI PRIMARI
            </div>
            <div class="uiParagraphRight">
                <button nz-button class="uiAddQuestion" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare il tratto {{g.temp_quest.tratti[activatedIndex - 1]?.codice}}?" (nzOnConfirm)="deleteTrait()" nz-tooltip nzTooltipTitle="Elimina tratto">
                    <i class="customIcon uiTrashIcon"></i>
                </button>

                <button nz-button class="uiAddQuestion" nz-tooltip nzTooltipTitle="Duplica tratto">
                    <i class="customIcon uiCopyIcon"></i>
                </button>

                <button nz-button class="uiAddQuestion" (click)="addTrait()" nz-tooltip nzTooltipTitle="Aggiungi tratto">
                    <i class="customIcon uiPlusCircleIcon"></i>
                </button>



                <nz-pagination class="uiTraitPagination" [nzPageIndex]="1" [nzTotal]="g.temp_quest.tratti.length" nzSimple (nzPageIndexChange)="navigateTrait($event)" [nzPageSize]="1"></nz-pagination>
            </div>
        </div>
        <div class="uiTraitHeader" *ngIf="g.temp_quest.tratti.length > 0">
            <div nz-row class="uiQuestTraitInfo">
                <div nz-col nzSpan="2" class="uiGridCol uiBlackCol" style="justify-content: center;">
                    <span>{{g.temp_quest.tratti[activatedIndex - 1].codice}}</span>
                </div>
                <div nz-col nzSpan="17" class="uiGridCol uiGreyCol">
                    <input nz-input [(ngModel)]="g.temp_quest.tratti[activatedIndex - 1].titolo[g.temp_quest_lang]" class="uiAnswerInput">
                </div>
                <div nz-col nzSpan="2" class="uiGridCol uiBlackCol" style="justify-content: center;">
                    <span>Gruppo</span>
                </div>
                <div nz-col nzSpan="3" [ngClass]="[(g.temp_quest.tratti[activatedIndex - 1].gruppo === '' || !g.temp_quest.tratti[activatedIndex - 1].gruppo) ? 'uiGridCol uiGreyCol' : '',
                    (g.temp_quest.tratti[activatedIndex - 1].gruppo === 1) ? 'uiGridCol uiSelectCol uiRedSelect' : '',
                    (g.temp_quest.tratti[activatedIndex - 1].gruppo === 2) ? 'uiGridCol uiSelectCol uiOrangeSelect' : '',
                    (g.temp_quest.tratti[activatedIndex - 1].gruppo === 3) ? 'uiGridCol uiSelectCol uiBlueSelect' : '',
                    (g.temp_quest.tratti[activatedIndex - 1].gruppo === 4) ? 'uiGridCol uiSelectCol uiGreenSelect' : '',
                    (g.temp_quest.tratti[activatedIndex - 1].gruppo === 5) ? 'uiGridCol uiSelectCol uiYellowSelect' : '']" nz-tooltip nzTooltipTitle="Seleziona gruppo">
                    <nz-select [(ngModel)]="g.temp_quest.tratti[activatedIndex - 1].gruppo" class="uiTableSelect uiSemSelect" nzDropdownClassName="uiGroupOptions" class="uiGroupSelect">
                        <nz-option nzLabel="" [nzValue]="1">
                        </nz-option>
                        <nz-option nzLabel="" [nzValue]="2">
                        </nz-option>
                        <nz-option nzLabel="" [nzValue]="3">
                        </nz-option>
                        <nz-option nzLabel="" [nzValue]="4">
                        </nz-option>
                        <nz-option nzLabel="" [nzValue]="5">
                        </nz-option>
                    </nz-select>
                </div>
            </div>
        </div>

        <div class="uiTraitTableHead" *ngIf="g.temp_quest.tratti.length > 0">
            <nz-input-group [nzPrefix]="prefixTemplateUser" class="uiSerchbar">
                <input type="text" nz-input placeholder="{{'search' | translate}}" [(ngModel)]="search" (ngModelChange)="filter()" autocomplete="off" />
            </nz-input-group>
            <ng-template #prefixTemplateUser><i class="customIcon uiFilterIcon"></i></ng-template>

            <button [ngClass]="filterActive ? 'uiFilterActiveBtn' : 'uiFilterBtn'" (click)="active()" nz-tooltip nzTooltipTitle="Solo domande con valori">
                <i class="customIcon uiFilterRoundIcon"></i>
            </button>

            <button class="uiTransformBtn" (click)="showTransform()" nz-tooltip nzTooltipTitle="Mostra riclassificazione">
                <i class="customIcon uiSurveyGreyIcon"></i>
            </button>
        </div>

        <div class="uiQuestTraitBody" *ngIf="g.temp_quest.tratti.length > 0; else emptyDivTemplate">
            <nz-table class="uiTraitTable" #questions [nzData]="g.temp_quest_questions" [nzPageSize]="1" [nzShowPagination]="false" [nzScroll]="scroll" *ngIf="g.temp_quest_questions.length > 0; else emptyTemplate">
                <thead>
                    <tr>
                        <th nzWidth="10%" nzAlign="center">COD</th>
                        <th nzWidth="55%">Domande ({{g.temp_quest_questions.length}})</th>
                        <th nzWidth="7%" nzAlign="center">R1</th>
                        <th nzWidth="7%" nzAlign="center">R2</th>
                        <th nzWidth="7%" nzAlign="center">R3</th>
                        <th nzWidth="7%" nzAlign="center">R4</th>
                        <th nzWidth="7%" nzAlign="center">R5</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of g.temp_quest_questions; let j = index">
                        <tr class="uiDarkColorRow" [ngClass]="j%2 === 0 ? 'uiGreyTableRow' : 'uiLightTableRow'">
                            <td nzAlign="center">
                                {{data.codice}}
                            </td>
                            <td class="uiQuestCol">
                                {{data.titolo[g.selectedLanguage]}}
                                <i class="customIcon uiPlusCircleIcon" (click)="addFilter(data.codice)"></i>
                            </td>
                            <ng-container *ngFor="let i of [0,1,2,3,4]">
                                <td *ngIf="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.risposte[i]; else blackBlock" nzAlign="center" nz-tooltip [nzTooltipTitle]="data.risposte[i].titolo[g.temp_quest_lang]" [nzTooltipMouseEnterDelay]="2" [ngClass]="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.risposte[i].valore != 0 ? 'uiYellowInput': ''">
                                    <nz-input-number [nzStep]="1" [nzMin]="-100" class="uiPuntInput" [(ngModel)]="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.risposte[i].valore">
                                    </nz-input-number>
                                </td>
                                <ng-template #blackBlock>
                                    <td class="uiNoInput">
                                    </td>
                                </ng-template>
                            </ng-container>
                        </tr>
                        <ng-container *ngFor="let filter of g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.filtri; let f = index">
                            <ng-container *ngIf="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda">
                                <tr class="uiFilterRow" [ngClass]="j%2 === 0 ? 'uiGreyTableRow' : 'uiLightTableRow'">
                                    <td class="uiEmptyCol">
                                    </td>
                                    <td class="uiFilterCol">
                                        <!-- FILTRI RISPOSTA -->
                                        <div nz-row>
                                            <div nz-col nzSpan="7" class="uiGridCol">
                                                <i style="position: absolute; left: 10px;" class="customIcon uiFilterGreyIcon"></i>
                                                <!-- <nz-select [(ngModel)]="filter.campo.etichetta" class="uiFilterSelect"
                                                    (ngModelChange)="selectAnagrafica($event, f, j)"
                                                    [nzCustomTemplate]="selectTpl">
                                                    <nz-option *ngFor="let an of g.temp_quest.anagrafica"
                                                        [nzLabel]="an.etichetta" [nzValue]="an"></nz-option>
                                                </nz-select>
                                                <ng-template #selectTpl let-selected>
                                                    {{ selected.nzValue.etichetta }}
                                                </ng-template> -->

                                                <!-- SELEZIONE ANAGRAFICA -->
                                                <nz-select [ngModel]="filter.campo" class="uiFilterSelect" *ngIf="!filter.campo.etichetta" (ngModelChange)="selectAnagrafica($event, f, data.codice)" [nzCustomTemplate]="selectTpl">
                                                    <div *ngFor="let an of g.temp_quest.anagrafica">
                                                        <nz-option *ngIf="an.tipo == 'Range' || an.tipo === 'Elenco' || an.tipo === 'Booleano' || an.tipo === 'Data'" [nzCustomContent]="true" [nzValue]="an" [nzLabel]="an.etichetta">
                                                            {{an.etichetta}}
                                                        </nz-option>
                                                    </div>
                                                </nz-select>
                                                <ng-template #selectTpl let-selected>
                                                    <span *ngIf="filter.campo">{{filter.campo.etichetta}} </span>
                                                    <span *ngIf="!filter.campo">{{ selected.nzValue | gettext}}</span>
                                                </ng-template>
                                                <span *ngIf="filter.campo.etichetta">
                                                    {{filter.campo.etichetta}} <i (click)="reInitFilter(data.codice, f)" style="position: absolute; right: 10px; color:#cccccc;" nz-icon nzType="close-circle" nzTheme="outline"></i>
                                                </span>
                                            </div>
                                            <!-- SELEZIONE OPERATORE 1 SE RANGE O DATA -->
                                            <div nz-col nzSpan="3" class="uiGridCol uiRightAlign">
                                                <nz-select [(ngModel)]="filter.operatore1" class="uiFilterSelect" *ngIf="filter.campo.etichetta !== '' && (filter.campo.tipo === 'Data' || filter.campo.tipo === 'Range')">
                                                    <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                                                </nz-select>

                                                <nz-select [(ngModel)]="filter.operatore1" class="uiFilterSelect" *ngIf="filter.campo.tipo === 'Elenco'">
                                                    <nz-option nzLabel="=" nzValue="="></nz-option>
                                                </nz-select>
                                            </div>
                                            <!-- INPUT CAMPO SE ELENCO O DATA / SELECT 1 SE RANGE -->
                                            <div nz-col nzSpan="5" class="uiGridCol">
                                                <!-- ALTRO -->
                                                <input nz-input [(ngModel)]="filter.valore1" class="uiFilterInput" *ngIf="filter.campo.etichetta !== '' && filter.campo.tipo !== 'Range'&& filter.campo.tipo !== 'Elenco' && filter.campo.tipo !== 'Data'">
                                                <!-- RANGE -->
                                                <nz-select [(ngModel)]="filter.valore1" *ngIf="filter.campo.etichetta !== '' && filter.campo.tipo === 'Range'" class="uiFilterSelect">
                                                    <nz-option *ngFor="let n of (buildArray(filter.minimo, filter.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                                                </nz-select>
                                                <!-- ELENCO -->
                                                <nz-select [(ngModel)]="filter.valore1" *ngIf="filter.campo.etichetta && filter.campo.tipo === 'Elenco'" class="uiFilterSelect">
                                                    <nz-option *ngFor="let n of filter.selezioni" [nzLabel]="n.toString()" [nzValue]="n">
                                                    </nz-option>
                                                </nz-select>
                                                <!-- DATA -->
                                                <nz-date-picker [(ngModel)]="filter.valore1" class="uiFilterData" nzFormat="dd/MM/yyyy" *ngIf="filter.campo.etichetta && filter.campo.tipo === 'Data'">
                                                </nz-date-picker>
                                            </div>
                                            <!-- SELEZIONE OPRATORE 2 SE RANGE -->
                                            <div nz-col nzSpan="3" class="uiGridCol uiRightAlign">
                                                <nz-select [(ngModel)]="filter.operatore2" class="uiFilterSelect" *ngIf="filter.campo.etichetta !== '' && filter.campo.tipo === 'Range'">
                                                    <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                                                </nz-select>
                                            </div>
                                            <!-- SELECT 2 SE RANGE -->
                                            <div nz-col nzSpan="5" class="uiGridCol">
                                                <nz-select *ngIf="filter.campo.etichetta !== '' && filter.campo.tipo === 'Range'" [(ngModel)]="filter.valore2" class="uiFilterSelect">
                                                    <nz-option *ngFor="let n of (buildArray(filter.valore1, filter.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                                                </nz-select>
                                            </div>
                                            <div nz-col nzSpan="1" class="uiGridCol uiRightAlign uiRemoveCol">
                                                <i class="customIcon uiTrashIcon" (click)="removeFilter(f, data.codice)"></i>
                                            </div>
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let i of [0,1,2,3,4]">
                                        <td *ngIf="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.filtri[f].risposte[i]; else blackBlock" nzAlign="center" nz-tooltip [nzTooltipTitle]="data.risposte[i].titolo[g.temp_quest_lang]" [nzTooltipMouseEnterDelay]="1.5" [ngClass]="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.filtri[f].risposte[i].valore > 0 ? 'uiYellowInput': ''">
                                            <nz-input-number [nzStep]="1" [nzMin]="0" class="uiPuntInput" [(ngModel)]="g.temp_quest.tratti[activatedIndex - 1].punteggi[data.codice].domanda.filtri[f].risposte[i].valore">
                                            </nz-input-number>
                                        </td>
                                        <ng-template #blackBlock>
                                            <td class="uiNoInput">
                                            </td>
                                        </ng-template>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
            <ng-template #emptyTemplate>
                <div class="uiEmptyContainer">
                    <nz-empty nzNotFoundImage="simple" style="color: var(--neutral-color)" nzNotFoundContent="Nessuna domanda trovata.">
                    </nz-empty>
                </div>
            </ng-template>
        </div>

        <ng-template #emptyDivTemplate>
            <div class="uiEmptyTraitContainer">
                <nz-empty nzNotFoundImage="simple" style="color: var(--neutral-color)" nzNotFoundContent="Nessun tratto trovato.">
                </nz-empty>
            </div>
        </ng-template>
    </nz-content>
</nz-layout>

<nz-modal [(nzVisible)]="showTransformModal" nzTitle="" nzFooter="null" [nzContent]="modalTransformContent" nzWrapClassName="innerTransformModal" (nzOnCancel)="closeTransform()">
    <ng-template #modalTransformContent>
        <div nz-row class="uiTransformStickyRow">
            <div nz-col nzSpan="4">
            </div>
            <div nz-col nzSpan="8" style="text-align: center;">
                Punteggio
            </div>
            <div nz-col nzSpan="8" style="text-align: center;">
                Output
            </div>
            <div nz-col nzSpan="4">
            </div>
        </div>
        <div class="uiTransformContainer">
            <div nz-row *ngFor="let t of g.temp_quest.tratti[this.activatedIndex - 1].transform; let ti = index" class="uiTransformRow">
                <div nz-col nzSpan="4">
                </div>
                <div nz-col nzSpan="8" style="text-align: center;">
                    <nz-input-number [(ngModel)]="t.punti" (nzFocus)="addTransformRow(ti)" class="uiTransformInput">
                    </nz-input-number>
                </div>
                <div nz-col nzSpan="8" style="text-align: center;">
                    <nz-input-number [(ngModel)]="t.trasformato" (nzFocus)="addTransformRow(ti)" class="uiTransformInput"></nz-input-number>
                </div>
                <div nz-col nzSpan="4">
                </div>
            </div>
        </div>
        <button nz-button nzSize="large" nzType="primary" class="saveBtn" (click)="saveTransform()">Conferma</button>
    </ng-template>
</nz-modal>