<nz-layout>
  <nz-sider class="uiQuestSidebar" nzCollapsible nzWidth="256px"
    nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <div class="uiQuestSidebarLogo">
      <a href="/admin"><img class="uiUncollapsedLogo"
          src="../../../assets/logo-light.png" alt="logo"
          *ngIf="!isCollapsed" /></a>
      <a href="/admin"><img class="uiCollapsedLogo"
          src="../../../assets/logo-small.png" alt="logo"
          *ngIf="isCollapsed" /></a>
    </div>
    <ul class="uiQuestLanguage" nz-menu
      *ngIf="g.temp_quest && !isCollapsed && g.temp_quest.tipo === 1"
      nzMode="inline">
      <li nz-submenu nzTitle="{{ qID }} {{ vID }} {{ g.temp_quest_lang }} "
        class="uiHeadLi">
        <ul class="uiSubMenuList">
          <li (click)="g.temp_quest_lang = l"
            [ngClass]="g.temp_quest_lang === l ? 'uiSubMenuLangItem uiSubMenuLangSelectItem' : 'uiSubMenuLangItem'"
            nz-menu-item class *ngFor="let l of g.temp_quest.lingue">
            <span>{{ l }}</span>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="uiQuestLanguage" nz-menu
      *ngIf="g.temp_quest && isCollapsed && g.temp_quest.tipo === 1"
      nzMode="inline">
      <li nz-submenu nzTitle="{{ g.temp_quest_lang }} " class="uiHeadLi">
        <ul class="uiSubMenuList">
          <li (click)="g.temp_quest_lang = l"
            [ngClass]="g.temp_quest_lang === l ? 'uiSubMenuLangItem uiSubMenuLangSelectItem' : 'uiSubMenuLangItem'"
            nz-menu-item class *ngFor="let l of g.temp_quest.lingue" nzOpen>
            <span>{{ l }}</span>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="uiQuestLanguage" nz-menu
      *ngIf="g.temp_quest && !isCollapsed && g.temp_quest.tipo === 2"
      nzMode="inline">
      <li nz-menu-item class="uiHeadSubLi">
        <span>{{ qID }} {{ vID }}</span>
      </li>
    </ul>
    <ul class="uiQuestLanguage" nz-menu
      *ngIf="g.temp_quest && isCollapsed && g.temp_quest.tipo === 2"
      nzMode="inline">
      <li nz-menu-item class="uiHeadSubLi">
        <span>{{ qID }} {{ vID }}</span>
      </li>
    </ul>
    <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline"
      [nzInlineCollapsed]="isCollapsed" *ngIf="g.temp_quest && !isCollapsed">
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/intestazione')">
        <a (click)="navigate('', $event)"><i class="customIcon uiHeadIcon"></i>
          Intestazione</a>
      </li>
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/domande')"
        [nzDisabled]="g.temp_quest.tipo === 2">
        <a (click)="navigate('/domande', $event)"><i
            class="customIcon uiQuestionsIcon"></i> Domande</a>
      </li>
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/tratti')">
        <a (click)="navigate('/tratti', $event)"><i
            class="customIcon uiConnectIcon"></i> Tratti primari</a>
      </li>
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/attendibilita')">
        <a (click)="navigate('/attendibilita', $event)"><i
            class="customIcon uiShieldIcon"></i> Attendibilità</a>
      </li>
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/forzatura')">
        <a (click)="navigate('/forzatura', $event)"><i
            class="customIcon uiLightningIcon"></i> Forzatura</a>
      </li>
      <li nz-menu-item class="uiMenuItem"
        [nzSelected]="router.url.includes('/email')">
        <a (click)="navigate('/email', $event)"><i
            class="customIcon uiEmailSendWhite "></i> Imposta Email di Invio</a>
      </li>
      <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
        <a class="uiUploadBtn" title="Download JSON" [href]="downloadJsonHref"
          [download]="qID + '-' + vID + '.json'"><i
            class="customIcon uiReportIcon"></i> Esporta</a>
      </li>
      <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
        <input style="display: none" type="file"
          (change)="onFileSelected($event)" #fileInput />
        <a class="uiUploadBtn" (click)="fileInput.click()"><i
            class="customIcon uiAddImageIconWhite"></i> Importa</a>
      </li>
      <li nz-menu-item class="uiMenuItem uiActionItem" [nzMatchRouter]="true">
        <a (click)="save()"><i class="customIcon uiSaveIcon"></i> Salva</a>
      </li>
      <li nz-menu-item class="uiMenuItem uiActionItem uiCancelMenuItem"
        [nzMatchRouter]="true">
        <a nz-popconfirm nzPopconfirmTitle="Annullare le modifiche?"
          (nzOnConfirm)="cancel()"><i class="customIcon uiRollbackIcon"></i>
          Annulla</a>
      </li>
      <li nz-menu-item class="uiMenuItem uiLastMenuItem uiActionItem">
        <a (click)="saveAndExit()"><i class="customIcon uiSaveRedIcon"></i>
          Salva ed esci</a>
      </li>
    </ul>
    <ul class="uiCollapsedMenu" nz-menu nzTheme="light" nzMode="inline"
      [nzInlineCollapsed]="isCollapsed" *ngIf="g.temp_quest && isCollapsed">
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem"
        [nzSelected]="router.url.includes('/intestazione')"
        [nzDisabled]="g.temp_quest.tipo === 2">
        <a (click)="navigate('', $event)"><i
            class="customIcon uiHeadIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem"
        [nzSelected]="router.url.includes('/domande')">
        <a (click)="navigate('/domande', $event)"><i
            class="customIcon uiQuestionsIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem"
        [nzSelected]="router.url.includes('/tratti')">
        <a (click)="navigate('/tratti', $event)"><i
            class="customIcon uiConnectIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem"
        [nzSelected]="router.url.includes('/attendibilita')">
        <a routerLink="./attendibilita"><i
            class="customIcon uiShieldIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem"
        [nzSelected]="router.url.includes('/forzatura')">
        <a routerLink="./forzatura"><i
            class="customIcon uiLightningIcon"></i></a>
      </li>

      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem">
        <a (click)="save()"><i class="customIcon uiSaveIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiMenuCollapsedItem uiCancelMenuItem">
        <a nz-popconfirm nzPopconfirmTitle="Annullare le modifiche?"
          (nzOnConfirm)="cancel()"><i class="customIcon uiRollbackIcon"></i></a>
      </li>
      <li nz-menu-item class="uiMenuItem uiLastMenuItem uiMenuCollapsedItem">
        <a (click)="saveAndExit()"><i class="customIcon uiSaveRedIcon"></i></a>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="uiHeader uiQuestHeader">
      <div nz-row class="uiHeaderRow">
        <div class="uiHeaderLeftCol" nz-col nzSpan="16">
          <span class="uiSidebarTrigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
              [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
          </span>
          <span>{{ g.actualPage }}</span>
        </div>
        <div nz-col nzSpan="8" class="uiHeaderRightCol">
          {{ loggedIn.email }}
          <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon"
            (click)="auth.logout()"></i>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="uiNewQuestContainer">
        <nz-layout class="uiQuestInnerLayout">
          <nz-content class="uiQuestContent">
            <div class="uiQuestInnerContent">
              <router-outlet></router-outlet>
            </div>
          </nz-content>
        </nz-layout>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
