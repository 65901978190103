import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  isCollapsed = false;
  loggedIn: any;
  code: string;
  logo: SafeResourceUrl;


  // ######################################
  // FUNCTION: constructor
  constructor(
    private sanitizer: DomSanitizer,
    public auth: AuthClienteService,
    public g: GlobalService,
    private route: ActivatedRoute,
    private fire: FireService,
    public router: Router,
    private node: NodeService
  ) { }

  // ######################################
  // FUNCTION: ngOnInit
  ngOnInit(): void {
    this.loggedIn = this.auth.getUser();
    this.code = this.loggedIn.azienda;
    let logoPath = this.auth.getLogo();
    if (logoPath===null) {
      this.fire.getOnce("clienti", this.code).subscribe((resC) => {
        let cData = resC.data();
        if (cData.logo !== "") {
          let params = { "filepath": cData.logo };
          this.node.getData(params, "/download").subscribe(
            (data) => {
              if (data.status !== 200) {
                var fileURL: string = URL.createObjectURL(data);
                this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
                this.auth.saveLogo(fileURL);
              }
            },
            (err) => {
              this.logo = "../../../../assets/logo-small.png";
              this.auth.saveLogo(this.logo.toString());
            }
          );
        } else {
          this.logo = "../../../../assets/logo-small.png";
          this.auth.saveLogo(this.logo.toString());
        }
      });
    }
    else{
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(logoPath);
    }
  }

  copyDocument() {
    const sourcePath = 'clienti/A65/licenze/L2/compilazioni/R3U1';
    const targetPath = 'clienti/A65/licenze/L3/compilazioni/R4U1';

    this.fire.copyDocument(sourcePath, targetPath)
      .then(() => console.log('Copy completed'))
      .catch(error => console.error('Error during copy:', error));
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  // ######################################
  // FUNCTION: navigate
  navigate(url): void {
    this.router.navigateByUrl(url);
  }
}
