<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiLicenzeContent">
        <div class="uiLicenzeHeader">
            <div class="uiLicenzeLeftHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser"
                    class="uiSerchbar">
                    <input type="text" nz-input
                        placeholder="{{'search' | translate}}"
                        [(ngModel)]="search" (ngModelChange)="filter()"
                        autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i
                        class="customIcon uiFilterIcon"></i></ng-template>
            </div>
        </div>

        <div class="uiLicenzeTableContainer">
            <nz-table class="uiLicenzeTable" #usersTable
                [nzData]="displayedData" [nzShowPagination]="false"
                *ngIf="!loading" [nzScroll]="scroll" [nzPageSize]="100"
                [nzWidthConfig]="['3%', '3%', '3%', '10%', '10%', '15%', '21%', '5%', '5%', '10%', '5%', '10%' ]">
                <thead>
                    <tr>
                        <th nzAlign="center" colspan="6">AZIENDA</th>
                        <th nzAlign="center">TECNICO</th>
                        <th nzAlign="center" colspan="2">LICENZE</th>
                        <th nzAlign="center">SCADENZA</th>
                        <th nzAlign="center" colspan="2">AZIONI</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let data of usersTable.data; let i = index">
                        <tr
                            [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                            <td nzAlign="center" colSpan="2">
                                <i
                                    [ngClass]="openFolder[data.id] ? 'customIcon uiFolderOpenIcon': 'customIcon uiFolderIcon'"
                                    (click)="openArea(i)" style="margin: 0;"
                                    nz-tooltip
                                    [nzTooltipTitle]="openFolder[data.id] ? 'Chiudi la cartella' : 'Apri la cartella'"></i>
                            </td>
                            <td colSpan="2" nzAlign="center">
                                {{data.id}}
                            </td>
                            <td colSpan="2">
                                {{data.ragione_sociale}}
                            </td>
                            <td colSpan="4">
                                {{data.tecnico}}
                            </td>
                            <td nzAlign="center" colSpan="2">
                                <i style="margin-right: 10px;"
                                    class="customIcon uiAddIcon"
                                    (click)="newL(i, data)" nz-tooltip
                                    nzTooltipTitle="Aggiungi licenza"></i>
                            </td>
                        </tr>
                        <ng-container *ngIf="openFolder[data.id]">
                            <ng-container
                                *ngFor="let a of data.licenze; let ai = index">
                                <tr
                                    [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                    <td class="uiBlackCol">
                                    </td>
                                    <td nzAlign="center" colSpan="2">
                                        {{a.id}}
                                    </td>
                                    <td nzAlign="center">
                                        <div
                                            style="display: flex; width: 100%; align-items: center; justify-content: center;">
                                            <i
                                                class="customIcon uiLicenseQuestIcon"></i>
                                            <nz-select *ngIf="!a.attivo"
                                                nzMode="default"
                                                [(ngModel)]="a.questionario"
                                                [nzCustomTemplate]="selectTpl"
                                                class="uiQuestSelect"
                                                nzBorderless="true"
                                                nzPlaceHolder="Seleziona questionario"
                                                [nzShowArrow]="true"
                                                nzDropdownClassName="uiDropdownSelect"
                                                [nzMenuItemSelectedIcon]="selectIconTpl"
                                                [nzOptionHeightPx]="43"
                                                (ngModelChange)="selectQuest($event, i, ai)"
                                                nz-tooltip
                                                nzTooltipTitle="Seleziona questionario">
                                                <nz-option
                                                    [nzCustomContent]="true"
                                                    *ngFor="let q of questDocs"
                                                    [nzValue]="q.id"
                                                    [nzLabel]="q.id">
                                                    {{q.id}} {{q.titolo}}
                                                </nz-option>
                                            </nz-select>
                                            <div
                                                *ngIf="a.questionario && a.attivo"
                                                class="uiQuestSelect"
                                                style="width: 100%">
                                                {{a.questionario}}
                                            </div>
                                        </div>
                                        <ng-template #selectTpl let-selected>
                                            {{selected.nzValue}}
                                        </ng-template>
                                        <ng-template #selectIconTpl>
                                            <i
                                                class="customIcon uiSelectIcon"></i>
                                        </ng-template>
                                    </td>
                                    <td nzAlign="center">
                                        <nz-select
                                            *ngIf="a.questionario && questVersions && !a.attivo"
                                            nzMode="default"
                                            [(ngModel)]="a.versione"
                                            [nzCustomTemplate]="selectTpl"
                                            class="uiQuestSelect"
                                            nzBorderless="true"
                                            nzPlaceHolder="Seleziona questionario"
                                            [nzShowArrow]="true"
                                            nzDropdownClassName="uiDropdownSelect"
                                            (ngModelChange)="selectVersion($event, i, ai)"
                                            [nzMenuItemSelectedIcon]="selectIconTpl"
                                            [nzOptionHeightPx]="43" nz-tooltip
                                            nzTooltipTitle="Seleziona versione">
                                            <nz-option [nzCustomContent]="true"
                                                *ngFor="let v of questVersions[a.questionario].versioni"
                                                [nzValue]="v.id"
                                                [nzLabel]="v.id">
                                                {{v.id}} {{v.titolo['ITA']}}
                                            </nz-option>
                                        </nz-select>
                                        <div *ngIf="a.questionario && a.attivo"
                                            class="uiQuestSelect"
                                            style="width: 100%">
                                            {{a.versione}}
                                        </div>
                                        <ng-template #selectTpl let-selected>
                                            {{selected.nzValue}}
                                        </ng-template>
                                        <ng-template #selectIconTpl>
                                            <i
                                                class="customIcon uiSelectIcon"></i>
                                        </ng-template>
                                    </td>
                                    <td nzAlign="center">
                                        <nz-select nzMode="multiple"
                                            [(ngModel)]="a.reparti"
                                            [nzCustomTemplate]="selectTpl"
                                            class="uiFilterSelect"
                                            nzBorderless="true"
                                            nzPlaceHolder="Seleziona reparti"
                                            [nzShowArrow]="true"
                                            nzDropdownClassName="uiDropdownSelect"
                                            [nzMenuItemSelectedIcon]="selectIconTpl"
                                            (ngModelChange)="selectReparti($event, i, ai)"
                                            [nzOptionHeightPx]="48">
                                            <nz-option [nzCustomContent]="true"
                                                nzValue="TUTTI" nzLabel="TUTTI"
                                                class="uiCustom">
                                                <span
                                                    [ngClass]="a.reparti.length === data.reparti.length ? 'uiAllBoldOption uiAllOption' : 'uiAllOption'">TUTTI</span>
                                            </nz-option>
                                            <nz-option [nzCustomContent]="true"
                                                *ngFor="let r of data.reparti"
                                                [nzValue]="r.id"
                                                [nzLabel]="r | getname">
                                                {{r | getname}}
                                            </nz-option>
                                        </nz-select>
                                        <ng-template #selectTpl let-selected>
                                            {{selected.nzValue}}
                                        </ng-template>
                                        <ng-template #selectIconTpl>
                                            <i
                                                class="customIcon uiSelectIcon"></i>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <div
                                            *ngIf="!editCacheL[data.id][a.id].edit">{{a.tecnico}}</div>
                                        <div
                                            *ngIf="editCacheL[data.id][a.id].edit"><input
                                                type="text" nz-input
                                                [(ngModel)]="editCacheL[data.id][a.id].data.tecnico" /></div>
                                    </td>
                                    <td style="padding: none;" nzAlign="center"
                                        nz-tooltip
                                        nzTooltipTitle="Licenze utilizzate">
                                        {{a.utilizzate}}
                                    </td>
                                    <td style="padding: none;" nzAlign="center"
                                        [ngClass]="a.utilizzate < a.totali ? 'uiGreenCol' : 'uiOffCol'">
                                        <div
                                            *ngIf="!editCacheL[data.id][a.id].edit"
                                            nz-tooltip
                                            nzTooltipTitle="Licenze assegnate">{{a.totali}}</div>
                                        <div
                                            *ngIf="editCacheL[data.id][a.id].edit"><input
                                                class="min-w" type="number"
                                                nz-input min="0"
                                                [(ngModel)]="editCacheL[data.id][a.id].data.totali" /></div>
                                    </td>
                                    <td style="padding: none;" nzAlign="center"
                                        [ngClass]="a.scadenza.seconds > nowSeconds ? 'uiGreenCol' : 'uiOffCol'"
                                        nz-tooltip
                                        [nzTooltipTitle]="a.scadenza.seconds > nowSeconds  ? 'Licenza attiva' : 'Licenza non attiva'">
                                        <div
                                            *ngIf="!editCacheL[data.id][a.id].edit">{{a.scadenza.seconds
                                            * 1000 | date:
                                            'dd/MM/yyyy'}}</div>
                                        <div
                                            *ngIf="editCacheL[data.id][a.id].edit">
                                            <nz-date-picker
                                                [(ngModel)]="editCacheL[data.id][a.id].data.scadenza"
                                                nzFormat="dd/MM/yyyy"
                                                [nzLocale]="g.dataITtranslation"></nz-date-picker>
                                        </div>
                                    </td>

                                    <td style="padding: none;" nzAlign="center"
                                        [ngClass]="a.attivo ? 'uiOnCol':'uiOffCol'">
                                        <nz-switch [(ngModel)]="a.attivo"
                                            (ngModelChange)="onChangeSwitchA($event, i, ai)"
                                            nz-tooltip
                                            [nzTooltipTitle]="a.attivo ? 'Il Cliente Elabora i Questionari' : 'Il Cliente NON Elabora i Questionari'"></nz-switch>
                                    </td>
                                    <td nzAlign="center"
                                        *ngIf="!editCacheL[data.id][a.id].edit">
                                        <i style="margin-right: 10px;"
                                            class="customIcon uiTrashGreyIcon"
                                            nz-popconfirm
                                            nzPopconfirmTitle="Eliminare questa riga?"
                                            (nzOnConfirm)="deleteL(i, ai)"
                                            [ngClass]="{ 'uiTrashGreyIconDisabled' : a.attivo }"></i>
                                        <i style="margin-right: 10px;"
                                            class="customIcon uiEditIcon"
                                            (click)="editL(data.id, a.id)"></i>

                                        <i style="margin-right: 10px;"
                                            class="customIcon uiSmsSend"
                                            (click)="utentiGet(tplUserSms, tplSmsFooter, a, data, true)"></i>
                                        <i style="margin-right: 10px;"
                                            class="customIcon uiEmailSend"
                                            (click)="utentiGet(tplUserEmail, tplEmailFooter, a, data, false)"></i>
                                        <i
                                            *ngIf="a.attivo"
                                            style="margin-right: 10px;"
                                            [ngClass]="{
                                                'customIcon': true, 
                                                'uiReportGrayIcon': true, 
                                                'uiReportGrayIconActive': a.viewAllQuestionnaires, 
                                                'uiReportGrayIconNotActive': !a.viewAllQuestionnaires
                                            }"
                                            (click)="viewQuestionnaires(i, ai)"
                                            nz-tooltip
                                            [nzTooltipTitle]="a.viewAllQuestionnaires ? 'Questionari Visibili dal Cliente' : 'Questionari NON Visibili dal Cliente'">
                                        </i>

                                        <!-- <i nz-dropdown nzTrigger="click" class="customIcon uiEmailSend" [nzDropdownMenu]="users" (click)="utentiGet(tplUserEmail, tplFooter, data.id, data.reparti)"></i>
                                        <nz-dropdown-menu #users="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li *ngFor="let u of a.utenti" nz-menu-item (click)="sendMail(data, a, u)">{{u.email}}</li>
                                            </ul>
                                        </nz-dropdown-menu> -->

                                    </td>
                                    <td nzAlign="center"
                                        *ngIf="editCacheL[data.id][a.id].edit">
                                        <i style="margin-right: 10px;"
                                            class="customIcon uiSaveRedIcon"
                                            (click)="saveEditL(i, ai, data.id, a.id)"></i>
                                        <i style="margin-right: 10px;"
                                            class="customIcon uiCloseGreyIcon"
                                            (click)="cancelEditL(i, ai, data.id, a.id)"></i>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>
    </nz-content>

    <div class="waiter" *ngIf="loading">
        <span class="ant-spin-dot ant-spin-dot-spin ng-star-inserted"><i
                class="ant-spin-dot-item"></i><i
                class="ant-spin-dot-item"></i><i
                class="ant-spin-dot-item"></i><i
                class="ant-spin-dot-item"></i></span>
    </div>
</nz-layout>

<!-- TEMPLATE SEND EMAIL LIST -->
<ng-template #tplUserEmail let-ref="modalRef">
    <nz-table #rowSelectionTable nzShowSizeChanger nzBordered
        [nzData]="utentiSend" [nzShowPagination]="false"
        [nzScroll]="{ y: '60vh' }">
        <thead>
            <tr>
                <th nzWidth="50px" [(nzChecked)]="utentiAllChecked"
                    [nzIndeterminate]="utentiIntChecked"
                    (nzCheckedChange)="onAllChecked($event)"></th>
                <th>SELEZIONARE GLI UTENTI</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let u of utentiSend">
                <td [(nzChecked)]="u.check"
                    (nzCheckedChange)="refreshCheckedStatus()"></td>
                <td>{{ u.email }}</td>
            </tr>
        </tbody>
    </nz-table>
</ng-template>
<!-- TEMPLATE EMAIL FOOTER -->
<ng-template #tplEmailFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Annulla</button>
    <button nz-button nzType="primary"
        [disabled]="!utentiIntChecked && !utentiAllChecked"
        [nzLoading]="isSending" (click)="sendEmail(ref)">
        Invia EMAIL
    </button>
</ng-template>
<!-- TEMPLATE SEND SMS LIST -->
<ng-template #tplUserSms let-ref="modalRef">
    <nz-table #rowSelectionTable nzShowSizeChanger nzBordered
        [nzData]="utentiSend" [nzShowPagination]="false"
        [nzScroll]="{ y: '60vh' }">
        <thead>
            <tr>
                <th nzWidth="50px" [(nzChecked)]="utentiAllChecked"
                    [nzIndeterminate]="utentiIntChecked"
                    (nzCheckedChange)="onAllChecked($event)"></th>
                <th>UTENTE</th>
                <th>NUMERO</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let u of utentiSend">
                <td [(nzChecked)]="u.check"
                    (nzCheckedChange)="refreshCheckedStatus()"></td>
                <td>{{ u.descrizione }}</td>
                <td>{{ u.mobile }}</td>
            </tr>
        </tbody>
    </nz-table>
</ng-template>

<!-- TEMPLATE SMS FOOTER -->
<ng-template #tplSmsFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Annulla</button>
    <button nz-button nzType="primary"
        [disabled]="!utentiIntChecked && !utentiAllChecked"
        [nzLoading]="isSending" (click)="sendSms(ref)">
        Invia SMS
    </button>
</ng-template>
