import { Expansion } from "@angular/compiler";
import { Component, HostListener, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ExceptionOutline } from "@ant-design/icons-angular/icons";
import { str } from "crc-32/*";
import { firstValueFrom } from "rxjs";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { FireService } from "src/app/services/fire.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
  selector: "app-questionari",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./licenze.component.html",
  styleUrls: ["./licenze.component.scss"],
})
export class QuestionariComponent implements OnInit {
  @ViewChild('avatarTemplateAnalisi', { static: true }) avatarTemplateAnalisi!: TemplateRef<any>;
  @ViewChild('avatarTemplateClima', { static: true }) avatarTemplateClima!: TemplateRef<any>;
  @ViewChild('avatarTemplateSelezioni', { static: true }) avatarTemplateSelezioni!: TemplateRef<any>;


  user: any;
  licenses: any[] = [];
  displayedData: any[] = [];
  loading = true;
  openFolder: any[] = [];
  searchText: string = '';
  filteredItems: any[] = [];
  scroll = { y: "" };
  options: string[] = [];
  typeQuestionnaire: any[];
  haveLicence = true;
  tabsCount: string[] = [];
  avatarTemplateMap: { interni: TemplateRef<any>; clima: TemplateRef<any>; };


  // ######################################
  // FUNCTION: constructor
  constructor(
    private fire: FireService,
    private g: GlobalService,
    private auth: AuthClienteService,
    private router: Router
  ) {
    this.filteredItems = this.displayedData;
    this.loadMoreItems();
  }


  @HostListener('window:scroll')
  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // User has scrolled to the bottom
      this.loadMoreItems();
    }
  }

  loadMoreItems() {
    if (!this.loading) {
      this.loading = true;
      // Simulate loading data
      setTimeout(() => {
        const newItems = [];
        this.filteredItems = this.filteredItems.concat(newItems);
        this.loading = false;
      }, 2000); // Simulating loading time
    }
  }

  showAnalysis(elem: Object) {
    this.router.navigate(['/valutazioni'], { state: { object: elem } });
  }

  @ViewChild('actionAnalysis') actionAnalysis: TemplateRef<any>;
  @ViewChild('actionSettings') actionSettings: TemplateRef<any>;


  getActions(data: any): any[] {
    return [
      this.actionAnalysis,
      this.actionSettings
    ];
  }

  search() {
    // Logic to filter items based on searchText
    this.filteredItems = this.displayedData.filter(item =>
      item.quest.titolo.toLowerCase().includes(this.searchText.toLowerCase().trim()) || item.vers.titolo.ITA.toLowerCase().includes(this.searchText.toLowerCase().trim()));
  }

  clearSearch(): void {
    this.searchText = '';
    this.options = [];
  }

  getItemsForTab() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const convertToDate = (seconds: number): Date => new Date(seconds * 1000);
    this.tabsCount = []; // Clear existing tab counts

    this.typeQuestionnaire = this.typeQuestionnaire.filter(typQ => {
      let nElem = 0;
      const tabType = typQ.toLowerCase().trim();

      switch (tabType) {
        case 'in corso':
          nElem = this.displayedData
            .filter(item => convertToDate(item.scadenza.seconds) >= today)
            .sort((a, b) => b.scadenza.seconds - a.scadenza.seconds)
            .length;
          break;

        case 'tutti':
          nElem = this.displayedData.length;
          break;

        default:
          nElem = this.displayedData.filter(item =>
            item.quest.titolo.toUpperCase().includes(tabType.toUpperCase())
          ).length;
          break;
      }
      this.tabsCount.push(nElem.toString());
      return true;
    });

    if (this.tabsCount[0] === '0') {
      this.typeQuestionnaire.shift();
      this.tabsCount.shift();
    }

    if (this.tabsCount.length === 2) {
      this.typeQuestionnaire.shift();
      this.tabsCount.shift();
    }

  }


  tabChanged(index: number) {

    if (this.typeQuestionnaire[index].toLowerCase() === 'in corso') {
      const today = new Date(); // Get today's date
      today.setHours(0, 0, 0, 0); // Set time to the start of the day

      this.filteredItems = this.displayedData
        .filter(item => {
          const date = new Date(item.scadenza.seconds * 1000); // Convert seconds to milliseconds and create a Date object
          return date >= today; // Keep items that are not expired
        })
        .sort((a, b) => {
          const dateA = a.scadenza.seconds * 1000; // Convert seconds to milliseconds
          const dateB = b.scadenza.seconds * 1000; // Convert seconds to milliseconds

          return dateB - dateA; // Descending order (most recent to oldest)
        });
    }

    else if (this.typeQuestionnaire[index].toLowerCase().trim() === "tutti")
      this.filteredItems = this.displayedData;
    else
      this.filteredItems = this.displayedData.filter(item =>
        item.quest.titolo.toUpperCase().trim().includes(this.typeQuestionnaire[index].toUpperCase().trim()));
  }


  getAvatarTemplate(data: any): TemplateRef<any> {
    const title = data?.quest?.titolo?.toLowerCase();
    if (!title) {
      return this.avatarTemplateSelezioni; // Default if titolo is undefined
    }

    // Find a matching template by keyword
    for (const keyword in this.avatarTemplateMap) {
      if (title.includes(keyword)) {
        return this.avatarTemplateMap[keyword];
      }
    }

    return this.avatarTemplateSelezioni; // Default template if no match found
  }



  // ######################################
  // EVENT: onResize
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    event.target.innerWidth;
    this.setScrollHeight();
  }

  // ######################################
  // FUNCTION: setScrollHeight
  setScrollHeight() {
    if (document.getElementsByClassName("uiAdminClientiTableContainer").length > 0) {
      let height = document.getElementsByClassName("uiAdminClientiTableContainer")[0].clientHeight;
      if (
        document.getElementsByClassName("uiAdminClientiTableContainer")[0].getElementsByClassName("ant-table-thead")
          .length
      ) {
        let headH = document
          .getElementsByClassName("uiAdminClientiTableContainer")[0]
          .getElementsByClassName("ant-table-thead")[0].clientHeight;
        this.scroll = { y: height - headH + "px" };
      } else {
        this.scroll = { y: height - 24 + "px" };
      }
    }
  }


  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.options = value
      ? [...new Set(
        this.displayedData
          .filter(item =>
            item.quest.titolo.toLowerCase().includes(value.toLowerCase().trim()) ||
            item.vers.titolo.ITA.toLowerCase().includes(value.toLowerCase().trim())
          )
          .map(item => item.vers.titolo.ITA.trim())
      )]
        .sort((a, b) => a.length - b.length)
      : [];

  }

  /*
  async ngOnInit(): Promise<void> {
    try {
      this.user = this.auth.getUser();
      const licenses = await firstValueFrom(this.fire.getCollection("clienti", this.user.azienda, "licenze"));
      this.licenses = licenses;
  
      for (const l of this.licenses) {
        l.reps = [];
        l.quest = await firstValueFrom(this.fire.get("questionari", l.questionario));
        l.vers = await firstValueFrom(this.fire.getDocument("questionari", l.questionario, "versioni", l.versione));
  
        const compilazioni = await firstValueFrom(
          this.fire.getSubCollection("clienti", this.user.azienda, "licenze", l.id, "compilazioni")
        );
        l.destinatari = compilazioni.length;
  
        // Setup reparti attivi
        let inviateUsers = compilazioni.filter((c) => c.sent);
        l.repartiAttivi = [];
  
        const repartiPromises = l.reparti.map(async (rep) => {
          let repId = rep.split(" ")[0];
          let resR = await firstValueFrom(this.fire.getDocument("clienti", this.user.azienda, "reparti", repId));
          let rUser = await firstValueFrom(this.fire.getSubCollection("clienti", this.user.azienda, "reparti", resR.id, "utenti"));
  
          let index = l.repartiAttivi.findIndex((d) => d.id === resR.id);
          if (index < 0) {
            let inCorso = compilazioni.filter((c) => c.compilazione ? c.compilazione.licenza.reparto == resR.id : 0);
            let concluse = compilazioni.filter((c) => c.licenza ? c.licenza.reparto == resR.id : 0);
            let inviate = inviateUsers.filter((u) => u.reparto == resR.id);
  
            l.reps.push({
              id: resR.id,
              nome: resR.nome,
              utenti: rUser.length,
              inCorso: inCorso.length,
              concluse: concluse.length,
              inviate: inviate.length,
            });
          }
        });
  
        await Promise.all(repartiPromises);
  
        l.compilazioni = compilazioni.filter((c) => c.concluso);
  
        if (this.licenses.indexOf(l) === this.licenses.length - 1) {
          this.loading = false;
          this.setScrollHeight();
          this.displayedData = this.licenses;
          this.filteredItems = this.displayedData;
        }
      }
    } catch (error) {
      // Handle any synchronous errors here
      console.error('An error occurred:', error);
    }
  }
  */

  ngOnInit(): void {
    try {
      // Initialize map with keywords and corresponding templates
      this.avatarTemplateMap = {
        'interni': this.avatarTemplateAnalisi,
        'clima': this.avatarTemplateClima,
        // Add more keywords and templates if needed
      };
      this.user = this.auth.getUser();
      this.loading = true;
      this.loadLicenses();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  private loadLicenses(): void {
    this.fire.getCollection("clienti", this.user.azienda, "licenze").subscribe((licenses) => {
      this.licenses = licenses;

      if (licenses.length > 0) {
        let processedCount = 0;  // Keep track of how many licenses have been processed

        this.licenses.forEach((license, licenseIndex) => {
          this.processLicense(license, licenseIndex, () => {
            processedCount++;
            if (processedCount === licenses.length) {
              // Finalize loading only after all licenses have been processed
              this.finalizeLoading();
            }
          });
        });
      } else {
        this.loading = false;
        this.haveLicence = false;
      }
    });
  }

  private processLicense(license: any, licenseIndex: number, callback: () => void): void {
    license.reps = [];

    this.fire.get("questionari", license.questionario).subscribe((questionario) => {
      license.quest = questionario;

      this.fire.getDocument("questionari", license.questionario, "versioni", license.versione).subscribe((versione) => {
        license.vers = versione;

        callback();
      });
    });
  }


  private finalizeLoading(): void {
    this.loading = false;
    this.setScrollHeight();
    this.displayedData = this.licenses;

    this.isTitolare();

    this.filteredItems = this.displayedData.sort((a, b) => {
      const dateA = a.scadenza.seconds * 1000; // Convert seconds to milliseconds
      const dateB = b.scadenza.seconds * 1000; // Convert seconds to milliseconds

      return dateB - dateA; // Descending order (most recent to oldest)
    });

    if (this.filteredItems.length > 0) {
      this.typeQuestionnaire = [
        'IN CORSO', 'TUTTI',
        ...[...new Set(this.filteredItems.map(elem => elem.quest?.titolo))]
      ];

      this.getItemsForTab();
    } else {
      this.haveLicence = false;
    }
  }
  private isTitolare() {
    const user = this.auth.getUser();
    const isTitolare = !!(user && user.titolare);
    if (!isTitolare) {
      this.displayedData = this.displayedData.filter(element => {
        const itaTitle = element?.vers?.titolo?.ITA;
        return itaTitle ? !itaTitle.toLowerCase().includes('titolari') : true; // Keep the element if there's no match or `ITA` is falsy
      });
    }
  }



  // ######################################
  // FUNCTION: filter
  filter() {
    this.displayedData = this.licenses.filter((r) => r.id.toUpperCase().includes(this.search()) || r.quest.id.toUpperCase().includes(this.search()) || r.quest.titolo.toUpperCase().includes(this.search()));
  }



  getSumComplete(data: any): number {
    const completati = data.reps.reduce((sum, department) => sum + department.concluse, 0);
    return completati;
  }

  getSumLicenceUsed(reps): number {
    return (reps.utilizzate);
  }

}
