import { NgModule } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { EmailComponent } from "./email";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import { GlobalService } from "src/app/shared/global.service";
import { NgZorroModule } from "src/app/ng-zorro.module";

@NgModule({
    declarations: [
      EmailComponent
    ],
    imports: [
      BrowserModule,
      FormsModule,
      NgZorroModule,
      QuillModule.forRoot() 
    ],
    providers: [GlobalService],
    bootstrap: [AppComponent]
  })

  export class EmailModule { }