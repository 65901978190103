<nz-layout class="uiPageInnerLayout">
    <nz-content class="uiQuestAddQuesionContent">
        <div nz-row class="uiQuestParagraphRow">
            <div class="uiParagraphLeft">
                DETTAGLIO DOMANDA
            </div>
            <div class="uiParagraphRight">
                <i class="customIcon uiListIcon" (click)="changeNav()" nz-tooltip nzTooltipTitle="Torna alla lista delle domande"></i>
                <i class="customIcon uiPlusCircleIcon" (click)="addQuest()" style="margin-left: 15px;" nz-tooltip nzTooltipTitle="Aggiungi domanda"></i>
                <nz-pagination class="uiQuestPagination" [nzPageIndex]="activatedIndex" [nzTotal]="g.temp_quest.domande.length" nzSimple (nzPageIndexChange)="navigateQuest($event)" [nzPageSize]="1"></nz-pagination>
            </div>
        </div>

        <div class="uiQuestAddBody">
            <div nz-row class="uiQuestCode">
                <div nz-col nzSpan="17" class="uiGridCol">
                    <span>{{g.temp_quest.domande[this.activatedIndex - 1].codice}}</span>
                </div>
                <div nz-col nzSpan="5" class="uiGridCol">
                    Esclusione <i class="customIcon uiAddFilterIcon" (click)="addQuestionFilter()"></i>
                </div>
                <div nz-col nzSpan="2" class="uiGridCol" style="justify-content: center">
                    <!-- <i class="customIcon uiCopyIcon" (click)="copy()"></i> -->
                    <i class="customIcon uiTrashIcon" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare la domanda {{g.temp_quest.domande[this.activatedIndex - 1].codice}}?" (nzOnConfirm)="remove()" nz-tooltip nzTooltipTitle="Elimina domanda"></i>
                </div>
            </div>
            <div nz-row class="uiQuestText">
                <div nz-col nzSpan="24" class="uiGridCol">
                    <textarea rows="3" nz-input [(ngModel)]="g.temp_quest.domande[this.activatedIndex - 1].titolo[g.selectedLanguage]" placeholder="Inserire il testo"></textarea>
                </div>
            </div>
            <!-- FILTRI DOMANDA -->
            <ng-container *ngIf="g.temp_quest.domande[this.activatedIndex - 1].filtri.length > 0">
                <div nz-row *ngFor="let f of g.temp_quest.domande[this.activatedIndex - 1].filtri; let k = index" class="uiQuestFilterRow">
                    <div nz-col nzSpan="1" class="uiGridCol uiEmptyCol">
                        <span style="color: transparent;">-</span>
                    </div>
                    <div nz-col nzSpan="1" class="uiGridCol">
                        <i class="customIcon uiFilterGreyIcon"></i>
                    </div>
                    <!-- SELEZIONE ANAGRAFICA -->
                    <div nz-col nzSpan="6" class="uiGridCol">
                        <nz-select [ngModel]="f.campo" class="uiFilterSelect" *ngIf="!f.campo.etichetta" (ngModelChange)="selectAnagrafica($event, k)" [nzCustomTemplate]="selectTpl">

                            <div *ngFor="let an of g.temp_quest.anagrafica">
                                <nz-option *ngIf="an.tipo == 'Range' || an.tipo === 'Elenco' || an.tipo === 'Booleano' || an.tipo === 'Data'" [nzCustomContent]="true" [nzValue]="an" [nzLabel]="an.etichetta">
                                    {{an.etichetta}}
                                </nz-option>
                            </div>
                        </nz-select>
                        <ng-template #selectTpl let-selected>
                            <span *ngIf="f.campo">{{f.campo.etichetta}} </span>
                            <span *ngIf="!f.campo">{{ selected.nzValue | gettext}}</span>
                        </ng-template>
                        <span *ngIf="f.campo.etichetta">{{f.campo.etichetta}} <i (click)="reInitQFilter(k)" style="position: absolute; right: 10px; color:#cccccc;" nz-icon nzType="close-circle" nzTheme="outline"></i></span>
                    </div>

                    <!-- SELEZIONE OPERATORE 1 -->
                    <div nz-col nzSpan="2" class="uiGridCol uiRightAlign">
                        <!-- SE RANGE O DATA -->
                        <nz-select [(ngModel)]="f.operatore1" class="uiFilterSelect" *ngIf="f.campo.tipo === 'Data' || f.campo.tipo === 'Range'">
                            <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                        </nz-select>
                        <!-- SE ELENCO -->
                        <nz-select [(ngModel)]="f.operatore1" class="uiFilterSelect" *ngIf="f.campo.tipo === 'Elenco'">
                            <nz-option nzLabel="=" nzValue="="></nz-option>
                        </nz-select>
                    </div>

                    <!-- INPUT CAMPO SE ELENCO O DATA / SELECT 1 SE RANGE -->
                    <div nz-col nzSpan="5" class="uiGridCol">
                        <!-- ALTRO -->
                        <input nz-input [(ngModel)]="f.valore1" class="uiFilterInput" *ngIf="f.campo.etichetta && f.campo.tipo !== 'Range' && f.campo.tipo !== 'Elenco' && f.campo.tipo !== 'Data' &&
                            f.campo.tipo !== 'Booleano'">
                        <!-- BOOLEANO -->
                        <nz-switch [(ngModel)]="f.valore1" class="uiAnswerSwitch" *ngIf="f.campo.etichetta && f.campo.tipo === 'Booleano'"></nz-switch>
                        <!-- RANGE -->
                        <nz-select [(ngModel)]="f.valore1" *ngIf="f.campo.etichetta && f.campo.tipo === 'Range'" class="uiFilterSelect">
                            <nz-option *ngFor="let n of (buildArray(f.minimo, f.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                        </nz-select>
                        <!-- ELENCO -->
                        <nz-select [(ngModel)]="f.valore1" *ngIf="f.campo.etichetta && f.campo.tipo === 'Elenco'" class="uiFilterSelect">
                            <nz-option *ngFor="let n of f.selezioni" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                        </nz-select>
                        <!-- DATA -->
                        <nz-date-picker [(ngModel)]="f.valore1" class="uiFilterData" nzFormat="dd/MM/yyyy" *ngIf="f.campo.etichetta && f.campo.tipo === 'Data'"></nz-date-picker>
                    </div>
                    <!-- SELEZIONE OPRATORE 2 SE RANGE -->
                    <div nz-col nzSpan="2" class="uiGridCol uiRightAlign">
                        <nz-select [(ngModel)]="f.operatore2" class="uiFilterSelect" *ngIf="f.campo.etichetta && f.campo.tipo === 'Range'">
                            <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                        </nz-select>
                    </div>
                    <!-- SELECT 2 SE RANGE -->
                    <div nz-col nzSpan="6" class="uiGridCol">
                        <nz-select *ngIf="f.campo.etichetta && f.campo.tipo === 'Range'" [(ngModel)]="f.valore2" class="uiFilterSelect">
                            <nz-option *ngFor="let n of (buildArray(f.valore1, f.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                        </nz-select>
                    </div>
                    <div nz-col nzSpan="1" class="uiGridCol uiRightAlign">
                        <i class="customIcon uiTrashIcon" (click)="removeQuestionFilter(k)"></i>
                    </div>
                </div>
            </ng-container>

            <div nz-row class="uiQuestAnswer uiYellowRow">
                <div nz-col nzSpan="18" class="uiGridCol">
                    <span>Risposte ({{g.temp_quest.domande[this.activatedIndex - 1].risposte.length}})</span>
                </div>
                <div nz-col nzSpan="6" class="uiGridCol uiRightAlign" *ngIf="g.temp_quest.domande[this.activatedIndex - 1].risposte.length < 10">
                    Risposta <i class="customIcon uiAddIcon" (click)="add()" style="margin-left: 10px"></i>
                </div>
            </div>

            <ng-container *ngFor="let answer of g.temp_quest.domande[this.activatedIndex - 1].risposte; let a = index">
                <div nz-row class="uiQuestCode" [ngClass]="a%2 === 0 ? 'uiGreyTableRow' : 'uiLightTableRow'">
                    <div nz-col nzSpan="1" class="uiGridCol">
                        <span>{{answer.codice}}</span>
                    </div>
                    <div nz-col nzSpan="9" class="uiGridCol">
                        <input nz-input [(ngModel)]="answer.titolo[g.selectedLanguage]" class="uiAnswerInput" placeholder="Inserire il testo">
                    </div>
                    <div nz-col nzSpan="3" style="text-align: center;">
                        <nz-radio-group [(ngModel)]="answer.trend">
                            <label nz-radio-button nzValue=-1>-</label>
                            <label nz-radio-button nzValue=0>=</label>
                            <label nz-radio-button nzValue=+1>+</label>
                        </nz-radio-group>
                    </div>
                    <div nz-col nzSpan="5" class="uiGridCol">
                        Commento <nz-switch class="uiAnswerSwitch" [(ngModel)]="answer.commentabile"></nz-switch>
                    </div>
                    <div nz-col nzSpan="4" class="uiGridCol">
                        Esclusione <i class="customIcon uiAddFilterIcon" (click)="addAnswerFilter(a)"></i>
                    </div>
                    <div nz-col nzSpan="2" class="uiGridCol" style="justify-content: center">
                        <i class="customIcon uiTrashIcon" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler cancellare la risposta {{answer.codice}}?" (nzOnConfirm)="removeAnswer(a)" nz-tooltip nzTooltipTitle="Elimina risposta"></i>
                    </div>
                </div>
                <!-- FILTRI RISPOSTA -->
                <div nz-row *ngFor="let fi of g.temp_quest.domande[this.activatedIndex - 1].risposte[a].filtri; let j = index" class="uiQuestFilterRow">
                    <div nz-col nzSpan="1" class="uiGridCol uiEmptyCol">
                        <span style="color: transparent;">-</span>
                    </div>
                    <div nz-col nzSpan="1" class="uiGridCol">
                        <i class="customIcon uiFilterGreyIcon"></i>
                    </div>
                    <!-- SELEZIONE ANAGRAFICA -->
                    <div nz-col nzSpan="5" class="uiGridCol">
                        <nz-select [ngModel]="fi.campo" class="uiFilterSelect" *ngIf="!fi.campo.etichetta" (ngModelChange)="selectAnswerAnagrafica($event, a, j)" [nzCustomTemplate]="selectAnswTpl">
                            <div *ngFor="let an of g.temp_quest.anagrafica">
                                <nz-option *ngIf="an.tipo == 'Range' || an.tipo === 'Elenco' || an.tipo === 'Booleano' || an.tipo === 'Data'" [nzCustomContent]="true" [nzValue]="an" [nzLabel]="an.etichetta">
                                    <span>{{an.etichetta}}</span>
                                </nz-option>
                            </div>
                        </nz-select>
                        <ng-template #selectAnswTpl let-selected>
                            <span *ngIf="fi.campo">{{fi.campo.etichetta}} </span>
                            <span *ngIf="!fi.campo">{{ selected.nzValue | gettext}}</span>
                        </ng-template>
                        <span *ngIf="fi.campo.etichetta">{{fi.campo.etichetta}} <i (click)="reInitAFilter(a, j)" style="position: absolute; right: 10px; color:#cccccc;" nz-icon nzType="close-circle" nzTheme="outline"></i>
                        </span>
                    </div>
                    <!-- SELEZIONE OPERATORE 1 -->
                    <div nz-col nzSpan="2" class="uiGridCol uiRightAlign">
                        <nz-select [(ngModel)]="fi.operatore1" class="uiFilterSelect" *ngIf="fi.campo.tipo === 'Data' || fi.campo.tipo === 'Range'">
                            <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                        </nz-select>
                        <nz-select [(ngModel)]="fi.operatore1" class="uiFilterSelect" *ngIf="fi.campo.tipo === 'Elenco'">
                            <nz-option nzLabel="=" nzValue="="></nz-option>
                        </nz-select>
                    </div>
                    <!-- INPUT CAMPO SE ELENCO O DATA / SELECT 1 SE RANGE -->
                    <div nz-col nzSpan="5" class="uiGridCol">
                        <!-- ALTRO -->
                        <input nz-input [(ngModel)]="fi.valore1" class="uiFilterInput" *ngIf="fi.campo.etichetta && fi.campo.tipo !== 'Range' && fi.campo.tipo !== 'Elenco' && fi.campo.tipo !== 'Data' &&
                            fi.campo.tipo !== 'Booleano'">
                        <!-- BOOLEANO -->
                        <nz-switch [(ngModel)]="fi.valore1" class="uiAnswerSwitch" *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Booleano'"></nz-switch>
                        <!-- RANGE -->
                        <nz-select [(ngModel)]="fi.valore1" *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Range'" class="uiFilterSelect">
                            <nz-option *ngFor="let n of (buildArray(fi.minimo, fi.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                        </nz-select>
                        <!-- ELENCO -->
                        <nz-select [(ngModel)]="fi.valore1" *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Elenco'" class="uiFilterSelect">
                            <nz-option *ngFor="let n of fi.selezioni" [nzLabel]="n.toString()" [nzValue]="n">
                            </nz-option>
                        </nz-select>
                        <!-- DATA -->
                        <nz-date-picker [(ngModel)]="fi.valore1" class="uiFilterData" nzFormat="dd/MM/yyyy" *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Data'"></nz-date-picker>
                    </div>

                    <!-- SELEZIONE OPRATORE 2 SE RANGE -->
                    <div nz-col nzSpan="2" class="uiGridCol uiRightAlign">
                        <nz-select [(ngModel)]="fi.operatore2" class="uiFilterSelect" *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Range'">
                            <nz-option *ngFor="let s of env.operators" [nzLabel]="s" [nzValue]="s"></nz-option>
                        </nz-select>
                    </div>
                    <!-- SELECT 2 SE RANGE -->
                    <div nz-col nzSpan="6" class="uiGridCol">
                        <nz-select *ngIf="fi.campo.etichetta && fi.campo.tipo === 'Range'" [(ngModel)]="fi.valore2" class="uiFilterSelect">
                            <nz-option *ngFor="let n of (buildArray(fi.valore1, fi.massimo))" [nzLabel]="n.toString()" [nzValue]="n"></nz-option>
                        </nz-select>
                    </div>

                    <div nz-col nzSpan="2" class="uiGridCol uiRightAlign">
                        <i class="customIcon uiTrashIcon" (click)="removeAnswerFilter(a, j)"></i>
                    </div>
                </div>
            </ng-container>
        </div>
    </nz-content>
</nz-layout>