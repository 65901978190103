<nz-layout>
    <nz-sider class="uiEditSidebar" nzCollapsible nzWidth="256px"
        nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="uiQuestSidebarLogo">
            <a href="/admin"><img class="uiUncollapsedLogo"
                    src="../../../assets/logo-light.png" alt="logo"
                    *ngIf="!isCollapsed"></a>
            <a href="/admin"><img class="uiCollapsedLogo"
                    src="../../../assets/logo-small.png" alt="logo"
                    *ngIf="isCollapsed"></a>
        </div>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline"
            [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem"
                [nzMatchRouter]="true">
                <a (click)="back()"><i class="customIcon uiRollbackIcon"></i>
                    Indietro</a>
            </li>
        </ul>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline"
            [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem"
                *ngIf="!questionario.anonimo"
                [nzMatchRouter]="true">
                <a (click)="allQuestionnairesVisbile()"><i
                        class="customIcon uiQuestIcon"></i>
                    Visibilità Questionari</a>
            </li>
        </ul>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline"
            [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem"
                *ngIf="!questionario.anonimo"
                [nzMatchRouter]="true">
                <a (click)="blockCommentsNotifications()"><i
                        class="customIcon uiPrivacyIcon"></i>
                    Commenti/Notifiche </a>
            </li>
        </ul>
        <ul class="uiMenu" nz-menu nzTheme="light" nzMode="inline"
            [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">
            <li nz-menu-item class="uiMenuItem uiActionItem"
                *ngIf="!questionario.anonimo"
                [nzMatchRouter]="true">
                <a (click)="downloadAllQuestionaires()"><i
                        class="customIcon uiExcelIconWhite"></i>
                    Scarica Tutti Questionari</a>
            </li>
        </ul>
    </nz-sider>
    <nz-layout>
        <nz-header class="uiHeader uiQuestHeader">
            <div nz-row class="uiHeaderRow">
                <div class="uiHeaderLeftCol" nz-col nzSpan="16">
                    <span class="uiSidebarTrigger"
                        (click)="isCollapsed = !isCollapsed">
                        <i class="trigger"
                            [ngClass]="isCollapsed ? 'customIcon uiMenuFoldIcon' : 'customIcon uiMenuFoldIcon'"></i>
                    </span>
                    <span>{{g.actualPage}}</span>
                </div>
                <div nz-col nzSpan="8" class="uiHeaderRightCol">
                    {{loggedIn.email}}
                    <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon"
                        (click)="auth.logout()"></i>
                </div>
            </div>
        </nz-header>
        <nz-content class="uiGestioneEditContent">
            <div nz-row class="uiGestioneEditParagraphRow" [nzGutter]="16">
                <div class="uiParagraphLeft">
                    <span *ngIf="repName">{{repName.nome}} ({{newDests.length +
                        displayedData.length}})</span>
                </div>
                <div class="uiParagraphRight">
                    <button nz-button class="uiAddDest"
                        (click)="selectDest(tplUserEmail, tplFooter)">
                        Aggiungi Destinatari <i
                            class="customIcon uiPlusCircleIcon"></i>
                    </button>
                </div>
            </div>

            <div class="uiGestioneEditHeader">
                <nz-input-group [nzPrefix]="prefixTemplateUser"
                    class="uiSerchbar">
                    <input type="text" nz-input
                        placeholder="{{'search' | translate}}"
                        [(ngModel)]="search" (ngModelChange)="filter()"
                        autocomplete="off" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i
                        class="customIcon uiFilterIcon"></i></ng-template>
            </div>

            <div style=" padding-bottom: calc(38px - 1rem) !important;"
                class="uiGestioneEditTableContainer" *ngIf="!loading">
                <nz-table class="uiGestioneEditTable" #usersTable
                    [nzData]="displayedData" [nzShowPagination]="false"
                    [nzScroll]="scroll" [nzPageSize]="100"
                    [nzWidthConfig]="['10%', '15%', '20%', '25%', '15%', '15%']">
                    <thead>
                        <tr>
                            <th nzAlign="center" colspan="2">DESTINATARI</th>
                            <th nzAlign="center">DATA COMPILAZIONE</th>
                            <th nzAlign="center">DESCRIZIONE</th>
                            <th nzAlign="center">LINK QUESTIONARIO</th>
                            <th nzAlign="center">AZIONI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let data of usersTable.data; let i = index">
                            <tr
                                [ngClass]="i%2 === 0 ? 'uiLightColorRow': 'uiDarkColorRow'">
                                <td nzAlign="center">
                                    {{data.id}}
                                </td>
                                <td nzAlign="center">
                                    {{questionario.anonimo ? "Anomimo" :
                                    (data.email.length>0 ? data.email
                                    :data.mobile)}}
                                </td>
                                <td nzAlign="center">
                                    {{ data.compilazione.dataCompilazione ?
                                    (data.compilazione.dataCompilazione.seconds
                                    * 1000 | date: 'dd/MM/yyyy HH:mm') : '-' }}

                                </td>
                                <td nzAlign="center">
                                    {{questionario.anonimo ? "Utente" :
                                    data.descrizione }}
                                </td>
                                <td nzAlign="center" class="uiLinkCol">
                                    <p
                                        *ngIf="data.compilazione && !data.compilazione.sent && data.compilazione.concluso === undefined">
                                        Da inviare <i
                                            class="customIcon uiLinkNotSent"
                                            nz-popconfirm
                                            nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                                            (nzOnConfirm)="utenteSend(data)"
                                            nz-tooltip
                                            nzTooltipTitle="Invia email"></i>
                                    </p>
                                    <p
                                        *ngIf="data.compilazione && data.compilazione.sent && data.compilazione.sent === true">
                                        Inviato <i class="customIcon uiLinkSent"
                                            nz-popconfirm
                                            nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                                            (nzOnConfirm)="utenteSend(data)"
                                            nz-tooltip
                                            nzTooltipTitle="Invia email"></i>
                                    </p>
                                    <p
                                        *ngIf="data.compilazione && data.compilazione.concluso !== undefined && data.compilazione.concluso === false">
                                        Iniziato <i
                                            class="customIcon uiLinkBegin"
                                            nz-popconfirm
                                            nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                                            (nzOnConfirm)="utenteSend(data)"
                                            nz-tooltip
                                            nzTooltipTitle="Invia email"></i>
                                    </p>
                                    <p
                                        *ngIf="data.compilazione && data.compilazione.concluso !== undefined && data.compilazione.concluso === true">
                                        Completato <i
                                            class="customIcon uiLinkEnd"
                                            nz-popconfirm
                                            nzPopconfirmTitle="Vuoi inviare nuovamente l'email a questo utente?"
                                            (nzOnConfirm)="utenteSend(data)"
                                            nz-tooltip
                                            nzTooltipTitle="Invia email"></i>
                                    </p>
                                </td>
                                <td nzAlign="center">
                                    <!-- <i *ngIf="data.compilazione.commento" [ngClass]="data.compilazione && data.compilazione.concluso !== undefined && data.compilazione.concluso === true ? 'customIcon uiWhiteValutazioniIcon' : 'customIcon uiWhiteValutazioniIcon uiDisabledIcon'" style="margin-left: 10px;" (click)="addValutazione(data.id, data.compilazione.id)"></i> -->
                                    <i
                                        *ngIf="!questionario.anonimo"
                                        [ngClass]="
                                      data.compilazione?.concluso
                                        ? (data.compilazione?.commento
                                            ? 'customIcon uiQuestIcon'
                                            : 'customIcon uiWhiteValutazioniIcon')
                                        : 'customIcon uiWhiteValutazioniIcon uiDisabledIcon'
                                    "
                                        style="margin-left: 10px;"
                                        (click)="addValutazione(data.id, data.compilazione?.id)"></i>

                                    <!--- 
                                        <i *ngIf="false"
                                        [ngClass]="data.compilazione && data.compilazione.concluso !== undefined && data.compilazione.concluso === true ? 'customIcon uiReportIcon' : 'customIcon uiReportIcon uiDisabledIcon'"
                                        style="margin:0 10px;"
                                        (click)="export(data)"></i>
                                       --->

                                    <i *ngIf="!questionario.anonimo"
                                        [ngClass]="data.compilazione && data.compilazione.concluso !== undefined && data.compilazione.concluso === true ? 'customIcon uiExcelIconWhite' : 'customIcon uiExcelIconWhite uiDisabledIcon'"
                                        style="margin: 0 10px; color: white;"
                                        (click)="excelModal(data, excelFooter)"></i>

                                    <i *ngIf="!questionario.anonimo"
                                        nz-icon
                                        [nzType]="data.compilazione.nonCommentare ? 'lock' : 'unlock'"
                                        nzTheme="outline"
                                        class="icon-style customIcon"
                                        style="font-size: 20px; width: 10px; height: 10px;"
                                        nz-tooltip
                                        [nzTooltipTitle]="'Commento: ' + (data.compilazione.nonCommentare ? 'Chiuso' : 'Aperto')"
                                        (click)="isInfoEmail && manageComments(data)"></i>

                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </nz-table>
            </div>
            <google-chart #chart id="chart" [type]="g.chartType"
                [data]="g.chartData" [columns]="g.chartColumnNames"
                *ngIf="g.chartVisible" [options]="g.chartDisplayOptions"
                style="position: absolute; top: 0; left: 0; width:99%; height: 99%; z-index: -1;"></google-chart>
            <div class="chartOverlay" *ngIf="g.chartProgress">
                <i class="customIcon uiLoadingIcon"></i>
                <br />
                <h1>Esportazione in corso...</h1>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>

<ng-template #tplUserEmail let-ref="modalRef">
    <nz-table #rowSelectionTable nzShowSizeChanger nzBordered
        [nzData]="utentiEmail" [nzShowPagination]="false"
        [nzScroll]="{ y: '60vh' }">
        <thead>
            <tr>
                <th nzWidth="50px" [(nzChecked)]="utentiAllChecked"
                    [nzIndeterminate]="utentiIntChecked"
                    (nzCheckedChange)="onAllChecked($event)"></th>
                <th>SELEZIONARE GLI UTENTI</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let u of utentiEmail">
                <td [(nzChecked)]="u.check"
                    (nzCheckedChange)="refreshCheckedStatus()"></td>
                <td>{{ u.email }}</td>
            </tr>
        </tbody>
    </nz-table>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Annulla</button>
    <button nz-button nzType="primary"
        [disabled]="!utentiIntChecked && !utentiAllChecked"
        (click)="utentiSend(ref)">
        Invia Email
    </button>
</ng-template>

<ng-template #excelFooter style="text-align: center;">
    <div class="text-center">
        <button nz-button nzType="primary" (click)="excelExport(dataExcel)">
            Estrai risposte
        </button>
        <button nz-button nzType="primary"
            (click)="excelExport(dataExcel, true)">
            Estrai commenti
        </button>
    </div>
</ng-template>

<div class="waiter-overlay" *ngIf="loading">
    <div class="waiter">
        <span class="loader" *ngIf="totalDownload===0">
            <i class="loader-dot"></i>
            <i class="loader-dot"></i>
            <i class="loader-dot"></i>
            <i class="loader-dot"></i>
        </span>
        <div class="export-text" *ngIf="totalDownload>0">
            Esportazione dei questionari: {{ downloadNumber }}/{{ totalDownload
            }}
        </div>
    </div>

</div>
