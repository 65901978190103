
<div class="editor-row">
    <!-- Quill Editor Column -->
    <div nz-col nzFlex="auto" class="editor-col">
        <quill-editor [(ngModel)]="valText"
            placeholder="Imposta il messaggio che i destinatari vedranno all'invio del link..."></quill-editor>
    </div>
</div>
<div
    class="note-control">
    <button
        nz-button
        nzType="default"
        nzShape="circle"
        (click)="save()"
        class="icon-button"
        nz-tooltip
        nzTooltipTitle="Salva Messaggio E-Mail"
        nzTooltipPlacement="right"
        [nzTooltipArrowPointAtCenter]="true">
        <i nz-icon nzType="save"></i>
    </button>
</div>
