

<nz-layout>
  <nz-header class="uiHeader uiQuestHeader">
    <div nz-row class="uiHeaderRow">
      <div class="uiHeaderLeftCol" nz-col nzSpan="16">
        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="back()"
          class="icon-button"
          nz-tooltip
          nzTooltipTitle="Esci"
          nzTooltipPlacement="bottomLeft"
          [nzTooltipArrowPointAtCenter]="true">
          <i nz-icon nzType="rollback"></i>
        </button>

        <button
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="exportQuestionare()"
          class="icon-button"
          nz-tooltip
          nzTooltipTitle="Scarica Questionario"
          nzTooltipPlacement="bottomLeft"
          [nzTooltipArrowPointAtCenter]="true">
          <i nz-icon nzType="download"></i>
        </button>
        <h1 class="titleClient">{{ nameClient }}</h1>
      </div>

      <div nz-col nzSpan="8" class="uiHeaderRightCol">
        {{loggedIn.email}}
        <i *ngIf="auth.isLoggedIn()" class="customIcon uiLogoutIcon"
          (click)="auth.logout()"></i>
      </div>
    </div>
  </nz-header>
  <nz-content class="uiGestioneEditContent">
    <div class="uiGestioneEditVContainer" *ngIf="!loading">

      <div class="ui-gestione-edit-v-graph"
        [class.ui-gestione-edit-v-big-graph]="!bSave">
        <div>
          <div  style="display: none;"  class="uiValControlsLeft">
            <span *ngIf="data[0].attendibilita === true"
              class="uiValBadge uiValPositive">ATTENDIBILE</span>
            <span *ngIf="data[0].attendibilita === false"
              class="uiValBadge uiValNegative">NON
              ATTENDIBILE</span>
          </div>
          <div style="display: none;"  class="uiValControlsRight">
            <span *ngIf="data[0].forzatura === true"
              class="uiValBadge uiValPositive">NON
              FORZATO</span>
            <span *ngIf="data[0].forzatura === false"
              class="uiValBadge uiValNegative">FORZATO</span>
          </div>
        </div>

        <div nz-row class="edit-grid-row">

          <div nz-col nzFlex="auto"
            class="edit-grid-col second-col">
            <google-chart
              class="chart"
              #chart
              [type]="g.chartType"
              [data]="g.chartData"
              [columns]="g.chartColumnNames"
              *ngIf="!g.chartVisible"
              [options]="g.chartDisplayOptions">
            </google-chart>
          </div>
        </div>
      </div>

      <nz-row style="margin-top: 20px !important;">
        <!-- Table Column -->
        <nz-col nzFlex="1" class="uiGestioneEditVTable">
          <nz-table
            class="uiGestioneEditVTable"
            #valTable
            [nzData]="intestazione"
            [nzShowPagination]="false"
            [nzScroll]="scroll"
            [nzPageSize]="100"
            [nzWidthConfig]="['20%', '70%']">
            <thead>
              <tr>
                <th colspan="2">INTESTAZIONE</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let int of valTable.data; let i = index">
                <tr
                  [ngClass]="i % 2 === 0 ? 'uiLightColorRow' : 'uiDarkColorRow'">
                  <td>{{ int.etichetta }}</td>
                  <td>{{ int.valore }}</td>
                </tr>
              </ng-container>
            </tbody>
          </nz-table>
        </nz-col>

        <!-- Chat Editor Column -->
        <nz-col nzFlex="2" *ngIf="bSave" class="chat-editor-container">
          <div class="editor-row">
            <!-- Quill Editor Column -->
            <div nz-col nzFlex="auto" class="editor-col">
              <quill-editor [(ngModel)]="valText"
                placeholder="Scrivi commento..."></quill-editor>
            </div>
          </div>
          <div *ngIf="!data[0].nonCommentare || data[0].nonCommentare !== true"
            class="note-control">
            <button
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="save()"
              class="icon-button"
              nz-tooltip
              nzTooltipTitle="Salva Commento "
              nzTooltipPlacement="left"
              [nzTooltipArrowPointAtCenter]="true">
              <i nz-icon nzType="save"></i>
            </button>

            <button
              nz-button
              nzType="default"
              nzShape="circle"
              class="icon-button"
              nz-tooltip
              nz-popconfirm
              nzPopconfirmTitle="Sei sicuro di volere inviare il commento al referente?"
              (nzOnConfirm)="sendCommentNotification()"
              nzTooltipTitle="Invia Commento"
              nzTooltipPlacement="top"
              [nzTooltipArrowPointAtCenter]="true">
              <i nz-icon nzType="send"></i>
            </button>
          </div>
        </nz-col>
      </nz-row>

    </div>


    <div class="waiter-overlay" *ngIf="loading">
      <div class="waiter">
          <span class="loader">
              <i class="loader-dot"></i>
              <i class="loader-dot"></i>
              <i class="loader-dot"></i>
              <i class="loader-dot"></i>
          </span>
      </div>
  </div>
  </nz-content>
</nz-layout>
