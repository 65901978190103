import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() { }

    uploadExcel(file: File, columnName: string[], initState: string): Promise<any[]> {

        return new Promise((resolve, reject) => {
            if (!file.name.endsWith('.xlsx')) {

                return;
            }

            const fileReader: FileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const data: ArrayBuffer = e.target.result;
                const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });

                // Process each sheet in the workbook
                const excelData: any[] = [];
                workbook.SheetNames.forEach((sheetName: string) => {
                    const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                    const sheetData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    // Skip empty sheets or sheets without data
                    if (sheetData.length > 1) {
                        const headers: string[] = sheetData[0];
                        for (let i = 1; i < sheetData.length; i++) {
                            const row: any[] = sheetData[i];
                            const rowData: any = {};

                            rowData.name = row[0];
                            rowData.address = row[1];
                            rowData.status = initState;

                            // Extract role from sheetName (assuming sheetName is like 'roles')
                            const role = sheetName // Use sheetName as role (convert to lowercase)
                            rowData.role = role;

                            excelData.push(rowData);
                        }
                    }
                });

                if (excelData.length > 0) {
                    resolve(excelData);
                } else {
                    reject(new Error('Struttura EXCEL sbagliato'));
                }
            };
            fileReader.readAsArrayBuffer(file);
        });
    }


    downloadExcel(data: any[], fileName: string, columnName: string[], roles: string[]) {
        const workbook = XLSX.utils.book_new();

        // Create a sheet for each provided role and populate with data
        roles.forEach(role => {
            const roleData = data
                .filter(item => item.role === role)
                .map(({ role, ...rest }) => ({
                    [columnName[0]]: rest.name,
                    [columnName[1]]: rest.address
                }));

            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(roleData ? roleData : roleData, { header: columnName });
            XLSX.utils.book_append_sheet(workbook, worksheet, role);
        });

        // Write the workbook to an Excel buffer and save the file
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, fileName || 'data');
    }


    private saveExcelFile(buffer: any, fileName: string) {
        const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, fileName + '.xlsx');
    }

}
