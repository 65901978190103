import { Questionario } from "./../entities/questionari";
import { environment } from "./../../environments/environment";
import { ElementRef, Injectable, ViewChild } from "@angular/core";
// @ts-ignore
import crc32 from "crc/crc32";
import { NzNotificationDataOptions, NzNotificationService } from "ng-zorro-antd/notification";
import { NzMessageService } from "ng-zorro-antd/message";
import { SodiumPlus, CryptographyKey } from "sodium-plus";
import { User } from "../entities/users";
import { FireService } from "src/app/services/fire.service";
import html2canvas from "html2canvas";
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { NodeService } from "../services/node.service";
import { ChartType } from "angular-google-charts";
import { NzModalService } from "ng-zorro-antd/modal";
import { color } from "highcharts";


interface QuestAUX {
  titolo: {
    ITA: string;
  };
  copertina_report: {
    ITA: string;
  };
}


@Injectable({
  providedIn: "root",
})
export class GlobalService {
  // yy.mm.dd
  appVersion = "5.1.14";
  globalFooter: boolean = true;
  actualPage = "";
  selectedLanguage = "ITA";
  selectedQuote = "";
  selectedVersion = "";
  keyBuffer!: Buffer;
  nodePath: string;
  sodium!: SodiumPlus;
  loggedInUser: User;
  languages = ["ITA", "ENG"];
  temp_quest: any;
  temp_quest_lang: string = "ITA";
  activeId: string = "";
  temp_quest_id: string = "";
  uploadButtons = {
    showRemoveIcon: false,
    showDownloadIcon: false,
  };
  questLoaded = false;
  temp_quest_questions: any = [];
  temp_quest_traits: any = [];


  listOfPermissions = [
    { label: "Designer", icona: "customIcon uiGreyDesignerIcon" },
    // {label:'Simulazione', icona: 'customIcon uiGreySimulazioneIcon'},
    { label: "Pubblicazione", icona: "customIcon uiGreyPubblicazioneIcon" },
    { label: "Utenti e gruppi", icona: "customIcon uiGreyUtentigruppiIcon" },
    {
      label: "Clienti e aziende",
      icona: "customIcon uiGreyClientiaziendeIcon",
    },
    {
      label: "Gestione questionari",
      icona: "customIcon uiGreyGestionequestIcon",
    },
    {
      label: "Gestione destinatari",
      icona: "customIcon uiGreyGestionedestIcon",
    },
    { label: "Gestione aggregati", icona: "customIcon uiGreyGestioneaggrIcon" },
    { label: "Valutazioni", icona: "customIcon uiGreyValutazioniIcon" },
    { label: "Privacy", icona: "customIcon uiGreyPrivacyIcon" },
  ];

  dataITtranslation = {
    lang: {
      locale: "it_IT",
      placeholder: "Seleziona data",
      rangePlaceholder: ["Data inizio", "Data fine"],
      today: "Oggi",
      now: "Ora",
      backToToday: "Ritorna ad oggi",
      ok: "OK",
      clear: "Annulla",
      month: "Mese",
      year: "Anno",
      timeSelect: "Seleziona ora",
      dateSelect: "Seleziona data",
      monthSelect: "Scegli mese",
      yearSelect: "Scegli anno",
      decadeSelect: "Scegli decade",
      yearFormat: "YYYY",
      dateFormat: "dd/MM/yyyy",
      dayFormat: "dd",
      dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
      monthFormat: "MM",
      monthBeforeYear: true,
      previousMonth: "Mese precende",
      nextMonth: "Mese successivo",
      previousYear: "Anno precedente",
      nextYear: "Anno successivo",
      previousDecade: "Decade precedente",
      nextDecade: "Decade successiva",
      previousCentury: "Secolo scorso",
      nextCentury: "Secolo successivo",
      yearPlaceholder: "Seleziona anno",
      monthPlaceholder: "Seleziona mese",
      weekPlaceholder: "Seleziona settimana",

      rangeYearPlaceholder: ["Anno inizio", "Anno fine"],
      rangeQuarterPlaceholder: ["Start quarter", "End quarter"],
      rangeMonthPlaceholder: ["Mese inizio", "Mese fine"],
      rangeWeekPlaceholder: ["Settimana inizio", "Settimana fine"],
    },
    timePickerLocale: {
      placeholder: "Seleziona ora",
    },
    dateFormat: "dd/MM/yyyy",
    dateTimeFormat: "dd/MM/yyyy HH:mm:ss",
    weekFormat: "YYYY-wo",
    monthFormat: "YYYY-MM",
  };
  CryptoJS = require("crypto-js");
  svgContent =  ` <?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 27.8.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 537.8 118.9" style="enable-background:new 0 0 537.8 118.9;" xml:space="preserve">
<style type="text/css">
	.st0{fill:url(#SVGID_1_);}
	.st1{fill:url(#SVGID_00000018194772525728275660000017347215514005654950_);}
	.st2{fill:url(#SVGID_00000036245995339210711500000015466250008913960086_);}
	.st3{fill:url(#SVGID_00000018957552368221121320000016999828755043458984_);}
	.st4{fill:url(#SVGID_00000179609393057359297080000002019162597774737296_);}
	.st5{fill:url(#SVGID_00000003795827393314279370000009528661813720109732_);}
	.st6{fill:url(#SVGID_00000006683057516312292520000018104964108875573138_);}
	.st7{fill:url(#SVGID_00000038402864555479952330000017250507922219053456_);}
	.st8{fill:url(#SVGID_00000088119102446099401010000017442093215687877012_);}
	.st9{fill:#C7283D;}
	.st10{fill:url(#SVGID_00000010287760450541313870000003390310651425757317_);}
	.st11{fill:#661515;}
	.st12{enable-background:new    ;}
	.st13{fill:#C8293D;}
	.st14{display:none;}
	.st15{display:inline;fill:#661515;}
	.st16{font-family:'Comfortaa-SemiBold';}
	.st17{font-size:21.77px;}
	.st18{display:inline;fill:#C8293D;}
</style>
<g id="Livello_2_00000136405140325091214440000005809185860935106226_">
	
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="93.87" y1="76.595" x2="527.06" y2="76.595" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path class="st0" d="M218,36.2v39.3c0,3-1,5.6-3,7.6c-1.9,1.9-4.6,3-7.6,3s-5.6-1-7.6-2.9c-2-1.9-3.1-4.6-3.1-7.6V36.2
		c0-5-1.2-8.8-3.5-11.3c-2.3-2.5-5.1-3.6-8.8-3.6c-4.1,0-7.2,1.3-9.7,3.9c-2.5,2.6-3.7,6.1-3.7,10.5v39.8c0,3-1.1,5.7-3.1,7.6
		c-2,1.9-4.6,2.9-7.6,2.9s-5.4-1-7.4-2.8c-2.1-1.9-3.3-4.6-3.3-7.7V36.2c0-5-1.2-8.8-3.5-11.3c-2.3-2.5-5.1-3.6-8.8-3.6
		c-4.1,0-7.3,1.3-9.8,3.9c-2.6,2.7-3.8,6.1-3.8,10.5v39.9c0,3-1,5.6-3,7.6c-1.9,1.9-4.6,3-7.6,3s-5.6-1-7.6-2.9
		c-2-1.9-3.1-4.6-3.1-7.6V12.2c0-3,1.1-5.6,3-7.6s4.6-3,7.6-3s5.7,1.1,7.6,3.1c0.3,0.3,0.6,0.7,0.8,1c0.2-0.1,0.4-0.3,0.7-0.4
		c4.9-3,10.6-4.5,16.7-4.5s11.7,1.5,16.6,4.4c2.3,1.4,4.4,3,6.2,5c1.9-1.9,4.1-3.5,6.6-4.9c5.2-3,11.2-4.4,17.8-4.4
		c6.1,0,11.7,1.5,16.6,4.4c4.9,2.9,8.7,7.2,11.4,12.6C216.8,23,218.1,29.2,218,36.2L218,36.2z"/>
	
		<linearGradient id="SVGID_00000164484288440567371820000004952017230080859795_" gradientUnits="userSpaceOnUse" x1="93.87" y1="60.155" x2="527.06" y2="60.155" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000164484288440567371820000004952017230080859795_);" d="M359.7,12.2v68.9c0,7.1-1.6,13.5-4.8,19.1
		c-3.2,5.6-7.6,10-13.2,13.2c-5.5,3.1-11.9,4.7-18.9,4.7c-3,0-5.6-1.1-7.6-3.1c-2-2-3-4.6-3-7.6s1-5.7,3-7.6c2-2,4.6-3,7.6-3
		c3.2,0,5.9-0.6,8.1-2c2.3-1.3,4.1-3.1,5.4-5.4c1.4-2.3,2-5,2-8.2V12.2c0-3.9,1.7-6.4,3.1-7.8c2-1.9,4.6-2.9,7.6-2.9s5.6,1,7.6,2.9
		C358,5.8,359.7,8.3,359.7,12.2L359.7,12.2z"/>
	
		<linearGradient id="SVGID_00000116926055459606840820000001685040388936668859_" gradientUnits="userSpaceOnUse" x1="93.87" y1="76.375" x2="527.06" y2="76.375" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000116926055459606840820000001685040388936668859_);" d="M441.1,40.3c0.1,1.2,0.2,2.4,0.2,3.6
		c0,1.1,0,2.1-0.2,3.1c0.1-1,0.2-2,0.2-3.1v-0.1C441.4,42.6,441.3,41.4,441.1,40.3z"/>
	
		<linearGradient id="SVGID_00000059996378250539559980000012717396044941246141_" gradientUnits="userSpaceOnUse" x1="93.87" y1="76.19" x2="527.06" y2="76.19" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000059996378250539559980000012717396044941246141_);" d="M456.8,21.8c-3.7-6.5-8.8-11.6-15.2-15.3
		c-6.4-3.7-13.7-5.6-21.9-5.6s-15.6,1.9-22,5.6c-6.4,3.7-11.6,8.9-15.3,15.3c-3.7,6.5-5.6,13.9-5.6,22.1s1.9,15.5,5.6,22
		c3.7,6.5,8.8,11.6,15.3,15.3c6.4,3.7,13.8,5.6,22,5.6s15.5-1.9,21.9-5.6c6.4-3.7,11.6-8.9,15.3-15.3c3.7-6.5,5.6-13.8,5.6-22
		C462.4,35.6,460.4,28.2,456.8,21.8z M441.4,43.9c0,1-0.1,2.1-0.2,3.1c0,0.1,0,0.1,0,0.2c-1.6,10.4-10.6,18.4-21.5,18.4
		c-11.6,0-21.1-9.1-21.7-20.6c0-0.4,0-0.7,0-1.1v-0.1c0-0.4,0-0.7,0-1.1c0.6-11.5,10.1-20.6,21.7-20.6c10.8,0,19.8,7.9,21.5,18.2
		c0.2,1.1,0.3,2.3,0.3,3.5C441.4,43.7,441.4,43.9,441.4,43.9z"/>
	
		<linearGradient id="SVGID_00000032619040948357784440000002689604122011761557_" gradientUnits="userSpaceOnUse" x1="93.87" y1="76.6" x2="527.06" y2="76.6" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000032619040948357784440000002689604122011761557_);" d="M537.3,14.3c-0.7,3.4-2.6,5.1-4,5.9
		c-1.6,0.9-3.4,1.4-5.4,1.4c-1.3,0-2.7,0-4.2-0.1l-0.4,0c-4.6-0.6-8.7-0.4-12.1,0.7c-3.3,1-5.9,2.7-7.7,5c-1.8,2.1-2.6,4.7-2.6,7.8
		v40.7c0,3.9-1.6,6.3-2.9,7.6c-1.3,1.3-3.7,2.9-7.6,2.9s-6.3-1.6-7.6-2.9c-1.3-1.3-2.9-3.7-2.9-7.6V12.1c0-3.9,1.6-6.3,2.9-7.6
		c1.3-1.3,3.7-2.9,7.6-2.9s6.3,1.6,7.6,2.9c0.6,0.6,1.2,1.4,1.7,2.4c0.8-0.6,1.7-1.1,2.6-1.7c5.1-3,10.8-4.5,17.1-4.5
		c5.5,0,9.8,0.9,13,2.7C536.5,5.8,538.4,9.9,537.3,14.3L537.3,14.3z"/>
	
		<linearGradient id="SVGID_00000006676045676872495490000014302837763084613788_" gradientUnits="userSpaceOnUse" x1="93.87" y1="76.19" x2="527.06" y2="76.19" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000006676045676872495490000014302837763084613788_);" d="M319.4,32.2c-1-3.7-2.4-7.1-4.2-10.3
		c-3.7-6.4-8.9-11.6-15.3-15.4c-6.4-3.8-13.7-5.7-21.6-5.7s-15.4,1.9-21.9,5.6c-6.5,3.8-11.6,8.9-15.4,15.4
		c-3.8,6.5-5.6,13.9-5.6,22s1.8,15.3,5.4,21.8c3.6,6.5,8.5,11.6,14.7,15.4c6.2,3.8,13.3,5.7,21.1,5.7s14.4-1.8,20.3-5.4
		c1.1-0.7,2.2-1.4,3.2-2.2c0.5,1.5,1.4,2.9,2.5,4c2,1.9,4.6,2.9,7.6,2.9s5.4-1,7.4-2.8c2.1-1.9,3.3-4.6,3.3-7.7V43.8
		C320.9,39.8,320.4,35.9,319.4,32.2L319.4,32.2z M299.9,43.9c0,12-9.8,21.7-21.8,21.7c-0.7,0-1.5,0-2.1-0.1c-0.4,0-0.7-0.1-1-0.1
		c-0.4-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.1-1-0.2c-0.4-0.1-0.8-0.2-1.2-0.3c-0.6-0.2-1.2-0.4-1.7-0.6c-0.3-0.1-0.6-0.3-0.9-0.4
		c-0.6-0.3-1.2-0.6-1.8-0.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.3-0.8-0.5c0,0-0.1,0-0.1-0.1c-0.3-0.2-0.5-0.4-0.8-0.6
		c-0.3-0.2-0.5-0.4-0.8-0.6l0,0c-0.2-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.7
		c-0.2-0.2-0.5-0.5-0.6-0.8c-0.2-0.2-0.4-0.5-0.6-0.8c-0.3-0.4-0.5-0.7-0.7-1.1c-0.1-0.2-0.2-0.4-0.4-0.6l-0.1-0.2
		c-0.1-0.2-0.3-0.5-0.4-0.8c-0.1-0.3-0.3-0.6-0.5-0.9c0,0,0,0,0-0.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.4-0.9-0.7-1.8-0.9-2.8
		c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.4-0.2-0.9-0.2-1.3c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.4,0-0.7,0-1.1v-0.1c0-0.4,0-0.7,0-1.1
		c0.6-11.5,10.1-20.6,21.7-20.6c0.7,0,1.5,0,2.1,0.1c0.4,0,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1,0.2c0.4,0.1,0.7,0.1,1,0.2
		c0.5,0.1,1,0.2,1.4,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,0.9,0.4c0,0,0.1,0,0.1,0.1c0.3,0.1,0.6,0.2,0.9,0.4
		c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.3c0.6,0.3,1.1,0.7,1.6,1.1c0.2,0.1,0.4,0.3,0.6,0.4
		c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.3,0.6,0.5c0,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0c0.2,0.2,0.5,0.5,0.7,0.7
		c0.2,0.2,0.5,0.5,0.7,0.7c0.5,0.5,0.9,1,1.3,1.6c0.2,0.3,0.4,0.5,0.6,0.8c0.2,0.3,0.4,0.7,0.6,1c0.1,0.2,0.3,0.5,0.4,0.7
		c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.3,0.6,0.4,1c0.1,0.3,0.2,0.7,0.3,1c0.2,0.5,0.3,1,0.4,1.5
		s0.2,1,0.3,1.5v0c0.1,0.3,0.1,0.6,0.1,1c0,0,0,0,0,0.1v0c0,0.2,0,0.4,0,0.6c0-0.4,0-0.8-0.1-1.1c0,0.5,0.1,0.9,0.1,1.4
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4C299.9,43.2,299.9,43.5,299.9,43.9L299.9,43.9L299.9,43.9z"/>
	
		<linearGradient id="SVGID_00000103982574255033155470000004900359340172840884_" gradientUnits="userSpaceOnUse" x1="93.87" y1="89.72" x2="527.06" y2="89.72" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000103982574255033155470000004900359340172840884_);" d="M295.7,31.1c-0.4-0.5-0.8-1.1-1.3-1.6
		C294.9,30,295.3,30.5,295.7,31.1z"/>
	
		<linearGradient id="SVGID_00000132780594768228471470000013940106209133125054_" gradientUnits="userSpaceOnUse" x1="93.87" y1="77.64" x2="332.04" y2="77.64" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000132780594768228471470000013940106209133125054_);" d="M299.8,42.6c0-0.2,0-0.3,0-0.4
		c0,0.1,0,0.1,0,0.2C299.8,42.4,299.8,42.5,299.8,42.6L299.8,42.6z"/>
	
		<linearGradient id="SVGID_00000155133444108158983110000003653367333564940975_" gradientUnits="userSpaceOnUse" x1="93.87" y1="73.94" x2="527.06" y2="73.94" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000155133444108158983110000003653367333564940975_);" d="M256.6,47.1c-0.1-0.7-0.2-1.4-0.2-2.1
		C256.4,45.7,256.5,46.4,256.6,47.1z"/>
	<path class="st9" d="M85.2,43.4c0,0.8,0,1.5-0.1,2.3v29.7c0,5.6-4.6,10.2-10.2,10.2h-1.1c-4.1,0-7.6-2.4-9.2-5.8
		c-0.3-0.6-0.5-1.2-0.7-1.8c-0.2-0.8-0.3-1.7-0.3-2.6l0.2-31.8v0c0,0,0-1.2,0-0.3c0-0.1,0-0.1,0-0.2c-0.1-11.8-9.6-21.3-21.4-21.4
		c0,0-0.1,0-0.2,0c-0.5,0-1,0-1.5,0c-16.9,0.9-30.3,14.9-30.3,32.1c0,15.8,11.4,28.9,26.4,31.6C16.1,82.7,0.1,64.9,0.1,43.4
		c0-23.5,19-42.5,42.5-42.5S85.2,19.9,85.2,43.4z"/>
	
		<linearGradient id="SVGID_00000173853734814347157360000011026737914039193767_" gradientUnits="userSpaceOnUse" x1="58.6925" y1="66.3932" x2="10.5021" y2="65.753" gradientTransform="matrix(1 0 0 -1 0 120)">
		<stop  offset="0.14" style="stop-color:#C7283D"/>
		<stop  offset="0.29" style="stop-color:#BE2639"/>
		<stop  offset="0.53" style="stop-color:#A6212F"/>
		<stop  offset="0.83" style="stop-color:#7F1A1F"/>
		<stop  offset="1" style="stop-color:#661515"/>
	</linearGradient>
	<path style="fill:url(#SVGID_00000173853734814347157360000011026737914039193767_);" d="M58.7,82.7c-3.2,1.3-6.7,2.3-10.3,2.8
		c-1.9,0.3-3.8,0.4-5.8,0.4s-3.9-0.1-5.8-0.4c-15-2.7-26.4-15.8-26.4-31.6c0-17.2,13.4-31.2,30.3-32.1c-11.2,0.8-20,10.1-20,21.5
		s9.6,21.5,21.5,21.5c6.6,0,12.5-3,16.4-7.6L58.7,82.7L58.7,82.7z"/>
	<path class="st11" d="M64.6,79.8c-1.9,1.1-3.8,2.1-5.9,2.9V57.2c2.5-3,4.3-6.7,4.9-10.7c0.2-1,0.2-1.9,0.2-2.9c0,0,0,0,0,0
		l-0.2,31.9c0,0.9,0.1,1.8,0.3,2.6C64.1,78.6,64.3,79.2,64.6,79.8L64.6,79.8z"/>
	<g class="st12">
		<path class="st11" d="M26.1,111.9c-1,0-1.9-0.2-2.7-0.6c-0.8-0.4-1.4-1.1-1.9-1.9c-0.5-0.8-0.7-1.8-0.7-3v-5.5
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v5.5c0,0.8,0.2,1.5,0.5,2.1
			s0.8,1,1.3,1.2c0.5,0.3,1.2,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.6,1.2-1.1c0.3-0.4,0.5-0.9,0.5-1.5h1.3
			c0,0.9-0.3,1.7-0.7,2.4s-1,1.3-1.8,1.7C27.9,111.7,27,111.9,26.1,111.9z M30.9,111.8c-0.3,0-0.5-0.1-0.7-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7
			v9.9c0,0.3-0.1,0.5-0.3,0.7C31.4,111.7,31.2,111.8,30.9,111.8z"/>
		<path class="st11" d="M36.8,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C37.4,111.8,37.1,111.9,36.8,111.9z M45.9,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C46.4,111.8,46.2,111.9,45.9,111.9z"/>
		<path class="st11" d="M56,111.9c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.2S50,107,50,105.9c0-1.2,0.3-2.2,0.8-3.1
			c0.5-0.9,1.2-1.6,2.1-2.1c0.9-0.5,1.9-0.8,3-0.8c0.9,0,1.6,0.2,2.4,0.5c0.7,0.3,1.4,0.8,1.9,1.5c0.2,0.2,0.3,0.4,0.2,0.7
			c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2,0-0.4-0.2-0.6-0.4c-0.8-0.8-1.7-1.2-2.9-1.2c-0.8,0-1.4,0.2-2,0.5
			c-0.6,0.4-1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2c0,0.8,0.2,1.5,0.5,2.2c0.3,0.6,0.8,1.1,1.4,1.5c0.6,0.4,1.3,0.6,2.1,0.6
			c0.5,0,1-0.1,1.4-0.2c0.4-0.1,0.8-0.3,1.1-0.6c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.4,0.6
			c0,0.2-0.1,0.4-0.2,0.6C58.9,111.4,57.5,111.9,56,111.9z"/>
		<path class="st11" d="M69,111.9c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1c0-1.2,0.3-2.2,0.8-3.1
			s1.2-1.6,2.1-2.1c0.9-0.5,1.9-0.8,3.1-0.8c1.2,0,2.2,0.3,3.1,0.8c0.9,0.5,1.6,1.2,2.1,2.1s0.8,2,0.8,3.1c0,1.2-0.3,2.2-0.8,3.1
			c-0.5,0.9-1.2,1.6-2.1,2.1C71.3,111.7,70.2,111.9,69,111.9z M69,110.1c0.8,0,1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.4-1.5
			s0.5-1.4,0.5-2.2s-0.2-1.6-0.5-2.2c-0.3-0.6-0.8-1.1-1.4-1.5c-0.6-0.4-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5
			c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2s0.2,1.6,0.5,2.2c0.3,0.6,0.8,1.1,1.4,1.5C67.6,110,68.3,110.1,69,110.1z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M79.4,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C79.9,111.8,79.7,111.9,79.4,111.9z M88.5,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C89,111.8,88.8,111.9,88.5,111.9z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M96.6,111.8c-0.4,0-0.7-0.2-1-0.7l-4.7-9.9c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.2,0.3-0.4,0.5-0.5
			c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0.1,0.4,0.2,0.5,0.4L97,110h-1l4.4-9.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.1,0.7,0
			c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.4,0,0.7l-4.7,9.9C97.3,111.6,97,111.8,96.6,111.8z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M109.6,111.9c-1.2,0-2.3-0.3-3.2-0.8c-0.9-0.5-1.6-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.2-2.2,0.7-3.1c0.5-0.9,1.2-1.6,2-2.1c0.9-0.5,1.9-0.8,3-0.8c1.1,0,2.1,0.2,2.9,0.8s1.4,1.2,1.9,2.1
			c0.4,0.9,0.7,1.9,0.7,3c0,0.2-0.1,0.5-0.3,0.6s-0.4,0.2-0.7,0.2h-8.9V105h9l-0.9,0.6c0-0.8-0.2-1.5-0.5-2.1
			c-0.3-0.6-0.7-1.1-1.2-1.4c-0.5-0.3-1.2-0.5-1.9-0.5c-0.8,0-1.5,0.2-2.1,0.6c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.7-0.5,1.4-0.5,2.2
			s0.2,1.6,0.6,2.2s0.9,1.2,1.5,1.5c0.6,0.4,1.4,0.6,2.3,0.6c0.5,0,0.9-0.1,1.4-0.3c0.5-0.2,0.9-0.4,1.2-0.6
			c0.2-0.2,0.4-0.2,0.7-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.3,0.6c-0.5,0.4-1.1,0.7-1.8,1
			C110.9,111.8,110.2,111.9,109.6,111.9z"/>
		<path class="st11" d="M118.5,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C119,111.8,118.8,111.9,118.5,111.9z M127.6,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C128.1,111.8,127.9,111.9,127.6,111.9z"/>
		<path class="st11" d="M131.7,102.1c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6
			c0.2-0.2,0.4-0.2,0.7-0.2h5.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.2-0.7,0.2
			H131.7z M136.8,111.8c-0.7,0-1.4-0.2-2-0.6c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.2V97.1c0-0.3,0.1-0.5,0.3-0.7
			c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v10.5c0,0.7,0.2,1.2,0.5,1.6c0.4,0.4,0.8,0.6,1.4,0.6h0.7
			c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3H136.8z"/>
		<path class="st11" d="M142.3,97.9c-0.4,0-0.7-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9c0.3-0.3,0.6-0.4,0.9-0.4
			c0.3,0,0.7,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C143,97.7,142.7,97.9,142.3,97.9z M142.3,111.8
			c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
			s0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C142.8,111.7,142.6,111.8,142.3,111.8z"/>
		<path class="st11" d="M152.9,111.9c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.3-2.2,0.8-3.1s1.2-1.6,2.1-2.1c0.9-0.5,1.9-0.8,3.1-0.8c1.2,0,2.2,0.3,3.1,0.8c0.9,0.5,1.6,1.2,2.1,2.1s0.8,2,0.8,3.1
			c0,1.2-0.3,2.2-0.8,3.1c-0.5,0.9-1.2,1.6-2.1,2.1C155.1,111.7,154.1,111.9,152.9,111.9z M152.9,110.1c0.8,0,1.5-0.2,2.1-0.5
			c0.6-0.4,1.1-0.9,1.4-1.5s0.5-1.4,0.5-2.2s-0.2-1.6-0.5-2.2c-0.3-0.6-0.8-1.1-1.4-1.5c-0.6-0.4-1.3-0.5-2.1-0.5
			c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2s0.2,1.6,0.5,2.2c0.3,0.6,0.8,1.1,1.4,1.5
			C151.4,110,152.1,110.1,152.9,110.1z"/>
		<path class="st11" d="M163.2,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C163.8,111.8,163.5,111.9,163.2,111.9z M172.3,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C172.8,111.8,172.6,111.9,172.3,111.9z"/>
		<path class="st11" d="M182.1,111.9c-1.1,0-2.1-0.3-2.9-0.8c-0.9-0.5-1.5-1.2-2-2.2c-0.5-0.9-0.7-1.9-0.7-3.1
			c0-1.2,0.3-2.2,0.8-3.1c0.5-0.9,1.2-1.6,2.2-2.2c0.9-0.5,1.9-0.8,3.1-0.8c1.1,0,2.2,0.3,3.1,0.8c0.9,0.5,1.6,1.3,2.1,2.2
			c0.5,0.9,0.8,1.9,0.8,3.1l-0.8,0.2c0,1.1-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.6-2,2.1S183.1,111.9,182.1,111.9z M182.4,110.1
			c0.8,0,1.5-0.2,2.1-0.6c0.6-0.4,1.1-0.9,1.4-1.5c0.4-0.6,0.5-1.4,0.5-2.2c0-0.8-0.2-1.5-0.5-2.2c-0.4-0.6-0.8-1.2-1.4-1.5
			c-0.6-0.4-1.3-0.6-2.1-0.6c-0.8,0-1.4,0.2-2.1,0.6s-1.1,0.9-1.5,1.5c-0.4,0.6-0.5,1.4-0.5,2.2c0,0.8,0.2,1.5,0.5,2.2
			c0.4,0.6,0.8,1.2,1.5,1.5S181.7,110.1,182.4,110.1z M187.4,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.5l0.4-2.1
			l1.6,0.6v5c0,0.3-0.1,0.5-0.3,0.7C188,111.8,187.7,111.9,187.4,111.9z"/>
		<path class="st11" d="M194.4,111.8c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.8-1.1-1.4c-0.3-0.6-0.4-1.3-0.4-2V95.8
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v12.1c0,0.6,0.1,1,0.3,1.4
			c0.2,0.4,0.5,0.5,0.8,0.5h0.5c0.3,0,0.5,0.1,0.7,0.3s0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.9,0.3H194.4z"/>
		<path class="st11" d="M210.5,111.9c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.2c-0.5-0.9-0.8-1.9-0.8-3.1V95.8
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v6.1c0.5-0.6,1.1-1.1,1.9-1.5
			c0.7-0.4,1.6-0.6,2.5-0.6c1.1,0,2,0.3,2.9,0.8c0.9,0.5,1.5,1.2,2,2.2c0.5,0.9,0.8,1.9,0.8,3.1s-0.3,2.2-0.8,3.1
			c-0.5,0.9-1.2,1.6-2.2,2.2C212.7,111.7,211.6,111.9,210.5,111.9z M210.5,110.1c0.8,0,1.5-0.2,2.1-0.6c0.6-0.4,1.1-0.9,1.5-1.5
			c0.4-0.6,0.5-1.4,0.5-2.2s-0.2-1.5-0.5-2.2c-0.4-0.6-0.8-1.1-1.5-1.5c-0.6-0.4-1.3-0.6-2.1-0.6c-0.8,0-1.5,0.2-2.1,0.6
			s-1.1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2s0.2,1.5,0.5,2.2c0.3,0.6,0.8,1.2,1.4,1.5S209.7,110.1,210.5,110.1z"/>
		<path class="st11" d="M224.9,111.9c-1,0-1.9-0.2-2.7-0.6c-0.8-0.4-1.4-1.1-1.9-1.9c-0.5-0.8-0.7-1.8-0.7-3v-5.5
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v5.5c0,0.8,0.2,1.5,0.5,2.1
			s0.8,1,1.3,1.2c0.5,0.3,1.2,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.6,1.2-1.1c0.3-0.4,0.5-0.9,0.5-1.5h1.3
			c0,0.9-0.3,1.7-0.7,2.4s-1,1.3-1.8,1.7C226.7,111.7,225.9,111.9,224.9,111.9z M229.7,111.8c-0.3,0-0.5-0.1-0.7-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7
			v9.9c0,0.3-0.1,0.5-0.3,0.7C230.2,111.7,230,111.8,229.7,111.8z"/>
		<path class="st11" d="M238.9,111.9c-1,0-1.9-0.1-2.7-0.4c-0.9-0.3-1.5-0.7-2-1.2c-0.2-0.2-0.3-0.5-0.2-0.7s0.2-0.5,0.4-0.7
			c0.2-0.2,0.5-0.3,0.8-0.2s0.5,0.1,0.7,0.3c0.3,0.3,0.7,0.6,1.3,0.8c0.6,0.2,1.2,0.3,1.8,0.3c0.9,0,1.6-0.2,2.1-0.5
			s0.7-0.7,0.7-1.2c0-0.5-0.2-0.9-0.7-1.2c-0.5-0.3-1.2-0.6-2.4-0.8c-1.5-0.3-2.5-0.7-3.2-1.3c-0.7-0.6-1-1.3-1-2.1
			c0-0.7,0.2-1.4,0.6-1.8c0.4-0.5,1-0.8,1.6-1.1c0.7-0.2,1.4-0.4,2.1-0.4c1,0,1.8,0.2,2.5,0.5c0.7,0.3,1.3,0.7,1.7,1.3
			c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.4,0.6c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.5-0.2-0.7-0.4c-0.4-0.4-0.8-0.6-1.2-0.8
			s-1-0.2-1.5-0.2c-0.7,0-1.3,0.1-1.7,0.4c-0.5,0.3-0.7,0.6-0.7,1.1c0,0.3,0.1,0.5,0.2,0.8c0.2,0.2,0.4,0.4,0.9,0.6
			c0.4,0.2,1,0.3,1.8,0.5c1.1,0.2,1.9,0.5,2.6,0.8s1.1,0.7,1.4,1.2c0.3,0.4,0.4,1,0.4,1.5c0,0.7-0.2,1.3-0.6,1.8
			c-0.4,0.5-0.9,0.9-1.6,1.3S239.9,111.9,238.9,111.9z"/>
		<path class="st11" d="M247.7,97.9c-0.4,0-0.7-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9c0.3-0.3,0.6-0.4,0.9-0.4
			c0.3,0,0.7,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C248.4,97.7,248.1,97.9,247.7,97.9z M247.7,111.8
			c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
			s0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C248.2,111.7,248,111.8,247.7,111.8z"/>
		<path class="st11" d="M253.9,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C254.4,111.8,254.2,111.9,253.9,111.9z M263,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C263.5,111.8,263.3,111.9,263,111.9z"/>
		<path class="st11" d="M273.2,111.9c-1.2,0-2.3-0.3-3.2-0.8c-0.9-0.5-1.6-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.2-2.2,0.7-3.1c0.5-0.9,1.2-1.6,2-2.1c0.9-0.5,1.9-0.8,3-0.8c1.1,0,2.1,0.2,2.9,0.8c0.8,0.5,1.4,1.2,1.9,2.1
			c0.4,0.9,0.7,1.9,0.7,3c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.7,0.2h-8.9V105h9l-0.9,0.6c0-0.8-0.2-1.5-0.5-2.1s-0.7-1.1-1.2-1.4
			c-0.5-0.3-1.2-0.5-1.9-0.5c-0.8,0-1.5,0.2-2.1,0.6c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.7-0.5,1.4-0.5,2.2s0.2,1.6,0.6,2.2
			s0.9,1.2,1.5,1.5c0.6,0.4,1.4,0.6,2.3,0.6c0.5,0,0.9-0.1,1.4-0.3c0.5-0.2,0.9-0.4,1.2-0.6c0.2-0.2,0.4-0.2,0.7-0.2
			c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.3,0.6c-0.5,0.4-1.1,0.7-1.8,1
			C274.6,111.8,273.9,111.9,273.2,111.9z"/>
		<path class="st11" d="M285.4,111.9c-1,0-1.9-0.1-2.7-0.4c-0.9-0.3-1.5-0.7-2-1.2c-0.2-0.2-0.3-0.5-0.2-0.7s0.2-0.5,0.4-0.7
			c0.2-0.2,0.5-0.3,0.8-0.2c0.3,0,0.5,0.1,0.7,0.3c0.3,0.3,0.7,0.6,1.3,0.8c0.6,0.2,1.2,0.3,1.8,0.3c0.9,0,1.6-0.2,2.1-0.5
			s0.7-0.7,0.7-1.2c0-0.5-0.2-0.9-0.7-1.2s-1.2-0.6-2.4-0.8c-1.5-0.3-2.5-0.7-3.2-1.3c-0.7-0.6-1-1.3-1-2.1c0-0.7,0.2-1.4,0.6-1.8
			c0.4-0.5,1-0.8,1.6-1.1c0.7-0.2,1.4-0.4,2.1-0.4c1,0,1.8,0.2,2.5,0.5c0.7,0.3,1.3,0.7,1.7,1.3c0.2,0.2,0.3,0.4,0.3,0.7
			c0,0.2-0.1,0.4-0.4,0.6c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.5-0.2-0.7-0.4c-0.4-0.4-0.8-0.6-1.2-0.8c-0.4-0.2-1-0.2-1.5-0.2
			c-0.7,0-1.3,0.1-1.7,0.4c-0.5,0.3-0.7,0.6-0.7,1.1c0,0.3,0.1,0.5,0.2,0.8s0.4,0.4,0.9,0.6c0.4,0.2,1,0.3,1.8,0.5
			c1.1,0.2,1.9,0.5,2.6,0.8c0.6,0.3,1.1,0.7,1.4,1.2c0.3,0.4,0.4,1,0.4,1.5c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-0.9,0.9-1.6,1.3
			C287.2,111.8,286.4,111.9,285.4,111.9z"/>
		<path class="st11" d="M297.2,111.9c-1,0-1.9-0.1-2.7-0.4c-0.9-0.3-1.5-0.7-2-1.2c-0.2-0.2-0.3-0.5-0.2-0.7s0.2-0.5,0.4-0.7
			c0.2-0.2,0.5-0.3,0.8-0.2c0.3,0,0.5,0.1,0.7,0.3c0.3,0.3,0.7,0.6,1.3,0.8c0.6,0.2,1.2,0.3,1.8,0.3c0.9,0,1.6-0.2,2.1-0.5
			s0.7-0.7,0.7-1.2c0-0.5-0.2-0.9-0.7-1.2s-1.2-0.6-2.4-0.8c-1.5-0.3-2.5-0.7-3.2-1.3c-0.7-0.6-1-1.3-1-2.1c0-0.7,0.2-1.4,0.6-1.8
			c0.4-0.5,1-0.8,1.6-1.1c0.7-0.2,1.4-0.4,2.1-0.4c1,0,1.8,0.2,2.5,0.5c0.7,0.3,1.3,0.7,1.7,1.3c0.2,0.2,0.3,0.4,0.3,0.7
			c0,0.2-0.1,0.4-0.4,0.6c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.5-0.2-0.7-0.4c-0.4-0.4-0.8-0.6-1.2-0.8c-0.4-0.2-1-0.2-1.5-0.2
			c-0.7,0-1.3,0.1-1.7,0.4c-0.5,0.3-0.7,0.6-0.7,1.1c0,0.3,0.1,0.5,0.2,0.8s0.4,0.4,0.9,0.6c0.4,0.2,1,0.3,1.8,0.5
			c1.1,0.2,1.9,0.5,2.6,0.8c0.6,0.3,1.1,0.7,1.4,1.2c0.3,0.4,0.4,1,0.4,1.5c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-0.9,0.9-1.6,1.3
			C299,111.8,298.2,111.9,297.2,111.9z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M384.2,111.9c-1.2,0-2.3-0.3-3.2-0.8c-0.9-0.5-1.6-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.2-2.2,0.7-3.1s1.2-1.6,2-2.1c0.9-0.5,1.9-0.8,3-0.8c1.1,0,2.1,0.2,2.9,0.8c0.8,0.5,1.4,1.2,1.9,2.1
			c0.4,0.9,0.7,1.9,0.7,3c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.7,0.2h-8.9V105h9l-0.9,0.6c0-0.8-0.2-1.5-0.5-2.1s-0.7-1.1-1.2-1.4
			c-0.5-0.3-1.2-0.5-1.9-0.5c-0.8,0-1.5,0.2-2.1,0.6c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.7-0.5,1.4-0.5,2.2s0.2,1.6,0.6,2.2
			s0.9,1.2,1.5,1.5c0.6,0.4,1.4,0.6,2.3,0.6c0.5,0,0.9-0.1,1.4-0.3c0.5-0.2,0.9-0.4,1.2-0.6c0.2-0.2,0.4-0.2,0.7-0.2
			c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.3,0.6c-0.5,0.4-1.1,0.7-1.8,1
			C385.6,111.8,384.9,111.9,384.2,111.9z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M396.8,111.8c-0.4,0-0.7-0.2-1-0.7l-4.7-9.9c-0.1-0.2-0.1-0.5,0-0.7c0.1-0.2,0.3-0.4,0.5-0.5
			c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0.1,0.4,0.2,0.5,0.4l4.4,9.6h-1l4.4-9.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.1,0.7,0
			c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.4,0,0.7l-4.7,9.9C397.5,111.6,397.2,111.8,396.8,111.8z"/>
	</g>
	<g class="st12">
		<path class="st11" d="M409.7,111.9c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.3-2.2,0.8-3.1c0.5-0.9,1.2-1.6,2.1-2.1c0.9-0.5,1.9-0.8,3.1-0.8s2.2,0.3,3.1,0.8c0.9,0.5,1.6,1.2,2.1,2.1
			c0.5,0.9,0.8,2,0.8,3.1c0,1.2-0.3,2.2-0.8,3.1c-0.5,0.9-1.2,1.6-2.1,2.1C411.9,111.7,410.8,111.9,409.7,111.9z M409.7,110.1
			c0.8,0,1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.4-1.5c0.3-0.6,0.5-1.4,0.5-2.2s-0.2-1.6-0.5-2.2c-0.3-0.6-0.8-1.1-1.4-1.5
			c-0.6-0.4-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2s0.2,1.6,0.5,2.2
			c0.3,0.6,0.8,1.1,1.4,1.5C408.2,110,408.9,110.1,409.7,110.1z"/>
		<path class="st11" d="M421.7,111.8c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.8-1.1-1.4c-0.3-0.6-0.4-1.3-0.4-2V95.8
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v12.1c0,0.6,0.1,1,0.3,1.4
			c0.2,0.4,0.5,0.5,0.8,0.5h0.5c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.9,0.3
			H421.7z"/>
		<path class="st11" d="M430.4,111.9c-1,0-1.9-0.2-2.7-0.6c-0.8-0.4-1.4-1.1-1.9-1.9c-0.5-0.8-0.7-1.8-0.7-3v-5.5
			c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v5.5c0,0.8,0.2,1.5,0.5,2.1
			s0.8,1,1.3,1.2c0.5,0.3,1.2,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.6,1.2-1.1c0.3-0.4,0.5-0.9,0.5-1.5h1.3
			c0,0.9-0.3,1.7-0.7,2.4c-0.4,0.7-1,1.3-1.8,1.7C432.3,111.7,431.4,111.9,430.4,111.9z M435.2,111.8c-0.3,0-0.5-0.1-0.7-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v9.9
			c0,0.3-0.1,0.5-0.3,0.7C435.8,111.7,435.5,111.8,435.2,111.8z"/>
		<path class="st11" d="M439.6,102.1c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6
			c0.2-0.2,0.4-0.2,0.7-0.2h5.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.2-0.7,0.2
			H439.6z M444.6,111.8c-0.7,0-1.4-0.2-2-0.6c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.2V97.1c0-0.3,0.1-0.5,0.3-0.7
			c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v10.5c0,0.7,0.2,1.2,0.5,1.6c0.4,0.4,0.8,0.6,1.4,0.6h0.7
			c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.7s-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3H444.6z"/>
		<path class="st11" d="M450.2,97.9c-0.4,0-0.7-0.1-0.9-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-0.9c0.3-0.3,0.6-0.4,0.9-0.4
			c0.3,0,0.7,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.4,0.9c0,0.4-0.1,0.7-0.4,1C450.9,97.7,450.5,97.9,450.2,97.9z M450.2,111.8
			c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
			s0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C450.7,111.7,450.5,111.8,450.2,111.8z"/>
		<path class="st11" d="M460.8,111.9c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1
			c0-1.2,0.3-2.2,0.8-3.1c0.5-0.9,1.2-1.6,2.1-2.1c0.9-0.5,1.9-0.8,3.1-0.8c1.2,0,2.2,0.3,3.1,0.8c0.9,0.5,1.6,1.2,2.1,2.1
			c0.5,0.9,0.8,2,0.8,3.1c0,1.2-0.3,2.2-0.8,3.1c-0.5,0.9-1.2,1.6-2.1,2.1C463,111.7,461.9,111.9,460.8,111.9z M460.8,110.1
			c0.8,0,1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.4-1.5c0.3-0.6,0.5-1.4,0.5-2.2s-0.2-1.6-0.5-2.2c-0.3-0.6-0.8-1.1-1.4-1.5
			c-0.6-0.4-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-1.1,0.9-1.4,1.5c-0.3,0.6-0.5,1.4-0.5,2.2s0.2,1.6,0.5,2.2
			s0.8,1.1,1.4,1.5C459.3,110,460,110.1,460.8,110.1z"/>
		<path class="st11" d="M471.1,111.9c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-9.9c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3
			c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v9.9c0,0.3-0.1,0.5-0.3,0.7C471.6,111.8,471.4,111.9,471.1,111.9z M480.2,111.9
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-5.5c0-0.9-0.2-1.6-0.5-2.1s-0.7-1-1.3-1.2c-0.5-0.3-1.2-0.4-1.9-0.4
			c-0.7,0-1.2,0.1-1.8,0.4c-0.5,0.3-0.9,0.6-1.3,1c-0.3,0.4-0.5,0.9-0.5,1.5h-1.3c0-0.9,0.2-1.7,0.7-2.4c0.4-0.7,1.1-1.3,1.8-1.7
			c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,3v5.5c0,0.3-0.1,0.5-0.3,0.7
			C480.7,111.8,480.5,111.9,480.2,111.9z"/>
	</g>
	<g class="st12">
		<path class="st13" d="M363.4,111.3c-0.2-0.3-0.1-0.7,0.1-1.1l3.7-5.9l2,0.6l-4,6.4c-0.1,0.2-0.2,0.3-0.4,0.4
			c-0.1,0.1-0.3,0.1-0.5,0.1C363.9,111.8,363.6,111.7,363.4,111.3z M372.6,111.5c-0.2-0.2-0.3-0.4-0.3-0.7v-5.6h-3
			c-1.1,0-2-0.2-2.9-0.7c-0.8-0.4-1.5-1-2-1.8c-0.5-0.8-0.7-1.6-0.7-2.6c0-1,0.2-2,0.7-2.8s1.1-1.4,2-1.9c0.8-0.5,1.8-0.7,2.9-0.7h4
			c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v15c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3
			C373.1,111.8,372.8,111.7,372.6,111.5z M372.4,96.7h-3c-0.7,0-1.3,0.1-1.8,0.4s-0.9,0.7-1.3,1.2c-0.3,0.5-0.5,1.1-0.5,1.8
			c0,0.6,0.2,1.2,0.5,1.6s0.7,0.9,1.3,1.1s1.1,0.4,1.8,0.4h3V96.7z"/>
	</g>
</g>
<g id="scritte_editabili" class="st14">
	<text transform="matrix(1 0 0 1 19.04 111.83)" class="st15 st16 st17">unco</text>
	<text transform="matrix(1 0 0 1 76.4 111.83)" class="st15 st16 st17">n</text>
	<text transform="matrix(1 0 0 1 90.55 111.83)" class="st15 st16 st17">v</text>
	<text transform="matrix(1 0 0 1 102.08 111.83)" class="st15 st16 st17">entional business</text>
	<text transform="matrix(1 0 0 1 376.76 111.83)" class="st15 st16 st17">e</text>
	<text transform="matrix(1 0 0 1 390.78 111.83)" class="st15 st16 st17">v</text>
	<text transform="matrix(1 0 0 1 402.31 111.83)" class="st15 st16 st17">olution</text>
	<text transform="matrix(-1 0 0 1 376.5801 111.83)" class="st18 st16 st17">R</text>
</g>
</svg>
`;

  // ######################################
  // FUNCTION: constructor
  constructor(
    private notification: NzNotificationService,
    private message: NzMessageService,
    private fire: FireService,
    private http: HttpClient,
    private node: NodeService,
    private modal: NzModalService
  ) {
    this.nodePath = this.node.baseUrl;

    // SUBSCRIBE DISCONECTION EVENT
  }

  // ######################################
  // FUNCTION: saveKey
  saveKey(data: string) {
    this.keyBuffer = Buffer.from(data, "utf8");
  }

  // ######################################
  // FUNCTION: encryptDataGlobal
  async encryptDataGlobal(data: string) {
    const crc32Key = crc32(data);
    const base64Key = btoa(crc32Key.toString());

    if (!this.sodium) {
      this.sodium = await SodiumPlus.auto();
    }
    const nonce = await this.sodium.randombytes_buf(24);
    const keyPair = new CryptographyKey(this.keyBuffer);
    const sKey = await this.sodium.crypto_box_secretkey(keyPair);
    const pKey = await this.sodium.crypto_box_publickey(keyPair);
    const encrypted = await this.sodium.crypto_box(base64Key, nonce, sKey, pKey);

    return nonce.toString("hex") + encrypted.toString("hex");
  }

  // ######################################
  // FUNCTION: randomMath
  randomMath() {
    return Math.random();
  }

  // ######################################
  // FUNCTION: createSuccessNotification
  createSuccessNotification(title: string, message: string, duration: number = 4500) {
    this.notification.create("success", title, message, { nzDuration: duration });
  }

  // ######################################
  // FUNCTION: createErrorNotification (popup on topright with close)
  createErrorNotification(title: string, message: string, duration: number = 4500) {
    this.notification.create("error", title, message, { nzDuration: duration });
  }

  // ######################################
  // FUNCTION: createCustomNotification (popup on topright with close)
  createCustomNotification(type: string, title: string, message: string, options?: NzNotificationDataOptions) {
    this.notification.create(type, title, message, options);
  }

  // ######################################
  // FUNCTION: createCustomMessage (popup on top without close)
  createCustomMessage(type: string, message: string) {
    this.message.create(type, message);
  }

  // ######################################
  // FUNCTION: dynamicSort
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  // ######################################
  // FUNCTION: dynamicNumberSort
  dynamicNumberSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      return (
        a[property].localeCompare(b[property], undefined, {
          numeric: true,
          sensitivity: "base",
        }) * sortOrder
      );
    };
  }

  // ######################################
  // FUNCTION: chartDisplay
  public chartProgress: boolean = false;
  public chartSave: boolean = false;
  public chartData: any[] = [];
  public chartVisible: boolean = false;

  public chartType: ChartType = ChartType.ColumnChart;
  public chartBarType: ChartType = ChartType.ColumnChart;
  public chartLineType: ChartType = ChartType.LineChart;

  // ## CHART COLUMNS NAMES
  public chartColumnNames: any = ["tratto", "value"];
  public chartBarColumnNames = [
    "tratto",
    "value",
    { role: "style" },
    { role: "annotation" },
    { role: "tooltip", type: "string", p: { html: true } },
  ];
  public chartLineColumnNames = [
    "tratto",
    "value",
    { role: "style" },
    { role: "annotation" },
    { role: "tooltip", type: "string", p: { html: true } },
  ];
  // ## CHART PRINT OPTIONS
  public chartPrintOptions = {};
  public chartBarPrintOptions = {
    title: "",
    isStacked: true,
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    axisTitlesPosition: "out",

    chartArea: {
      left: "5%",
      top: "5%",
      height: "67%",
      width: "92%",
    },
    fontSize: 28,
    legend: { position: "none" },

    vAxis: {
      minValue: -100,
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textPosition: "out",
      slantedText: true,
      slantedTextAngle: 90,
      textStyle: {
        fontSize: 12,
        fontFamily: "Poppins",
        bold: true,
      },
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 25,
        fontFamily: "Poppins",
        color: "#303030",
        bold: true,

      },
      datum: {
        stem: { length: 0 },
        style: (value) => {
          // If the value is negative, position the annotation inside the bar
          return value < 0 ? { placement: 'in', align: 'center' } : { placement: 'out', align: 'end' };
        },
      },
    },
    highContrast: true,

  };


  formatNominativo(fullName) {
    let nameParts = fullName.trim().split(' ');
    let nome = nameParts[0];
    let cognome = nameParts.slice(1).join(' ');

    function maskString(str) {
      const cleanStr = str.replace(/\s+/g, '');

      if (cleanStr.length <= 3) {
        return cleanStr.toUpperCase();
      }

      return cleanStr.substring(0, 3).toUpperCase() + cleanStr.slice(3).replace(/./g, '*');
    }
    return maskString(nome) + " " + maskString(cognome);
  }

  async downloadFile(path: string): Promise<string> {
    let params = { "filepath": path };

    return new Promise((resolve, reject) => {
      this.node.getData(params, "/download").subscribe({
        next: async (data) => {
          if (data instanceof Blob) {
            // Read the Blob as base64 data URL
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result as string;
              resolve(base64data); // Return the base64-encoded PDF
            };
            reader.onerror = (err) => {
              reject(new Error("Failed to read PDF Blob: " + err));
            };
            reader.readAsDataURL(data); // Convert the Blob to a base64 data URL
          } else {
            reject(new Error("Failed to download file: Invalid data type"));
          }
        },
        error: (err) => {
          console.error('Error downloading file:', err);
          reject(err);
        }
      });
    });
  }

  destroyChartAmajor(time = 3000) {
    setTimeout(() => {
      const iframe = document.getElementById('questionarioID');
      if (iframe) {
        iframe.remove();
      }
    }, time);
  }


  async exportChartAmajor(dataChart, questionario, nominativo, isAdmin = true, isClima = false) {
    let urlCopertina = '';
    // Fetch the cover PDF (if available)
    if ((questionario.copertina_report?.ITA).length > 0) {
      try {
        urlCopertina = await this.downloadFile(questionario.copertina_report?.ITA);
      } catch (error) {
      }
    }

    const nominativoAnonimaze = !isClima
      ? this.formatNominativo(nominativo)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")  // Rimuove gli accenti
        .replace(/[^a-zA-Z0-9-_:/\s]/g, "") // Rimuove i caratteri non alfanumerici, ma conserva trattino, underscore, due punti e spazi
      : nominativo
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")  // Rimuove gli accenti
        .replace(/[^a-zA-Z0-9-_:/\s]/g, ""); // Rimuove i caratteri non alfanumerici, ma conserva trattino, underscore, due punti e spazi

    let labels;
    let values;
    let colors;
    let adjustedColors;

    if (isAdmin) {
      // Prepare the data for the histogram
      labels = dataChart.map(item => String(item[0]));
      values = dataChart.map(item => item[1]);
      colors = dataChart.map(item => item[2].replace('color: ', "").replace(';', ""));
      adjustedColors = colors.map(color => color === '#FFFF00' ? '#000000' : color);
    }
    else {
      labels = dataChart[0];
      values = dataChart[1];
      colors = dataChart[2];
      adjustedColors = dataChart[2].map(color => color === 'yellow' ? 'black' : color);
    }

   

    const htmlContent = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
    <title>Amajor: Questionario</title>
    <link rel="shortcut icon" href="https://www.amajorsb.com/wp-content/uploads/2021/03/favicon.png" type="image/x-icon">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet">
    <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.js"></script>
    <style>
        body {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
            text-align: center;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            margin-bottom: 20px;
        }
        .logo {
            width: 150px;    /* Stretch to full width */
            height: 80px;   /* Stretch to full height */
            object-fit: cover; /* Ensures the image fills the container */
        }
        .top-right { text-align: right; }
        .underline-dashed {
            border-bottom: 2px dashed black;
            padding-bottom: 2px;
        }
        .date { font-size: 14px; color: black; }
        .title {
            font-size: 24px;
            margin: 0 0 0 20px;
        }
        .chart-container, .pdf-container {
            width: 95%;
            margin: 20px auto;
        }
        .chart-container {
            height: 80%;
            border: 1px solid #ccc;
        }
        canvas {
            display: block;
            width: 100% !important;
            height: 100% !important;
            -webkit-transform: translateZ(0); /* Fix rendering issues */
        }

        .pdf-container {
            height: 140vh;
            width:100%;
            page-break-before: always;
            overflow: hidden;
        }
        img { width: 100%; height: 100%; }
        @media print {
            body { margin: 0; }
            .header, .chart-container { page-break-after: avoid; page-break-inside: avoid; }
            .top-right { display: block; }
            canvas { background: white; }
            .chart-container { height: auto; }
        }
    </style>
</head>
<body>
    <div class="header">
    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="100" viewBox="0 0 100 50">
    ${ this.svgContent}
    </svg>
   
        <h1 class="title">${questionario.titolo.ITA}</h1>
        <div class="top-right">
            <p><span class="underline-dashed">${nominativoAnonimaze}</span></p>
            <p class="date">${new Date().toLocaleDateString()}</p>
        </div>
    </div>
    <div class="chart-container">
        <canvas id="myHistogram"></canvas>
    </div>
    
        ${urlCopertina !== '' ? ` <div class="pdf-container"> <img id="pdfImage" src="" alt="PDF Image"/>     </div>` : ``}

    <script>
            async function renderPDF(pdfUrl) {
            try {
                const loadingTask = pdfjsLib.getDocument(pdfUrl);
                const pdf = await loadingTask.promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 2 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({ canvasContext: context, viewport }).promise;
                document.getElementById('pdfImage').src = canvas.toDataURL();
            } catch (error) {
               
            }
        }

        ${urlCopertina !== '' ? `renderPDF("${urlCopertina}")` : ''};

        const ctx = document.getElementById('myHistogram').getContext('2d');
        const myHistogram = new Chart(ctx, {
            type: ${isClima ? "'line'" : "'bar'"},
            data: {
                labels: ${JSON.stringify(labels)},
                datasets: [{
                    label: 'Values',
                    data: ${JSON.stringify(values)},
                    backgroundColor: ${JSON.stringify(colors)},
                    borderWidth: 1,
                    borderColor: '#000',
                    pointStyle: ${isClima ? "'triangle'" : "''"},
                    pointRadius: ${isClima ? 12 : 4},
                    pointHoverRadius: ${isClima ? 10 : 6},
                    borderDash: ${isClima ? "[5, 5]" : "[]"},
                    datalabels: {
                        anchor: (context) => context.dataset.data[context.dataIndex] < 0 ? 'start' : 'end',
                        align: (context) => context.dataset.data[context.dataIndex] < 0 ? 'start' : 'end',
                        font: { size: ${isClima ? 20 : 24}, weight: 'bold' },
                        formatter: (value) => value
                    }
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 0 // Disable all animations
                  },
                layout: {

                    padding: {
                        right: ${isClima ? 15 : 0}, 
                        left:  ${isClima ? 15 : 0} 
                    }
                },
                plugins: {
                    legend: { display: false },
                    datalabels: { display: true, color: '#000' }
                },
                scales: {
                    x: { 
                    type: 'category',
                    offset: true,
                        ticks: {
                            autoSkip: false,
                            maxRotation: ${isClima ? 0 : 90},
                            minRotation: ${isClima ? 0 : 90},
                            color: ${JSON.stringify(adjustedColors)},
                            font: { size: ${isClima ? 20 : 16}, weight: 'bold' },
                            callback: function(value) {
                                let wordLabel = this.getLabelForValue(value);
                                return wordLabel.split(' ').reduce((acc, word, i) => {
                                    if (i % ${isClima ? 1 : 3} === 0) acc.push([]);
                                    acc[acc.length - 1].push(word);
                                    return acc;
                                }, []).map(line => line.join(' '));
                            }
                        },
                        grid: {
                            display:  ${isClima ? false : true},
                        },
                        
                        border: {
                            display: ${isClima ? false : true}
                        }
                    },
                    y: {
                        beginAtZero: true,
                        min: -115,
                        max: 115,
                        ticks: {
                            display: ${isClima ? true : false},  
                            stepSize: 20,
                            font: { 
                                size: 15, 
                                weight: 'normal'  
                            },
                            callback: function(value) {
                                // Hide labels for values less than -100 and greater than 100
                                if (value < -100 || value > 100) {
                                    return '';  // Return an empty string to hide the label
                                }
                                return value;  // Otherwise, return the value as the label
                            }
                        },
                        grid: {
                            drawBorder:  ${isClima ? true : false},
                            drawTicks: ${isClima ? true : false},
                            color: (context) => context.tick.value === 0 ? '#000' : ( (${isClima}&&context.tick.value<=100 && context.tick.value>=-100 )? '#D3D3D3' : 'rgba(255, 255, 255, 0)')
                        }
                    }
                }
            },
            plugins: [ChartDataLabels]
        });

       setTimeout(() => {
            const element = document.body;
            const options = {
                margin:       5,
                filename:     '${nominativoAnonimaze}.pdf',
                image:        { type: 'jpeg', quality: 0.98 },
                html2canvas:  { scale: 3 , useCORS: true },
                jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
            };
            html2pdf().from(element).set(options).save();
        }, 2500);  // Increased wait time to ensure all content (charts, images) is fully rendered
    </script>
</body>
</html>
`;

    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    // Create an iframe to render the content
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '500px';
    iframe.style.border = 'none';
    iframe.id = 'questionarioID';
    document.body.appendChild(iframe);

    iframe.src = url;
  }

  managePhoneNumber(address) {
    address = String(address);
    if (address) {
      address = address.trim();
      if (!address.includes('@')) {
        address = address.replace(/\D/g, '');
        if (address.length === 10) {
          address = `+39${address}`;
        } else if (!address.startsWith('+')) {
          address = `+${address}`;
        }
      }
    }
    return address;
  }


  public chartLinePrintOptions = {
    title: "",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    pointShape: "triangle",
    pointSize: 30,
    colors: ["#000"],
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },

    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: {
        color: "#303030",
        fontSize: 18,
      },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textPosition: "out",
      textStyle: {
        fontSize: 20,
        fontFamily: "Poppins",
        bold: true,
      },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 25,
        fontFamily: "Poppins"
      },
      stem: {
        length: 38,
      },
    },
  };
  // ## CHART DISPLAY OPTIONS
  public chartDisplayOptions = {};
  public chartBarDisplayOptions = {
    title: "",
    backgroundColor: "transparent",
    tooltip: {
      isHtml: true,
      trigger: "selection",
    },
    responsive: true,
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },
    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80", color: "#ff0000" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textStyle: { color: "#303030", fontSize: 12 },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 28,
      },
    },
  };

  public chartLineDisplayOptions = {
    title: "",
    backgroundColor: "transparent",
    tooltip: {
      isHtml: true,
      trigger: "selection",
    },
    responsive: true,
    width: "100%",
    height: "100%",
    enableInteractivity: true,
    axisTitlesPosition: "out",
    isStacked: true,
    pointShape: "triangle",
    pointSize: 30,
    chartArea: {
      left: "5%",
      top: "5%",
      height: "86%",
      width: "92%",
    },
    fontSize: 17,
    legend: { position: "none" },

    vAxis: {
      textPosition: "out",
      ticks: [
        { v: -100, f: "-100" },
        { v: -80, f: "-80" },
        { v: -60, f: "-60" },
        { v: -40, f: "-40" },
        { v: -20, f: "-20" },
        { v: 0, f: "0" },
        { v: 20, f: "20" },
        { v: 40, f: "40" },
        { v: 60, f: "60" },
        { v: 80, f: "80" },
        { v: 100, f: "100" },
      ],
      viewWindow: {
        max: 100,
        min: -100,
      },
      textStyle: { color: "#303030" },
      gridlines: {
        color: "#aaa",
      },
    },
    hAxis: {
      textStyle: { color: "#303030", fontSize: 12 },
    },
    annotations: {
      textStyle: {
        bold: false,
        opacity: 1,
        fontSize: 22,
      },
      stem: {
        length: 28,
      },
    },
  };
  // ####################
  chartDisplay(data: any, q: any) {

    q.tratti.forEach((t, tIndex: number) => {
      let pCount = 0;
      data.domande.forEach((d) => {
        let p = t.punteggi[d.domanda];
        if (p) {
          let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
          if (dIndex >= 0) {
            let r = p.domanda.risposte[dIndex];
            pCount += parseFloat(r.valore) || 0;
          }
        }
      });

      let tCount = pCount;
      this.chartType = this.chartBarType;
      this.chartDisplayOptions = this.chartBarDisplayOptions;
      this.chartColumnNames = this.chartBarColumnNames;

      if (q["report"] == 2) {
        this.chartType = this.chartLineType;
        this.chartDisplayOptions = this.chartLineDisplayOptions;
        this.chartColumnNames = this.chartLineColumnNames;

        // ######################## SCALATURA NUOVO ALGORITMO
        const scalato = ((tCount - t.min) / (t.max - t.min)) * 200 - 100;
        tCount = Math.round(scalato);
      } else if (t.transform) {
        let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
        if (pIndex > -1) {
          tCount = t.transform[pIndex].trasformato;
        }
      }
      this.chartData[tIndex] = [];
      this.chartData[tIndex].push(window.innerWidth < 1200 ? t.codice : t.titolo["ITA"]);
      if (q.titolo.ITA.toLowerCase().includes('young')) {
        tCount = tCount >= 0? tCount - 100 : tCount + 100;
        tCount = Number.isNaN(Number(tCount)) ? 0 : Math.round(tCount);
      }
      
      this.chartData[tIndex].push(tCount);
      if (q["report"] != 2) {
        if (t.gruppo === 1) {
          this.chartData[tIndex].push("color: #FD0000;");
        } else if (t.gruppo === 2) {
          this.chartData[tIndex].push("color: #FFA500;");
        } else if (t.gruppo === 3) {
          this.chartData[tIndex].push("color: #00B0F0;");
        } else if (t.gruppo === 4) {
          this.chartData[tIndex].push("color: #92D050;");
        } else if (t.gruppo === 5) {
          this.chartData[tIndex].push("color: #FFFF00;");
        } else {
          this.chartData[tIndex].push("color: #661615;");
        }
      }
      this.chartData[tIndex].push(tCount);
      this.chartData[tIndex].push(
        '<div style="padding:15px 15px 15px 15px; color: #000">' +
        "<table>" +
        "<tr>" +
        t.titolo["ITA"] + "</tr>" +
        "</table>" +
        "</div>"
      );

      if (tIndex === q.tratti.length - 1) {
        this.chartProgress = false;
      }
    });
  }

  extractExtraQuestions(questions, extraQuestions, surveyDetails): any {
    let personalComments: string[] = [];

    const questionTitle: string[] = [];
    for (let i = questions.length - extraQuestions; i <= questions.length - 1; i++) {
      let onlyQuestionString = surveyDetails.domande[i].titolo['ITA'];
      if (onlyQuestionString?.includes('\n')) onlyQuestionString = onlyQuestionString.replaceAll('\n', ' ');
      questionTitle.push(onlyQuestionString);
      if (questions[i].commento === '') {
        const value = surveyDetails.domande[i].risposte.find(answer => answer.codice === questions[i].risposta);
        const aswerString = value.titolo['ITA'];
        personalComments.push(aswerString);

      } else {
        if (questions[i].commento.includes('\n')) questions[i].commento = questions[i].commento.replaceAll('\n', '""');
        personalComments.push(questions[i].commento);
      }
    }
    return { personalComments, questionTitle };
  }

  // ######################################### EXPORT PER EXCEL
  async chartGroupExportExcell(licenza, reparti, fromExcelButton, extraQuestions) {
    this.chartType = this.chartLineType;

    const repartiId = reparti.map((r) => r.id);
    const repartiNome = reparti.map((r) => r.nome);

    let nCompilazioni: number = 0;
    this.chartProgress = true;

    //// console.log(reparti);
    //// console.log(licenza);
    let tempR: any[] = [];
    const compilationPromises = licenza.compilazioni.map(async (compilazione) => {
      const rep = compilazione.id.split("U")[0];
      // console.log(compilazione);

      // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SELEZIONATI
      if (repartiId.includes(rep)) {
        nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE
        if (fromExcelButton.comment) {
          let personalComments: string[] = [];
          let questionTitle: string[] = [];
          // ################### section for extract extra question ##############################
          if (extraQuestions !== null) {
            // api to get survey data
            const result = await this.fire.getDocOnce("clienti", compilazione.licenza.azienda, "licenze", compilazione.licenza.licenza).toPromise();
            const surveyData = result.data();

            // api that get aswers label and question
            const data = await this.fire.getDocOnce('questionari', surveyData.questionario, 'versioni', surveyData.versione).toPromise();
            const surveyDetails = data.data();

            // function that extract comment and questions
            personalComments = this.extractExtraQuestions(compilazione.domande, extraQuestions, surveyDetails).personalComments;
            questionTitle = this.extractExtraQuestions(compilazione.domande, extraQuestions, surveyDetails).questionTitle;
          } else {
            // ############ section that exrtract all comment #################
            compilazione.domande.forEach(question => {
              if (question.commento === '') question.commento = '""';
              if (question.commento.includes('\n')) question.commento = question.commento.replaceAll('\n', ' ');
              personalComments.push(question.commento);
            });
          }
          tempR[0] = questionTitle.join(';');
          tempR.push(personalComments.join(';'));

        } else {
          // ############ section that extract only aswers ################
          compilazione.domande.forEach((d, index) => {
            const rIndex = parseFloat(d.domanda.substring(1));

            if (!tempR[rIndex]) tempR[rIndex] = [0, 0, 0, 0, 0];
            const nRisposta: number = parseFloat(d.risposta.substring(1)) - 1;
            tempR[rIndex][nRisposta] = tempR[rIndex][nRisposta] + 1;
          });
        }
      }
    });

    await Promise.all(compilationPromises);

    let outCsv = "";
    let fileName = '';
    if (fromExcelButton.comment) {
      outCsv = await tempR.join('; \n');
      fileName = 'comm-';
    } else {
      // console.log("Numero compilazioni: " + nCompilazioni);
      // console.log(tempR);
      tempR.forEach((t) => {
        outCsv += t.join(";") + "\n";
      });
      fileName = 'risp-';
    }

    // create filename
    let idrReparti = '';
    let nameReparti = '';
    reparti.forEach((reparto, index) => {
      if (index > 0) {
        idrReparti += '-' + reparto.id;
        nameReparti += '-' + reparto.nome;
        return;
      }
      idrReparti += reparto.id;
      nameReparti += reparto.nome;
    });
    fileName += nameReparti + '-' + licenza.id + idrReparti + '.csv';


    this.fileExcelExport(outCsv, fileName);
    // const selBox = document.createElement("textarea");
    // selBox.style.position = "fixed";
    // selBox.style.left = "0";
    // selBox.style.top = "0";
    // selBox.style.opacity = "0";
    // selBox.value = outCsv;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand("copy");
    // document.body.removeChild(selBox);
    // nCompilazioni = 0;

    this.chartProgress = false;
    // this.message.success('Dati copiati con successo');
    // ######################################### End EXPORT PER EXCEL
  }


  async chartExportClima(licenza, reparti, isAll = false) {
    this.chartType = this.chartLineType;

    const repartiId = reparti.map((r) => r.id);
    const repartiNome = reparti.map((r) => r.nome);;

    let nCompilazioni: number = 0;

    this.fire.get("questionari", licenza.questionario).subscribe(async (quest) => {
      this.fire.getDocument("questionari", licenza.questionario, "versioni", licenza.versione).subscribe((q) => {
        q.tratti.forEach(async (t, tIndex: number) => {
          let pCount = 0;
          licenza.compilazioni.forEach((compilazione) => {
            if (repartiId.includes(compilazione.id.substr(0, compilazione.id.indexOf("U")))) {
              if (tIndex == 0) nCompilazioni++;

              compilazione.domande.forEach((d) => {
                let p = t.punteggi[d.domanda];
                if (p) {
                  let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                  if (dIndex >= 0) {
                    let r = p.domanda.risposte[dIndex];
                    pCount += parseFloat(r.valore) || 0;
                  }
                }
              });
            }
          });

          let tCount = pCount;
          this.chartType = this.chartBarType;
          this.chartDisplayOptions = this.chartBarPrintOptions;
          this.chartColumnNames = this.chartBarColumnNames;

          if (q["report"] == 2) {
            this.chartType = this.chartLineType;
            this.chartDisplayOptions = this.chartLinePrintOptions;
            this.chartColumnNames = this.chartLineColumnNames;

            const scalato =
              ((tCount - t.min * nCompilazioni) / (t.max * nCompilazioni - t.min * nCompilazioni)) * 200 - 100;
            tCount = Math.round(scalato);

          } else if (t.transform) {
            let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
            if (pIndex > -1) {
              tCount = t.transform[pIndex].trasformato;
            }
          }

          this.chartData[tIndex] = [];
          this.chartData[tIndex].push(t.titolo["ITA"]);
          this.chartData[tIndex].push(tCount);
          if (q["report"] == 2) {
            if (t.gruppo === 1) {
              this.chartData[tIndex].push("color: #FD0000;");
            } else if (t.gruppo === 2) {
              this.chartData[tIndex].push("color: #FFA500;");
            } else if (t.gruppo === 3) {
              this.chartData[tIndex].push("color: #00B0F0;");
            } else if (t.gruppo === 4) {
              this.chartData[tIndex].push("color: #92D050;");
            } else if (t.gruppo === 5) {
              this.chartData[tIndex].push("color: #FFFF00;");
            } else {
              this.chartData[tIndex].push("color: #661615;");
            }
          }
          this.chartData[tIndex].push(tCount);
          this.chartData[tIndex].push("");
          if (tIndex === q.tratti.length - 1) {
            this.chartVisible = true;

            const lastElement = this.chartData[tIndex];
            if (lastElement[0].toString().toLowerCase() !== 'clima') {
              this.chartData[tIndex] = this.chartData[tIndex - 1];
              this.chartData[tIndex - 1] = lastElement;
            }

            let auxQuest: QuestAUX = {
              titolo: {
                ITA: quest.titolo
              },
              copertina_report: {
                ITA: ''
              },
            };
            let nameChart = (repartiNome.length > 1 ? "Reparti: " : "Reparto: ") + (isAll ? 'Tutti' : repartiNome.join(" - "));
            if (nCompilazioni > 2) {
              this.chartVisible = false;
              await this.exportChartAmajor(this.chartData, auxQuest, nameChart + ' - Compilazioni: ' + nCompilazioni, true, true);
            }
            else {
              this.notification.create("error", 'Elaborazione Clima', 'Ci sono solo ' + nCompilazioni + ' compilazioni per ' + repartiNome.join(" - "), { nzDuration: 10000 });
            }
          }
        });
      });
    });
  }

  chartGroupExport(licenza, reparti) {

    this.chartType = this.chartLineType;

    const repartiId = reparti.map((r) => r.id);
    const repartiNome = reparti.map((r) => r.nome);;

    let nCompilazioni: number = 0;
    this.chartProgress = true;

    // ######################################### EXPORT PER EXCEL
    // //// console.log(reparti);
    // //// console.log(licenza);
    // let tempR: any[] = [];

    // licenza.compilazioni.forEach((compilazione) => {
    //   const rep = compilazione.id.split("U")[0];
    //   // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SELEZIONATI
    //   if (repartiId.includes(rep)) {
    //     nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE

    //     compilazione.domande.forEach((d, index) => {
    //       const rIndex = parseInt(d.domanda.substring(1));

    //       if (!tempR[rIndex]) tempR[rIndex] = [0, 0, 0, 0, 0];
    //       const nRisposta: number = parseInt(d.risposta.substring(1)) - 1;
    //       tempR[rIndex][nRisposta] = tempR[rIndex][nRisposta] + 1;
    //     });
    //   }
    // });

    // console.log("Numero compilazioni: " + nCompilazioni);

    // let outCsv = "";
    // tempR.forEach((t) => {
    //   outCsv += t.join(";") + "\n";
    // });

    // const selBox = document.createElement("textarea");
    // selBox.style.position = "fixed";
    // selBox.style.left = "0";
    // selBox.style.top = "0";
    // selBox.style.opacity = "0";
    // selBox.value = outCsv;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand("copy");
    // document.body.removeChild(selBox);
    // nCompilazioni = 0;
    // ######################################### EXPORT PER EXCEL

    this.fire.get("questionari", licenza.questionario).subscribe((quest) => {
      this.fire.getDocument("questionari", licenza.questionario, "versioni", licenza.versione).subscribe((q) => {
        q.tratti.forEach((t, tIndex: number) => {
          // PER OGNI TRATTO DEL QUESTIONARIO
          let pCount = 0;
          licenza.compilazioni.forEach((compilazione) => {
            // VERIFICO SE LA COMPILAZIONE È NEI REPARTI SLEEZIONATI
            if (repartiId.includes(compilazione.id.substr(0, compilazione.id.indexOf("U")))) {
              if (tIndex == 0) nCompilazioni++; // AUMENTO IL NUMERO DI COMPILAZIONI VALIDE

              compilazione.domande.forEach((d) => {
                let p = t.punteggi[d.domanda];
                if (p) {
                  let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                  if (dIndex >= 0) {
                    let r = p.domanda.risposte[dIndex];
                    pCount += parseFloat(r.valore) || 0;
                  }
                }
              });
            }
          });

          let tCount = pCount;
          this.chartType = this.chartBarType;
          this.chartDisplayOptions = this.chartBarPrintOptions;
          this.chartColumnNames = this.chartBarColumnNames;

          if (q["report"] == 2) {
            this.chartType = this.chartLineType;
            this.chartDisplayOptions = this.chartLinePrintOptions;
            this.chartColumnNames = this.chartLineColumnNames;

            // ######################## SCALATURA NUOVO ALGORITMO
            const scalato =
              ((tCount - t.min * nCompilazioni) / (t.max * nCompilazioni - t.min * nCompilazioni)) * 200 - 100;
            tCount = Math.round(scalato);


            // ####################### DEBUG
            // console.log(tIndex, "COUNT", pCount, "SCALATO", tCount, "MIN", t.min, "MAX", t.max, "COMP", nCompilazioni);
          } else if (t.transform) {
            let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);
            if (pIndex > -1) {
              tCount = t.transform[pIndex].trasformato;
            }
          }

          this.chartData[tIndex] = [];
          this.chartData[tIndex].push(t.titolo["ITA"]);
          this.chartData[tIndex].push(tCount);
          if (q["report"] == 2) {
            if (t.gruppo === 1) {
              this.chartData[tIndex].push("color: #FD0000;");
            } else if (t.gruppo === 2) {
              this.chartData[tIndex].push("color: #FFA500;");
            } else if (t.gruppo === 3) {
              this.chartData[tIndex].push("color: #00B0F0;");
            } else if (t.gruppo === 4) {
              this.chartData[tIndex].push("color: #92D050;");
            } else if (t.gruppo === 5) {
              this.chartData[tIndex].push("color: #FFFF00;");
            } else {
              this.chartData[tIndex].push("color: #661615;");
            }
          }
          this.chartData[tIndex].push(tCount);
          this.chartData[tIndex].push("");
          if (tIndex === q.tratti.length - 1) {
            this.chartVisible = true;

            const lastElement = this.chartData[tIndex];
            if (lastElement[0].toString().toLowerCase() !== 'clima') {
              this.chartData[tIndex] = this.chartData[tIndex - 1];
              this.chartData[tIndex - 1] = lastElement;
            }



            setTimeout(() => {
              let filename = licenza.id + repartiId.join("-") + "-chart.svg";

              let docEl: any = document.getElementById("chart");
              docEl = document.querySelector("svg");
              docEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
              docEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
              const formData = {
                "file": docEl.outerHTML,
                "filename": filename
              };


              formData.file = formData.file.replace(/<path [^>]*?stroke="[^"]*"/g, (match) => {
                return match.replace(/stroke="[^"]*"/, 'stroke="#000000"');
              });

              const data = this.node.crypto(formData);
              this.exportChartAmajor(this.chartData, quest.titolo, "REPARTI: " + repartiNome.join(" - ") + ' Compilazioni: ' + nCompilazioni, true, true)
              let baseUrl: string;
              if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "collapp.amajorsb.com") {
                baseUrl = "https://collnode.amajorsb.com";
              } else {
                baseUrl = "https://node.amajorsb.com";
              }
              const req = new HttpRequest("POST", baseUrl + "/quest/upload/charts", { data }, {
                reportProgress: true,
              });
              return this.http.request(req).subscribe(
                (event: HttpEvent<any>) => {
                  this.chartVisible = false;
                  if (event instanceof HttpResponse) {
                    var copertina = q.copertina_report["ITA"];
                    let param = {
                      "graph": filename,
                      "data": new Date().toJSON().slice(0, 10).split("-").reverse().join("/"),
                      "utente": "REPARTI: " + repartiNome.join(" - "),
                      "commento": "",
                      "attendibilita": "",
                      "forzatura": "",
                      "versione": q.id,
                      "questionario": quest.titolo,
                      "filename": licenza.id + repartiId.join("-"),
                      "copertina": copertina == null ? "" : q.copertina_report["ITA"],
                      "url": baseUrl,
                      "totCompilations": nCompilazioni
                    };

                    this.node.getData(param, "/quest/export").subscribe(
                      (data) => {
                        if (data.status !== 200) {
                          var file = new Blob([data], { type: "application/pdf" });
                          // var file = new Blob([data], { type: "text/html" });

                          var fileURL = URL.createObjectURL(file);
                          const a = document.createElement("a");
                          document.body.appendChild(a);
                          a.href = fileURL;
                          a.download = "valutazione" + licenza.id + repartiId.join("-") + ".pdf";
                          a.target = "_blank";
                          a.click();
                          this.chartProgress = false;
                        }
                      },
                      (err) => {
                        this.chartVisible = false;
                        this.chartProgress = false;
                        // console.log("err", err);
                      }
                    );
                  }
                },
                (err) => {
                  this.chartVisible = false;
                  this.chartProgress = false;
                  // console.log("err", err);
                }
              );
            }, 500);
          }
        });
      });
    });
  }

  excelExport(dataV, aId, lId, comment = false) {
    // this.chartProgress = true;
    let params = {
      aId: aId,
      lId: lId,
      cId: dataV.id,
    };
    let data: any[] = [];
    this.fire
      .getSubDoc("clienti", params.aId, "licenze", params.lId, "compilazioni", dataV.compilazione.id)
      .subscribe((res) => {
        data[0] = res;
        data[0].intestazione = Object.keys(data[0].intestazione)
          .sort()
          .reduce((obj, key) => {
            obj[key] = data[0].intestazione[key];
            return obj;
          }, {});

        let fileName = '';

        this.fire
          .getDocument("clienti", data[0].licenza.azienda, "licenze", data[0].licenza.licenza)
          .subscribe((lic) => {
            this.fire.get("questionari", lic.questionario).subscribe((quest) => {
              this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {
                // ######################################### EXPORT PER EXCEL
                //// console.log("DOMANDE", q.domande);
                //// console.log("RISPOSTE", data[0].domande);
                let outCsv = "";
                data[0].domande.forEach((r) => {
                  // console.log(r);
                  let qIndex = q.domande.findIndex((d) => d.codice == r.domanda);
                  if (qIndex > -1) {
                    let rIndex = q.domande[qIndex].risposte.findIndex((risp) => risp.codice === r.risposta);
                    if (rIndex >= 0) {
                      // CONSOLE DI CONTROLLO RISULTATI
                      // console.log(r, q.domande[qIndex].risposte[rIndex], q.domande[qIndex].risposte[rIndex].titolo.ITA);
                      if (comment) {
                        if (r.commento === '') r.commento = '""';
                        outCsv += r.domanda.substr(1) + "\t" + r.commento + "\n";
                        fileName = 'commenti';
                      } else {
                        fileName = 'risposte';
                        outCsv += r.domanda.substr(1) + "\t" + q.domande[qIndex].risposte[rIndex].titolo.ITA + "\n";
                      }
                    }
                  }
                });

                fileName += aId + lId + dataV.id + '.csv';

                this.fileExcelExport(outCsv, fileName);

                // const selBox = document.createElement("textarea");
                // selBox.style.position = "fixed";
                // selBox.style.left = "0";
                // selBox.style.top = "0";
                // selBox.style.opacity = "0";
                // selBox.value = outCsv;
                // document.body.appendChild(selBox);
                // selBox.focus();
                // selBox.select();
                // document.execCommand("copy");
                // document.body.removeChild(selBox);

                // this.message.success('');
                this.chartProgress = false;
              });
            });
          });
      });
  }


  splitTextByLength(text: string, maxLength: number = 21): string {
    const words = text.split(" ");
    let line = "";
    let result: string[] = [];

    words.forEach((word) => {
      if ((line + word).length > maxLength) {
        result.push(line.trim());
        line = word + " ";
      } else {
        line += word + " ";
      }
    });

    result.push(line.trim()); // Add the last line

    return result.join('\n'); // Join the lines with a newline character
  }


  // ######################################
  // FUNCTION: chartExport
  chartExport(dataV, aId, lId) {
    this.chartProgress = true;
    let params = {
      aId: aId,
      lId: lId,
      cId: dataV.id,
    };
    let data: any[] = [];
    this.fire
      .getSubDoc("clienti", params.aId, "licenze", params.lId, "compilazioni", dataV.compilazione.id)
      .subscribe((res) => {
        data[0] = res;

        this.fire
          .getDocument("clienti", data[0].licenza.azienda, "licenze", data[0].licenza.licenza)
          .subscribe((lic) => {
            this.fire.get("questionari", lic.questionario).subscribe((quest) => {
              this.fire.getDocument("questionari", lic.questionario, "versioni", lic.versione).subscribe((q) => {

                q.tratti.forEach((t, tIndex: number) => {
                  let pCount = 0;

                  data[0].domande.forEach((d) => {
                    let p = t.punteggi[d.domanda];
                    if (p) {
                      let dIndex = p.domanda.risposte.findIndex((di) => di.codice === d.risposta);
                      if (dIndex >= 0) {
                        let r = p.domanda.risposte[dIndex];
                        pCount += parseFloat(r.valore) || 0;
                      }
                    }
                  });

                  let tCount = pCount;
                  this.chartType = this.chartBarType;
                  this.chartDisplayOptions = this.chartBarPrintOptions;
                  this.chartColumnNames = this.chartBarColumnNames;

                  if (q["report"] == 2) {

                    this.chartType = this.chartLineType;
                    this.chartDisplayOptions = this.chartLinePrintOptions;
                    this.chartColumnNames = this.chartLineColumnNames;

                    // ######################## SCALATURA NUOVO ALGORITMO
                    const scalato = ((tCount - t.min) / (t.max - t.min)) * 200 - 100;
                    tCount = Math.round(scalato);

                  } else if (t.transform) {
                    let pIndex = t.transform.findIndex((tr) => tr.punti === pCount);

                    if (pIndex > -1) {
                      tCount = t.transform[pIndex].trasformato;
                    }
                  }
                  this.chartData[tIndex] = [];
                  this.chartData[tIndex].push(t.titolo["ITA"]);
                  this.chartData[tIndex].push(tCount);
                  if (q["report"] != 2) {
                    if (t.gruppo === 1) {
                      this.chartData[tIndex].push("color: #FD0000;");
                    } else if (t.gruppo === 2) {
                      this.chartData[tIndex].push("color: #FFA500;");
                    } else if (t.gruppo === 3) {
                      this.chartData[tIndex].push("color: #00B0F0;");
                    } else if (t.gruppo === 4) {
                      this.chartData[tIndex].push("color: #92D050;");
                    } else if (t.gruppo === 5) {
                      this.chartData[tIndex].push("color: #FFFF00;");
                    } else {
                      this.chartData[tIndex].push("color: #661615;");
                    }
                  }
                  this.chartData[tIndex].push(tCount.toString());
                  this.chartData[tIndex].push("");

                  if (tIndex === q.tratti.length - 1) {
                    this.chartVisible = true;
                    setTimeout(() => {
                      let filename = aId + lId + dataV.id + "-chart.svg";

                      let docEl: any = document.getElementById("chart");
                      docEl = document.querySelector("svg");
                      docEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                      docEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
                      const formData = {
                        "file": docEl.outerHTML,
                        "filename": filename
                      };

                      formData.file = formData.file.replace(/<path [^>]*?stroke="[^"]*"/g, (match) => {
                        return match.replace(/stroke="[^"]*"/, 'stroke="#000000"');
                      });

                      const data = this.node.crypto(formData);

                      let baseUrl: string;
                      if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "collapp.amajorsb.com") {
                        baseUrl = "https://collnode.amajorsb.com";
                      } else {
                        baseUrl = "https://node.amajorsb.com";
                      }
                      const req = new HttpRequest("POST", baseUrl + "/quest/upload/charts", { data }, {
                        reportProgress: true,
                      });
                      return this.http.request(req).subscribe(
                        (event: HttpEvent<any>) => {
                          this.chartVisible = false;

                          if (event instanceof HttpResponse) {
                            var copertina = q.copertina_report["ITA"];
                            let param = {
                              "graph": filename,
                              "data": new Date().toJSON().slice(0, 10).split("-").reverse().join("/"),
                              "utente": dataV.descrizione,
                              "commento": dataV.compilazione.commento ?? '',
                              "attendibilita": dataV.compilazione.attendibilita,
                              "forzatura": dataV.compilazione.forzatura,
                              "versione": q.id,
                              "questionario": q.titolo.ITA,
                              "filename": dataV.descrizione,
                              "copertina": copertina == null ? "" : q.copertina_report["ITA"],
                              "url": baseUrl,
                              "totCompilations": -1
                            };

                            this.node.getData(param, "/quest/export").subscribe(
                              (data) => {
                                if (data.status !== 200) {
                                  var file = new Blob([data], { type: "application/pdf" });
                                  // var file = new Blob([data], { type: "text/html" });

                                  var fileURL = URL.createObjectURL(file);
                                  const a = document.createElement("a");
                                  document.body.appendChild(a);
                                  a.href = fileURL;
                                  a.download = dataV.descrizione + ".pdf";
                                  a.target = "_blank";
                                  a.click();
                                  this.chartProgress = false;
                                }
                              },
                              (err) => {
                                this.chartVisible = false;
                                this.chartProgress = false;
                                // console.log("err", err);
                              }
                            );
                          }
                        },
                        (err) => {
                          this.chartVisible = false;
                          this.chartProgress = false;
                          // console.log("err", err);
                        }
                      );
                    }, 500);
                  }
                });
              });
            });
          });
      });
  }
  // ######################################
  // FUNCTION: checkEmail
  checkEmail(email: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  // ######################################
  // FUNCTION: checkEmail
  checkMobile(mobile: string): boolean {
    return /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/.test(mobile);
  }

  convertHtmlToTextWithNewLines(htmlContent: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    let plainText = tempElement.textContent || tempElement.innerText || '';

    // Insert a new line at the end of every HTML block tag
    plainText = htmlContent
      .replace(/<\/p>/gi, '\n') // Replace closing paragraph tags with a newline
      .replace(/<br\s*\/?>/gi, '\n') // Replace <br> tags with a newline
      .replace(/<\/?[^>]+(>|$)/g, '') // Remove all remaining HTML tags
      .replace(/\n\s*\n/g, '\n') // Collapse multiple newlines into one
      .trim(); // Remove trailing spaces or newlines

    return plainText;
  }

  async userSendLinkAmajor(azienda, licenza, user, license, emailMessaggio, anonimo = false): Promise<number> {
    const that = this;

    // Validate inputs
    if (!azienda || !licenza || !user || !user.address) {
      throw new Error("Invalid inputs");
    }

    let customLink = {
      azienda: azienda,
      licenza: licenza.id,
      reparto: user.roleCode,
      utente: user.nameId,
      tecnico: licenza.tecnico
    };

    let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

    let licenzeUtilizzate = Number(license);
    let isEmail = user.address.includes('@');
    let formData = {};

    if (isEmail) {
      formData = {
        user: user.address,
        link: encodedUrl,
        messaggio: emailMessaggio
      };
    } else {
      formData = {
        user: user.name,
        messaggio: this.convertHtmlToTextWithNewLines(emailMessaggio),
        link: encodedUrl,
        mobile: user.address.substring(3),
        pin: that.uniqueNumber(4, 4),
        timestamp: Date.now(),
      };
    }

    const sendNotification = async (formData, customLink) => {
      if (isEmail) {
        return await that.sendMail(formData, false);
      } else {
        return await that.sendSms(customLink, formData, false);
      }
    };

    const updateDatabase = async (customLink, licenzeUtilizzate) => {
      await that.fire.addSubCollection(
        "clienti",
        "licenze",
        "compilazioni",
        { sent: true, utente: customLink.utente, reparto: customLink.reparto },
        customLink.azienda,
        customLink.licenza,
        customLink.reparto + customLink.utente
      );

      await that.fire.update(
        "clienti",
        "licenze",
        { 'utilizzate': licenzeUtilizzate },
        customLink.azienda,
        customLink.licenza
      );
    };

    try {
      if (!user.isSended) {
        licenzeUtilizzate += 1;
        await updateDatabase(customLink, licenzeUtilizzate);
        await sendNotification(formData, customLink);
        return licenzeUtilizzate;
      } else {
        await sendNotification(formData, customLink);
        return licenzeUtilizzate;
      }

      return licenzeUtilizzate;

    } catch (error) {
      console.error("Error in userSendLinkAmajor:", error);
      throw error;
    }
  }


  modalResetLink() {

  }

  // ######################################
  // FUNCTION: userSendMail
  userSendMail(azienda, licenza, user, anonimo: boolean = false) {
    const that = this;
    return new Promise(function (resolve) {
      let customLink = {
        azienda: azienda.id,
        licenza: licenza.id,
        reparto: user.reparto,
        utente: user.id,
        tecnico: licenza.tecnico
      };
      let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

      const formData = {
        user: user.email,
        link: encodedUrl,
      };

      that.fire
        .getSubDocOnce(
          "clienti",
          customLink.azienda,
          "licenze",
          customLink.licenza,
          "compilazioni",
          customLink.reparto + customLink.utente
        )
        .subscribe(async (comp) => {
          let compData = comp.data();
          if (compData && compData.sent === true) {
            // QUESTIONARIO INVIATO MA NON INIZIATO
            // console.log("CHECK: questionario inviato ma non utilizzato");
            var res = await that.sendMail(formData);
            resolve(res);
          } else if (compData && !compData.concluso) {
            // QUESTIONARIO INIZIATO MA NON CONCLUSO
            // console.log("CHECK: questionario iniziato ma non concluso");

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.email,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Il destinatario ha già iniziato il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendMailUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (compData && compData.concluso) {
            // QUESTIONARIO INIZIATO E CONCLUSO
            // console.log("CHECK: questionario iniziato e concluso!");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            // console.log("USATE", licenza.utilizzate);

            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.email,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Questo utente ha già concluso il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendMailUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (licenza.utilizzate + 1 > licenza.totali) {
            // LIMITE LICENZE RAGGIUNTO
            // console.log("CHECK: limite licenze raggiunto!");
            that.createErrorNotification("Email non inviata", "Limite licenze raggiunte");
            resolve("STOP");
          } else {
            // NESSUNA RESTRIZIONE
            // console.log("CHECK: nessuna restrizione, procedo");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );
            that.fire
              .addSubCollection(
                "clienti",
                "licenze",
                "compilazioni",
                { sent: true, utente: customLink.utente, reparto: customLink.reparto },
                customLink.azienda,
                customLink.licenza,
                customLink.reparto + customLink.utente
              )
              .then((added) => { })
              .catch((err) => {
                // console.log(err);
                resolve(false);
              });

            that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
              (res) => {
                that.createSuccessNotification("Email inviata con successo", user.email);
                resolve(true);
              },
              (err) => {
                that.createErrorNotification("Problema di invio email!", user.email);
                // console.log("ERR:", err);
                resolve(false);
              }
            );
          }
        });
    });
  }

  sendMailNotification(formData: any, path: string) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, path).subscribe(
        (res) => {
          resolve(true);
        },
        (err) => {
          console.error("SEND MAIL:", err);
          resolve(false);
        }
      );
    });
  }

  //#######################################
  sendMail(formData: any, showModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
        (res) => {
          if (showModal)
            that.createSuccessNotification("Email inviata con successo", formData.email);
          resolve(true);
        },
        (err) => {
          if (showModal)
            that.createErrorNotification("Problema di invio email!", formData.email);
          console.error("SEND MAIL:", err);
          resolve(false);
        }
      );
    });
  }
  //#######################################
  async sendMailUpdateDb(u, customLink: any, formData: any, isModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      that.node.callNodeUrl(formData, "/users/sendLink").subscribe(
        async (res) => {
          if (isModal)
            that.createSuccessNotification("Email inviata con successo", u.email);
          let data = { sent: true, utente: customLink.utente, reparto: customLink.reparto };
          await that.fire
            .addSubCollection(
              "clienti",
              "licenze",
              "compilazioni",
              data,
              customLink.azienda,
              customLink.licenza,
              customLink.reparto + customLink.utente
            )
            .then((added) => {
              resolve(true);
            })
            .catch((err) => {
              console.error("ERR:", err);
              resolve(false);
            });
        },
        (err) => {
          that.createErrorNotification("Problema di invio email!", u.email);
          console.error("ERR:", err);
          resolve(false)
        }
      );
    });
  }

  // ############################################################################
  // FUNCTION: userSendSms
  uniqueNumber(time, count) {
    var date = Date.now();
    var chars = "0123456789".split("");
    var result = "";
    for (var i = 0; i < count; i++) {
      var x = Math.floor(Math.random() * chars.length);
      result += chars[x];
    }
    return date.toString().substr(13 - time) + result;
  }

  userSendSms(azienda, licenza, user, anonimo: boolean = false) {
    const that = this;
    return new Promise(function (resolve) {
      let customLink = {
        azienda: azienda.id,
        licenza: licenza.id,
        reparto: user.reparto,
        utente: user.id,
        tecnico: licenza.tecnico
      };

      let encodedUrl = that.CryptoJS.enc.Base64.stringify(that.CryptoJS.enc.Utf8.parse(JSON.stringify(customLink)));

      const formData = {
        user: user.email,
        link: encodedUrl,
        mobile: user.mobile,
        pin: that.uniqueNumber(4, 4),
        timestamp: Date.now(),
      };

      that.fire
        .getSubDocOnce(
          "clienti",
          customLink.azienda,
          "licenze",
          customLink.licenza,
          "compilazioni",
          customLink.reparto + customLink.utente
        )
        .subscribe(async (comp) => {
          let compData = comp.data();
          if (compData && compData.sent === true) {
            // QUESTIONARIO INVIATO MA NON INIZIATO
            // console.log("CHECK: questionario inviato ma non utilizzato");
            formData.pin = compData.pin; // REUSE SAME PIN
            var res = await that.sendSms(customLink, formData);
            resolve(res);
          } else if (compData && !compData.concluso) {
            // QUESTIONARIO INIZIATO MA NON CONCLUSO
            // console.log("CHECK: questionario iniziato ma non concluso");

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.mobile,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Il destinatario ha già iniziato il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendSmsUpdateDb(user, customLink, formData);
                // console.log("RESOLVE", res);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (compData && compData.concluso) {
            // QUESTIONARIO INIZIATO E CONCLUSO
            // console.log("CHECK: questionario iniziato e concluso!");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            // console.log("USATE", licenza.utilizzate);

            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );

            that.modal.create({
              nzTitle: anonimo ? "" : user.descrizione + ": " + user.mobile,
              nzContent:
                "<h1>Questionario in corso</h1>" +
                "<span>Questo utente ha già concluso il questionario. Cancellare la compilazione e inviare un nuovo questionario?</span>",
              nzOkText: "Conferma",
              nzOnOk: async () => {
                res = await that.sendSmsUpdateDb(user, customLink, formData);
                resolve(res);
              },
              nzOnCancel: () => {
                resolve(false);
              },
              nzCancelDisabled: true,
              nzCancelText: "",
              nzWrapClassName: "innerModal",
            });
          } else if (licenza.utilizzate + 1 > licenza.totali) {
            // LIMITE LICENZE RAGGIUNTO
            // console.log("CHECK: limite licenze raggiunto!");
            that.createErrorNotification("SMS non inviato", "Limite licenze raggiunte");
            resolve("STOP");
          } else {
            // NESSUNA RESTRIZIONE
            // console.log("CHECK: nessuna restrizione, procedo");

            //INVITO DA MANDARE, ALMENO UNA LICENZA LIBERA
            licenza.utilizzate += 1;
            await that.fire.update(
              "clienti",
              "licenze",
              { utilizzate: licenza.utilizzate },
              customLink.azienda,
              customLink.licenza
            );
            res = await that.sendSmsUpdateDb(user, customLink, formData);
            resolve(res);
          }
        });
    });
  }
  //#######################################
  sendSms(customLink: any, formData: any, showModal: boolean = true) {
    const that = this;
    return new Promise(async function (resolve) {
      const data = {
        azienda: customLink.azienda,
        licenza: customLink.licenza,
        reparto: customLink.reparto,
        utente: customLink.utente,
        compilazione: customLink.reparto + customLink.utente,
        timestamp: formData.timestamp,
      };
      // ASS SMS PIN TO DATABASE
      await that.fire
        .add("sms", data, formData.pin)
        .then(async (added) => {
          // SEND SMS PIN TO USER
          await that.node.callNodeUrl(formData, "/users/sendSms").subscribe(
            (res) => {
              if (showModal)
                that.createSuccessNotification("SMS inviato con successo", formData.mobile);
              resolve(true);
            },
            (err) => {
              if (showModal)
                that.createErrorNotification("Problema di invio SMS!", formData.mobile);
              console.error("SEND MAIL:", err);
              resolve(false);
            }
          );
        })
        .catch((err) => {
          that.createErrorNotification("Problema di comunicazione con il server!", "");
          console.error("ERR:", err);
          resolve(false);
        });
    });
  }
  //#######################################
  sendSmsUpdateDb(u, customLink: any, formData: any, isModal: boolean = true) {
    const that = this;
    return new Promise(function (resolve) {
      const data = {
        azienda: customLink.azienda,
        licenza: customLink.licenza,
        reparto: customLink.reparto,
        utente: customLink.utente,
        compilazione: customLink.reparto + customLink.utente,
        timestamp: formData.timestamp,
      };
      // ASS SMS PIN TO DATABASE
      that.fire
        .add("sms", data, formData.pin)
        .then((added) => {
          // SEND SMS PIN TO USER
          that.node.callNodeUrl(formData, "/users/sendSms").subscribe(
            (res) => {
              if (res) {
                // NOTIFY SUCCESS AND UPDATE DB
                if (isModal)
                  that.createSuccessNotification("SMS inviato con successo", u.mobile);
                const data = { sent: true, utente: customLink.utente, reparto: customLink.reparto, pin: formData.pin };
                that.fire
                  .addSubCollection(
                    "clienti",
                    "licenze",
                    "compilazioni",
                    data,
                    customLink.azienda,
                    customLink.licenza,
                    customLink.reparto + customLink.utente
                  )
                  .then((added) => {
                    resolve(true);
                  })
                  .catch((err) => {
                    console.error("ERR:", err);
                    resolve(false);
                  });

                resolve(true);
              }
              else
                resolve(false);
            },
            (err) => {
              // NOTIFY THE ERROR
              that.createErrorNotification("Problema di invio SMS!", u.mobile);
              console.error("ERR:", err);
              resolve(false);
            }
          );
        })
        .catch((err) => {
          that.createErrorNotification("Problema di comunicazione con il server!", "");
          console.error("ERR:", err);
          resolve(false);
        });
    });
  }

  fileExcelExport(outCsv, fileName) {
    // crate a blob
    let blob = new Blob([outCsv], { type: 'text/csv' });
    let blobUrl = URL.createObjectURL(blob);

    // create element a for download
    let a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);

    // simulate click
    a.click();

    // remove a after dowload
    document.body.removeChild(a);

    // clean url
    URL.revokeObjectURL(blobUrl);
  }

  customSort(a, b) {
    // Extract the numeric part of the ad_id (assuming it always starts with 'R')
    const aNum = parseInt(a.id.substring(1));
    const bNum = parseInt(b.id.substring(1));

    // Compare the numeric values
    return aNum - bNum;
  }
}
