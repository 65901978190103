import { BrowserModule } from "@angular/platform-browser";
import { NgModule} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { NZ_I18N, en_US, it_IT } from "ng-zorro-antd/i18n";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { registerLocaleData } from "@angular/common";
import it from "@angular/common/locales/it";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./guards/auth.guard";
import { NgZorroModule } from "./ng-zorro.module";
import { RecoverComponent } from "./pages/recover/recover.component";
import { AdminModule } from "./pages/admin/admin.module";
registerLocaleData(it);

// FIRESTORE MODULES
import { AngularFireModule } from "@angular/fire/compat";
import { firebaseConfig } from "./firestore.config";
import { QuestionarioModule } from "./pages/admin/designer/questionario/questionario.module";

import { ScrollingModule } from "@angular/cdk/scrolling";
import { CompilazioneModule } from "./pages/compilazione/compilazione.module";
import { QuestionariModule } from "./pages/admin/gestione/questionari/questionari.module";
import { EditQuestionariModule } from "./pages/admin/gestione/questionari/edit-questionari/edit-questionari.module";
import { EditValutazioniModule } from "./pages/admin/gestione/valutazioni/edit-valutazioni/edit-valutazioni.module";
import { EditPrivacyModule } from "./pages/admin/gestione/privacy/edit-privacy/edit-privacy.module";
import { EditAggregatiModule } from "./pages/admin/gestione/aggregati/edit-aggregati/edit-aggregati.module";
import { ClienteModule } from "./pages/cliente/cliente.module";
import { ClienteHomepageModule } from "./pages/cliente/homepage/homepage.module";
import { EditModule } from "./pages/cliente/homepage/licenze/edit/edit.module";
import { ShowValutazioniModule } from "./pages/cliente/homepage/licenze/analysis/show-valutazioni.module";
import { CfValidatorDirective } from "./services/validator.cf";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { IconDefinition } from "@ant-design/icons-angular";
import { NzIconModule } from "ng-zorro-antd/icon";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { HighchartsChartModule } from 'highcharts-angular'
import { EmailModule } from "./pages/admin/designer/questionario/email/email.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverComponent,
    CfValidatorDirective,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    NzIconModule.forRoot(icons),
    AdminModule,
    CompilazioneModule,
    QuestionarioModule,
    EmailModule,
    ClienteModule,
    ClienteHomepageModule,
    BrowserModule,
    AppRoutingModule,
    QuestionariModule,
    EditQuestionariModule,
    EditValutazioniModule,
    EditPrivacyModule,
    EditAggregatiModule,
    EditModule,
    ShowValutazioniModule,
    NgZorroModule,
    ScrollingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    HighchartsChartModule
  ],
  exports: [NgZorroModule, ScrollingModule, TranslateModule],
  providers: [AuthService, AuthGuard, { provide: NZ_I18N, useValue: it_IT }],
  bootstrap: [AppComponent],
})
export class AppModule { }
