import { browser, element } from "protractor";
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { Data, Router } from "@angular/router";
import { FireService } from "src/app/services/fire.service";
import { NodeService } from "src/app/services/node.service";
import { GlobalService } from "src/app/shared/global.service";
import { User } from "src/app/entities/users";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthClienteService } from "src/app/services/authCliente.service";
import { NzButtonSize } from "ng-zorro-antd/button";
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from "ng-zorro-antd/table";
import { ExcelService } from "./excel.service";
import * as Highcharts from 'highcharts';
import HC_dilldown from 'highcharts/modules/drilldown';
import { firstValueFrom } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { SeriesOptionsType } from "highcharts";
import ExportingModule from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import html2canvas from 'html2canvas';

ExportData(Highcharts);
HC_dilldown(Highcharts);
ExportingModule(Highcharts);


/*
TODO: 
npm run build --output-hashing=all



- Implementazione:
	dati tabella: 
		- filtro per nome
		- ordine alfabetico
	inserimento tutorial
	
*/



interface DataItem {
	name: string;
	nameId: string;
	role: string;
	roleCode: string;
	address: string;
	isComplete: boolean,
	tratti: any[],
	isSended: boolean,
	isInProgress: boolean,
	stateCompilation: number,
	comments: string,
	status: string
}

interface ColumnItem {
	name: string;
	sortOrder: NzTableSortOrder | null;
	sortFn: NzTableSortFn<DataItem> | null;
	listOfFilter: NzTableFilterList;
	filterFn: NzTableFilterFn<DataItem> | null;
	filterMultiple: boolean;
	sortDirections: NzTableSortOrder[];
}




@Component({
	selector: "app-show-valutazioni",
	templateUrl: "./show-valutazioni.component.html",
	encapsulation: ViewEncapsulation.None,
	styleUrls: ["./show-valutazioni.component.scss"],
})
export class ShowValutazioniComponent implements OnInit {
	@ViewChild("chart") gChart: any;
	receivedObject: any;
	search: string = '';
	isCollapsed = false;
	aId: string = '';
	lId: string = '';
	cId: string = '';
	size: NzButtonSize = 'large';
	CryptoJS = require("crypto-js");

	loggedIn: User;
	data: any[] = [];
	displayedData: any[] = [];
	intestazione: any[] = [];
	questionario: any[] = [];
	loading: boolean = false;
	logo: SafeResourceUrl;
	loadingChart: boolean = true;
	valText: string = '';
	scroll: object;

	bSave: boolean = false;

	uniqueRoles: string[] = [];
	showedUniqueRoles: string[] = [];

	licenzeDisponibili: number = 0;
	listOfColumns: ColumnItem[];
	listOfColumnName: string[] = ['Nome', 'Email/Cellulare', 'Reparto', 'Stato Questionario', 'Azioni'];
	listStateOfQuestionaire: string[] = ['Link da Inviare', 'Da Iniziare', 'In Compilazione', 'Completato'];

	emailOrPhonePattern = '^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}|\\+\\d{12})$';

	// FILTERS
	listStateOfQuestionaireFilterToSend = this.listStateOfQuestionaire.map(item => (item === this.listStateOfQuestionaire[0] ? { text: item, value: item, byDefault: true } : { text: item, value: item }));
	listStateOfQuestionaireFilter = this.listStateOfQuestionaire.map(item => ({ text: item, value: item }));
	listRepartOfQuestionaireFilter: any[] = [];

	functionListStateOfQuestionaireFilter = (list: string[], item: DataItem) => list.some(name => item.status.indexOf(name) !== -1);
	functionListRepartsOfQuestionaireFilter = (list: string[], item: DataItem) => list.some(name => item.role.indexOf(name) !== -1);

	editingRow: number | null = null;
	isVisibleSendQuestionnaires = false;
	isVisibleElaboratorQuestionnaires = false;
	checked = false;
	indeterminate = false;
	listOfCurrentPageData: readonly Data[] = [];
	setOfCheckedId = new Set<string>();
	userAzienda: string;
	nameAnonimo = 'Anonimo'

	isMouseDown = false;
	startX: number = 0;
	scrollLeft: number = 0;

	percentSend: number = 0;
	pecentSendString: string = '';
	showProgressSend = false;

	allElements: DataItem[];
	listOfData: DataItem[];
	listOfDataToSend: DataItem[];
	completeQuestionnaires: DataItem[];

	navigation;

	// Dashboard statistic
	numberOfComplete: number = 0;
	numberOfSend: number = 0;
	numberOfInProgress: number = 0;
	numberOfLicenceUsed: number = 0;
	totali: number = 0;
	emailMessaggio = ''

	// manage user
	user: any;
	reps: [] = [];


	// 
	isClima: boolean = false;
	trattiResultElaboration: any[] = [];

	//Chart
	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options;
	commentChart: string = '';
	backUpListOfData: DataItem[];

	isClickedNewUser: boolean = false;
	fileterClima: string[];
	listOfSelectedFilterClima: string[] = [];
	countClimaCompilation: any;
	hasListOfDataToSend: boolean;
	isInEditing: boolean = false;


	countLinkToSend: number = 0;
	textSend: string = 'Seleziona i Destinatari';
	actualName: string = '';
	completeUniqueRoles: any[];
	isTitolari: any;
	isActive: boolean = false;
	repartiRes: any[];
	expireDate: string = '';
	lastColorChart: any;
	lastLabelChart: string[];
	lastValueChart: any;
	isEleborating: boolean = false;
	disableExport: boolean = false;
	viewAllQuestionnaires = false;

	// ######################################
	// FUNCTION: constructor
	constructor(
		private sanitizer: DomSanitizer,
		private location: Location,
		public auth: AuthClienteService,
		private fire: FireService,
		private node: NodeService,
		public g: GlobalService,
		private excelService: ExcelService,
		private router: Router,
		private notification: NzNotificationService,
	) {
		this.navigation = this.router.getCurrentNavigation();
		this.updateScrollValue();


	}


	@HostListener("window:scroll", ["$event"])
	onScroll(event: Event): void {
		this.g.destroyChartAmajor(200);
	}

	updateScrollValue() {
		// Apply the custom formula to calculate the scroll value
		this.scroll = { y: `${290 + 290 * (1 - (window.outerWidth / window.innerWidth))}px` };
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.updateScrollValue();
	}

	setDashBoardStatistic() {
		this.isClima = this.receivedObject?.quest?.titolo.toLowerCase().includes('clima');
		this.numberOfLicenceUsed = this.receivedObject?.utilizzate;
		this.totali = this.receivedObject?.totali;
		this.viewAllQuestionnaires = this.receivedObject.viewAllQuestionnaires;
		this.licenzeDisponibili = this.totali - this.numberOfLicenceUsed;
		this.expireDate = this.receivedObject?.scadenza?.seconds
			? new Date(this.receivedObject.scadenza.seconds * 1000).toLocaleDateString('en-GB')
			: '';
	}

	async computeClima() {
		if (this.listOfSelectedFilterClima.length > 0) {
			this.isEleborating = true;
			let trattiClima = this.receivedObject.vers.tratti;;
			let completeQuestionnaires = await firstValueFrom(this.fire.getSubCollection("clienti", this.auth.getUser().azienda, "licenze", this.receivedObject.id, "compilazioni"));
			if (completeQuestionnaires) {
				completeQuestionnaires = completeQuestionnaires.filter(item => item.concluso);
				this.trattiResultElaboration = [];
				if (this.listOfSelectedFilterClima.length > 0) {
					let roleCodeList: string[] = [];
					if (this.listOfSelectedFilterClima.includes('Tutti')) {
						this.listOfSelectedFilterClima = ['Tutti'];
						const element = this.allElements;
						roleCodeList = Array.from(new Set(element.map(item => item.roleCode)));
					}
					else {
						this.listOfSelectedFilterClima.forEach(item => {
							let matchedElement = this.allElements.find(elem => elem.role === item);
							if (matchedElement)
								roleCodeList.push(matchedElement.roleCode);
						});
					}
					completeQuestionnaires = completeQuestionnaires.filter(item => roleCodeList.includes(item.licenza.reparto));
				}
				this.countClimaCompilation = completeQuestionnaires?.length;
				trattiClima.forEach((t, _: number) => {
					let pointsTratto = 0;
					completeQuestionnaires.forEach(complete => {
						if (complete.domande)
							complete.domande.forEach(({ domanda, risposta }) => {
								const match = t.punteggi[domanda].domanda.risposte.find(r => r.codice === risposta);
								if (match) {
									pointsTratto += Number(match.valore);
								}
							});
					});
					const totalQuestionnaires = completeQuestionnaires.length;
					const scaledPoints = ((pointsTratto - t.min * totalQuestionnaires) / (t.max * totalQuestionnaires - t.min * totalQuestionnaires)) * 200 - 100;
					this.trattiResultElaboration.push({ [t.codice]: Math.round(scaledPoints) });

				});
			}
			this.isEleborating = false;

		}
	}

	async rigenerateClima() {
		let isValidCompilation = (this.allElements.filter(element => {
			const selectedRole = this.listOfSelectedFilterClima[0];
			const validStatuses = this.listStateOfQuestionaire.slice(1)
			return (selectedRole === 'Tutti' || element.role === selectedRole) && validStatuses.includes(element.status);
		})).length >= 3;
		if ((this.listOfSelectedFilterClima.length === 1 && !isValidCompilation)) {
			this.notification.create(
				'error',
				'Elaborazione Errata',
				`Non è possibile elaborare il clima del reparto "${this.listOfSelectedFilterClima[0]}" perché è necessario inviare il link ad almeno 3 destinatari.`)

			this.listOfSelectedFilterClima = this.listOfSelectedFilterClima.slice(0, -1);
		}
		else {
			await this.computeClima();
			await this.generateChart();
		}
	}

	generateChart(dataUser?: DataItem) {
		this.commentChart = dataUser?.comments?.replace(/<[^>]*>/g, "") ?? '';
		this.actualName = !this.isClima ? dataUser?.name ?? '' : '';

		let groupToColor = ['red', 'orange', 'blue', 'green', 'yellow'];

		let categoriesList: string[] = this.receivedObject.vers.tratti.map(elem => elem.titolo.ITA.toUpperCase());
		let markerColors = this.receivedObject.vers.tratti.map(elem => groupToColor[elem.gruppo - 1]);
		let titleQuestionnaires: string;
		const logoTitle: string = `<img class="chartLogo" src="../../../assets/logo.svg" alt="Amajor">`;;
		let inputData: Array<SeriesOptionsType>;
		let trattiElaborated: any[] = [];

		if (this.isClima) {
			let repartClimaActive = this.listOfSelectedFilterClima.length > 0 ? this.listOfSelectedFilterClima : this.uniqueRoles.filter(item => item !== 'Tutti');
			titleQuestionnaires = '<div class="chart-header"> <p>Reparti: ' + repartClimaActive.join(' | ') + ' </p>' +
				'<p>' + new Date().toLocaleDateString() + ' - Compilazioni: ' + this.countClimaCompilation + '</p> </div>';


			trattiElaborated = this.trattiResultElaboration.map(item => Object.values(item)[0]);

			try {
				const lastIndex = categoriesList.length - 1;

				// Check if the last element is not "CLIMA" (case-insensitive)
				if (categoriesList[lastIndex]?.toString().toLowerCase() !== 'clima') {
					// Swap elements in `categoriesList`
					[categoriesList[lastIndex], categoriesList[lastIndex - 1]] =
						[categoriesList[lastIndex - 1], categoriesList[lastIndex]];

					// Swap corresponding elements in `trattiElaborated`
					[trattiElaborated[lastIndex], trattiElaborated[lastIndex - 1]] =
						[trattiElaborated[lastIndex - 1], trattiElaborated[lastIndex]];

					[markerColors[lastIndex], markerColors[lastIndex - 1]] =
						[markerColors[lastIndex - 1], markerColors[lastIndex]];
				}
			} catch (error) {
			}



			inputData = [{
				type: 'line',
				name: '',

				marker: {
					symbol: 'triangle',
					radius: 6,
					lineColor: '#666666',
					lineWidth: 1,

				},
				dataLabels: {
					enabled: true
				},
				lineWidth: 1,
				dashStyle: 'Dash',
				color: 'black',
				data: trattiElaborated.map((value, index) => ({
					y: value,
					marker: {
						fillColor: markerColors[index % markerColors.length]
					}
				})),
				showInLegend: false,
				accessibility: {
					enabled: false  // Disable accessibility warnings
				}
			}]
		}

		else {
			titleQuestionnaires = `<p>${dataUser?.name} <br>  ${new Date().toLocaleDateString()}</p>`;
			inputData = [{
				type: 'column',
				name: '',
				borderRadius: 2,
				colorByPoint: true,
				dataLabels: {
					enabled: true
				},
				colors: markerColors,
				data: dataUser?.tratti.map(item => item.punteggio).slice(0, categoriesList.length),
				showInLegend: false
			}];

		}

		this.chartOptions = {
			lang: {
				exitFullscreen: "Esci",
				viewFullscreen: "Tutto schermo",
			},
			title: {
				text: logoTitle,
				style: {
					width: 200,
					height: 100
				},

				useHTML: true,
				align: 'left'
			},
			subtitle: {
				text: titleQuestionnaires,
				useHTML: true,
				align: 'right'
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 600,
						maxHeight: 400,
					}
				}]
			},
			xAxis: {
				categories: categoriesList,
				labels: {
					rotation: -90,
					align: 'right',
					useHTML: true
				}
			},
			yAxis: {
				min: -100,
				max: 100,
				tickInterval: 20,
				plotLines: [{
					value: this.isClima ? 0 : 80,
					color: this.isClima ? 'black' : 'red',
					zIndex: 5,
				}],
				title: {
					text: ''
				}
			},
			series: inputData,
			credits: {
				enabled: false
			},
			exporting: {
				enabled: true,
				allowHTML: true,
				fallbackToExportServer: false,
				filename: `Amajor - ${dataUser?.name}`,
				buttons: {
					contextButton: {
						menuItems: ["viewFullscreen"]
					}
				},
				chartOptions: {
					title: {
						text: 'Amajor SB ',
						align: 'left'
					},
				}
			}


		};

		this.lastColorChart = markerColors ?? [];
		this.lastLabelChart = categoriesList ?? [];
		this.lastValueChart = this.isClima ? trattiElaborated : dataUser?.tratti.map(item => item.punteggio).slice(0, categoriesList.length);
	}


	getStatusMessage(data: any): string {
		if (data.isSended) {
			if (data.isComplete) {
				return this.listStateOfQuestionaire[3];
			} else {
				return data.isInProgress ? this.listStateOfQuestionaire[2] : this.listStateOfQuestionaire[1];
			}
		} else {
			return this.listStateOfQuestionaire[0];
		}
	}


	async fetchData(): Promise<DataItem[]> {
		try {

			this.loading = true;
			this.showProgressSend = true;


			let users: any[] = [];

			this.repartiRes = await firstValueFrom(this.fire.getCollection('clienti', this.userAzienda, 'reparti'));
			const repartiToSearch = this.receivedObject?.reparti;

			this.uniqueRoles = this.repartiRes
				.filter(item => repartiToSearch.includes(item.id))
				.map(elem => elem.nome);


			const userPromises = repartiToSearch.map(async (role) => {
				const subUsers = await firstValueFrom(this.fire.getSubCollection('clienti', this.userAzienda, 'reparti', role, 'utenti'));
				return subUsers.map(user => ({ ...user, idOriginal: user.id }));
			});

			const userArrays = await Promise.all(userPromises);
			userArrays.forEach(subUsers => users.push(...subUsers));


			const licensePromises = users.map(async (user, i) => {

				this.percentSend = Math.round(((i + 1) / users.length) * 100) ?? '';
				this.pecentSendString = '';

				const licenzaResult = await firstValueFrom(this.fire.getSubDoc('clienti', this.user.azienda, 'licenze', this.receivedObject.id, 'compilazioni', user.reparto + user.id)) as any;
				if (licenzaResult) {
					delete licenzaResult.id;
				}
				return { ...user, ...licenzaResult };
			});
			const usersWithLicenses = await Promise.all(licensePromises);

			let dataMerged: DataItem[] = usersWithLicenses.map(user => {

				return (
					{
						name: user.descrizione,
						nameId: user.id,
						role: this.repartiRes.find(item => item.id === user.reparto)?.nome ?? '',
						roleCode: user.reparto,
						address: user.email ? this.manageCompleteEmail(user.email, user.concluso) : this.manageCompleteEmail(user.mobile, user.concluso),
						isComplete: user.concluso ?? false,
						tratti: user.tratti ?? [],
						isSended: user.concluso || (user.sent ?? false) || (user.questionario !== undefined),
						isInProgress: user.questionario !== undefined,
						stateCompilation: user.concluso ? 1.0 :
							(user.compilazione?.last !== undefined && user.compilazione.last > 0) ?
								(user.compilazione.last / user.questionario.domande.length) :
								0.0,
						comments: user.commento ?? '',
						status: ''
					})
			});

			this.completeUniqueRoles = [...new Set(dataMerged.filter(item => item.isComplete).map(elem => elem.role))]

			dataMerged.forEach(user => {
				user.status = this.getStatusMessage(user); // Calculate status
			});


			return dataMerged;

		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
		finally {
			this.loading = false;
			this.showProgressSend = false;
		}
	}

	// Manage complete email function
	private manageCompleteEmail(text: string, isComplete: boolean): string {
		return isComplete ? text + '  ' : text;
	}


	async exportChart(name) {
		if (this.isClima) {
			let reparti = this.listOfSelectedFilterClima.length > 0 ? this.listOfSelectedFilterClima : this.uniqueRoles.filter(item => item !== 'Tutti');
			name = 'Reparti: ' + reparti.join(' | ') + ' - Compilazioni: ' + this.countClimaCompilation;
		}
		this.loading = true;
		this.disableExport = true;
		await this.g.exportChartAmajor([this.lastLabelChart, this.lastValueChart, this.lastColorChart], this.receivedObject.vers, name, false, this.isClima);
		setTimeout(() => {
			this.loading = false; this.disableExport = false;
		}, 2500);
		this.g.destroyChartAmajor();
	}


	// ######################################
	// FUNCTION: ngOnInit
	async ngOnInit() {
		try {
			if (this.navigation?.extras?.state?.['object']) {
				this.receivedObject = this.navigation.extras.state['object'];

				this.emailMessaggio = this.receivedObject.vers.emailMessaggio ?? '';
				this.user = this.auth.getUser();
				this.userAzienda = this.user?.azienda;


				if (this.userAzienda) {
					this.allElements = await this.fetchData();
					this.listOfCurrentPageData = this.allElements;
					this.uniqueRoles = ['Tutti', ...this.uniqueRoles];
					this.completeUniqueRoles = ['Tutti', ...this.completeUniqueRoles];
					this.completeQuestionnaires
					this.filterByRepart('Tutti');
					this.setDashBoardStatistic();
					if (this.isClima) {
						this.fileterClima = this.uniqueRoles;
					}
				} else {
					this.back();
				}

			} else {
				this.back();
			}

		} catch (error) {

			console.error('Error during ngOnInit:', error);
		} finally {

			this.loading = false;
		}
	}


	// ######################################
	// FUNCTION: back
	back() {
		this.location.back();
	}

	isNotSelected(value: string): boolean {
		return this.listOfSelectedFilterClima.indexOf(value) === -1;
	}

	filterByRepart(reparto) {
		if (reparto === 'Tutti') {
			this.listOfData = this.allElements;
			this.numberOfComplete = this.allElements.filter(item => item.isComplete).length;
			this.numberOfInProgress = this.allElements.filter(item => item.isInProgress).length;
			this.numberOfLicenceUsed = this.receivedObject.utilizzate;
			this.numberOfSend = this.allElements.filter(item => item.isSended).length - this.numberOfInProgress - this.numberOfComplete;
		}
		else {
			this.listOfData = this.allElements.filter(d => d.role === reparto);
			this.numberOfComplete = this.listOfData.filter(item => item.isComplete).length;
			this.numberOfInProgress = this.listOfData.filter(item => item.isInProgress).length;
			this.numberOfSend = this.listOfData.filter(item => item.isSended).length - this.numberOfInProgress - this.numberOfComplete;
			this.numberOfLicenceUsed = this.numberOfSend + this.numberOfInProgress + this.numberOfComplete;
		}

	}

	async deleteRow(rowIndex: number): Promise<void> {
		try {
			this.loading = true;
			const userToDelete = this.listOfData[rowIndex];
			if (userToDelete.roleCode && userToDelete.nameId)
				await this.fire.deleteSubDoc('clienti', 'reparti', 'utenti', this.userAzienda, userToDelete.roleCode, userToDelete.nameId);

			// Update the arrays
			this.allElements = this.allElements.filter(d => !(d.address === userToDelete.address && d.name === userToDelete.name));
			this.listOfData = this.listOfData.filter(d => !(d.address === userToDelete.address && d.name === userToDelete.name));
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
			this.g.createErrorNotification("Errore durante la eliminazione dell'utente", errorMessage);
			console.error("Error deleting user:", error);
		}
		finally {
			this.loading = false;
		}
	}


	async confirmRow(rowIndex) {
		let isCorrectPatternEmailOrPhone = new RegExp(this.emailOrPhonePattern).test(this.listOfData[rowIndex].address);

		/* Implementation 24.6.20
			Consente di creare un Destinatario con lo stesso indirizzo di invio già esistente solo se il destinatario ha completato il test precedente.
		*/
		if (this.listOfData[rowIndex].name && isCorrectPatternEmailOrPhone && this.listOfData[rowIndex].role) {
			this.loading = true;
			try {

				if (this.isClickedNewUser) {
					await this.createUser(rowIndex);
					this.isClickedNewUser = false;
				}
				else {
					await this.fire.getSubDocOnce('clienti', this.user.azienda, 'reparti', this.listOfData[rowIndex].roleCode, 'utenti', this.listOfData[rowIndex].nameId).subscribe(
						async response => {
							const dataUpdata = response.data();
							if (dataUpdata) {
								dataUpdata['descrizione'] = this.listOfData[rowIndex].name;
								if (this.listOfData[rowIndex].address.includes('@')) {
									dataUpdata['email'] = this.listOfData[rowIndex].address;
									dataUpdata['mobile'] = '';
								}
								else {
									dataUpdata['email'] = '';
									dataUpdata['mobile'] = this.listOfData[rowIndex].address;
								}

								dataUpdata['sendtype'] = !dataUpdata['email'];
								await this.fire.updateSubDoc('clienti', 'reparti', 'utenti', dataUpdata, this.user.azienda, this.listOfData[rowIndex].roleCode, this.listOfData[rowIndex].nameId)
							}
						}
					);
					this.editingRow = this.editingRow === rowIndex ? null : rowIndex;

				}
			} catch (error) {
				console.error('Error in confirmRow:', error);
			} finally {

				this.loading = false;
				this.isInEditing = true;
			}
		}
		else {
			this.notification.create(
				'error',
				'Inserimento dati Destinatario',
				'I dati inseriti sono mancanti o non corretti.'
			);
		}
		this.isInEditing = false;
	}

	async createUser(rowIndex: number | object) {
		try {
			// Validate and retrieve row data
			const rowData = typeof rowIndex === 'number'
				? this.validateRowIndex(rowIndex)
				: rowIndex;

			if (!rowData || !rowData.address || !rowData.name) {
				throw new Error('Invalid row data.');
			}

			const roleCode = this.repartiRes.find(elem => elem.nome === rowData.role)?.id ?? '';
			const allElmentsAux = this.allElements;
			const nextUserId = this.allElements
				.map(item => {
					if (item?.nameId && typeof item.nameId === 'string') {
						// Extract the numeric part if nameId is valid
						return parseInt(item.nameId.replace(/[^\d]/g, ''), 10);
					}
					return NaN; // Return NaN for invalid or missing nameId
				})
				.filter(num => !isNaN(num)) // Remove NaN values
				.reduce((max, current) => Math.max(max, current), 0) + 1; // Get the max value and increment by 1


			const isEmail = rowData.address.toString().includes('@');
			const emailUser = isEmail ? rowData.address : '';
			const phoneUser = isEmail ? '' : rowData.address;

			// Construct new user data
			const newUser = {
				admin: false,
				attivo: true,
				codice: nextUserId,
				descrizione: rowData.name,
				email: emailUser,
				mobile: phoneUser,
				sendtype: Boolean(phoneUser),
				id: `U${nextUserId}`,
				pwd: "",
				ultimo_accesso: {},
				reparto: roleCode,
			};

			await this.fire.addSingleSubDoc(
				'clienti', 'reparti', 'utenti', this.user.azienda, roleCode, newUser
			);

			if (typeof rowIndex === 'number') {
				this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
			}

			rowData['nameId'] = `U${nextUserId}`;
			rowData['roleCode'] = roleCode;



		} catch (error) {
			console.error("Error creating user:", error);
		}
	}

	// Helper function to validate row index
	private validateRowIndex(rowIndex: number): any {
		if (rowIndex < 0 || rowIndex >= this.listOfData.length) {
			throw new Error(`Invalid rowIndex: ${rowIndex}`);
		}
		return this.listOfData[rowIndex];
	}



	toggleEditMode(rowIndex: number) {
		this.isInEditing = true;
		this.backUpListOfData = JSON.parse(JSON.stringify(this.listOfData));
		this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
	}

	resetRow(rowIndex: number) {
		if ((!this.listOfData[rowIndex].address && !this.listOfData[rowIndex].name && !this.listOfData[rowIndex].role) || this.isClickedNewUser) {
			this.deleteRow(rowIndex);
		} else {
			this.listOfData = this.backUpListOfData;
		}


		this.editingRow = this.editingRow = this.editingRow === rowIndex ? null : rowIndex;
		this.isClickedNewUser = false;
		this.isInEditing = false;
	}

	downloadExcel() {
		const filteredData = this.allElements.filter(item => item.name && item.address);
		this.excelService.downloadExcel(filteredData, this.receivedObject?.quest?.titolo, this.listOfColumnName.slice(0, 2), this.uniqueRoles.filter(item => item !== 'Tutti'));
	}

	handleImport() {
		const fileInput = document.querySelector('input[type="file"]');

		if (fileInput) {
			(fileInput as HTMLInputElement).click();
		}
	}

	async onFileSelected(event: any, data) {
		const file: File = event.target.files[0];
		if (file) {
			await this.uploadExcel(file, data);
		}
	}


	async uploadExcel(file: File, data: DataItem[]) {
		try {
			let uploadedData = await this.excelService.uploadExcel(file, this.listOfColumnName, this.listStateOfQuestionaire[0]) as DataItem[];
			uploadedData = uploadedData.filter(item => item.address);
			const uniqueAddresses = new Set();
			let allReparti = this.uniqueRoles;
			let allEmails = this.allElements.map(elem => elem.address.trim());
			uploadedData = uploadedData.filter(item => {
				let addressUser = this.g.managePhoneNumber(item.address)
				if (!uniqueAddresses.has(item.address) && !allEmails.includes(addressUser) && allReparti.includes(item.role.trim())) {
					uniqueAddresses.add(addressUser);
					return true;
				}
				return false;
			});

			if (uploadedData.length > 0) {
				let i = 0;
				this.loading = true;
				this.showProgressSend = true;

				for (let user of uploadedData) {
					if (user && user.address) {
						this.percentSend = Math.round(((i + 1) / uploadedData.length) * 100) ?? 0;
						this.pecentSendString = uploadedData.length > 0 ? `Caricati: ${i + 1}/${uploadedData.length}` : '';

						user.address = user.address.toString().trim().replace(" ", "");
						user.address = this.g.managePhoneNumber(user.address);
						this.allElements = [user, ...this.allElements];
						await this.createUser(user);
						i = i + 1;
					}
				}

				this.listOfData = this.allElements;
			}
			else {
				this.notification.create(
					'warning',
					'Importa Destinatari',
					'Nessun nuovo destinatario. Stai caricando il file Excel non aggiornato o potresti aver caricato il file Excel non corretto.'
				);

			}
		} catch (error) {
			console.error('Error uploading Excel file:', error);
		} finally {
			this.loading = false;
			this.showProgressSend = false;
		}
	}

	addClient() {
		if (!this.isClickedNewUser) {
			const newClient: DataItem = {
				name: '',
				nameId: '',
				role: '',
				roleCode: '',
				address: '',
				isComplete: false,
				tratti: [],
				isSended: false,
				isInProgress: false,
				stateCompilation: 0,
				comments: '',
				status: this.listStateOfQuestionaire[0]
			};


			this.allElements.unshift({ ...newClient });
			this.listOfData = [...this.allElements];
			this.editingRow = 0;
			this.isClickedNewUser = true;
		}
	}

	showModal(typeModal: string) {
		if (typeModal === 'sendQuestionnaires') {
			this.isVisibleSendQuestionnaires = true;
			this.listOfDataToSend = this.allElements;
			this.hasListOfDataToSend = this.listOfDataToSend.filter(item => item.status === this.listStateOfQuestionaire[0]).length > 0
		}
		else if (typeModal === 'elaboratorQuestionnaires') {
			let userData = this.auth.getUser();
			this.completeQuestionnaires = this.allElements.filter(item => item.isComplete === true);
			this.isTitolari = this.receivedObject?.vers?.titolo.ITA.toLowerCase().includes('titolari');
			if (this.isTitolari)
				this.completeQuestionnaires = this.allElements.filter(item => item.isComplete === true && item.address.trim().toLowerCase() === userData.email.toLowerCase())

			if (this.completeQuestionnaires.length === 0) {
				this.notification.create(
					'warning',
					'Elaboratore Schede',
					'Non ci sono questionari completati'
				);
			}
			else {
				this.generateChart(this.completeQuestionnaires[0]);
				this.isVisibleElaboratorQuestionnaires = true;
				this.listOfDataToSend = this.listOfData;
			}
		}
		else {


		}
	}

	handleOk(typeModal: string): void {
		if (typeModal === 'sendQuestionnaires')
			this.isVisibleSendQuestionnaires = false;
		else
			this.isVisibleElaboratorQuestionnaires = false;
	}

	handleCancel(typeModal: string): void {
		this.setOfCheckedId = new Set<string>();

		this.showProgressSend = false;

		if (typeModal === 'sendQuestionnaires') {
			this.isVisibleSendQuestionnaires = false;
			this.listOfDataToSend = [];
		}
		else if (typeModal === 'elaboratorQuestionnaires') {
			this.isVisibleElaboratorQuestionnaires = false;
			this.listOfDataToSend = [];
		}
		else {


		}
	}

	updateCheckedSet(id: string, checked: boolean): void {
		if (checked) {
			this.setOfCheckedId.add(id);
		} else {
			this.setOfCheckedId.delete(id);
		}
	}

	onCurrentPageDataChange(listOfCurrentPageData: readonly Data[]): void {
		this.listOfCurrentPageData = listOfCurrentPageData;
		this.refreshCheckedStatus();
	}

	refreshCheckedStatus(): void {
		this.isActiveSend();
		const listOfEnabledData = this.listOfCurrentPageData.filter(item => item.isComplete !== true);
		this.checked = listOfEnabledData.every(({ address }) => this.setOfCheckedId.has(address));
		this.indeterminate = listOfEnabledData.some(({ address }) => this.setOfCheckedId.has(address)) && !this.checked;
	}

	onItemChecked(id: string, checked: boolean): void {
		this.updateCheckedSet(id, checked);
		this.refreshCheckedStatus();
	}

	onAllChecked(checked: boolean): void {
		this.listOfCurrentPageData
			.filter(item => this.isClima || item.isComplete !== true)
			.forEach(({ address }) => this.updateCheckedSet(address, checked));
		this.refreshCheckedStatus();
	}

	isActiveSend() {
		const filteredElements = this.allElements.filter(item => this.setOfCheckedId.has(item.address));
		this.countLinkToSend = filteredElements.filter(item => item.status === this.listStateOfQuestionaire[0]).length;
		const nameLicence = (this.countLinkToSend > 1) ? "Licenze" : "Licenza";

		if (this.setOfCheckedId.size < 1) {
			this.textSend = 'Seleziona i Destinatari';
		} else {
			let nameQuestionario = this.setOfCheckedId.size > 1 ? 'Questionari' : 'Questionario';
			this.textSend = `Stai per reinviare ${this.setOfCheckedId.size} ${nameQuestionario}.`;
		}

		if (this.countLinkToSend > 0) {
			this.textSend = (this.licenzeDisponibili - this.countLinkToSend >= 0) ?
				`Stai per utilizzare ${this.countLinkToSend} ${nameLicence} su ${this.licenzeDisponibili} Disponibili` :
				'Licenze Terminate!';
		}

		this.isActive = this.setOfCheckedId.size === 0 || this.countLinkToSend > this.licenzeDisponibili;
	}

	async sendRequest(setAddress) {
		this.loading = true;
		this.isVisibleSendQuestionnaires = false;
		let copySetOfCheckedId = new Set<string>(this.setOfCheckedId);
		this.setOfCheckedId = new Set<string>();
		try {
			this.showProgressSend = true;
			let listAddress = Array.from(copySetOfCheckedId)
			let i = 0;
			let numeroLicenze = this.receivedObject.utilizzate;

			for (const [i, element] of listAddress.entries()) {
				let index = this.allElements.findIndex(item => item.address === element && !item.isComplete);
				if (index !== -1) {
					this.allElements[index].status = this.listOfDataToSend[index].status = this.listOfDataToSend[index].status === this.listStateOfQuestionaire[2] ? this.listOfDataToSend[index].status : this.listStateOfQuestionaire[1];

					this.percentSend = Math.round(((i + 1) / listAddress.length) * 100) ?? 0;
					this.pecentSendString = listAddress.length > 0 ? `Inviati: ${i + 1}/${listAddress.length}` : '';

					let userIndex = this.listOfDataToSend.findIndex(item => item.address === element && !item.isComplete)

					try {
						const elemMessaggio = `${this.emailMessaggio}<p> Da compilare prima del <b>${this.expireDate}</b>.</p>`
						numeroLicenze = await this.g.userSendLinkAmajor(this.userAzienda, this.receivedObject, this.listOfDataToSend[userIndex], numeroLicenze, elemMessaggio);
					} catch (error) {

					}
				}
			}

			let questionarioName = listAddress.length > 1 ? 'Questionari' : 'Questionario'
			this.notification.create('success', 'Invio Link', `Hai Inviato ${listAddress.length} ${questionarioName}!`);
			this.loading = false;
			this.isVisibleSendQuestionnaires = true;

			// UPDATE STATISTIC
			this.numberOfInProgress = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[2]).length;
			this.numberOfSend = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[1]).length;
			this.numberOfComplete = this.allElements.filter(item => item.status === this.listStateOfQuestionaire[3]).length;
			this.numberOfLicenceUsed = numeroLicenze;
			this.licenzeDisponibili = (this.receivedObject.totali - this.receivedObject.utilizzate);


		}
		catch (error) {
			this.notification.create(
				'error',
				'Invio Questionari',
				'NON è stato possibile inviare il Questionario'
			);

		}
		finally {
			this.showProgressSend = false;
		}
	}

	filterByRepartOfSend(repart: string, isForComplete: boolean = false) {
		if (repart === 'Tutti')
			this.completeQuestionnaires = this.listOfDataToSend = isForComplete ? this.allElements.filter(item => item.isComplete === true) : this.allElements;
		else
			this.completeQuestionnaires = this.listOfDataToSend = this.allElements.filter(d => isForComplete ? (d.role === repart && d.isComplete) : d.role === repart);

		if (this.completeQuestionnaires.length > 0) {
			this.generateChart(this.completeQuestionnaires[0]);
			this.commentChart = this.completeQuestionnaires[0].comments ?? '';
		}
	}

	isExpiredQuestionnaire() {
		if (this.receivedObject?.scadenza?.seconds) {
			const expirationTime = this.receivedObject.scadenza.seconds * 1000;
			return Date.now() > expirationTime;
		}
		return false;
	}

}
