<nz-content>
  <div class="uiContainer">

    <nz-sider class="uiClienteSidebar" nzCollapsible nzBreakpoint="md"
      [nzCollapsedWidth]="80"
      [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
      <button id='buttonMenu' nz-button (click)="toggleCollapsed()">
        <span nz-icon [nzType]="isCollapsed ? 'plus' : 'minus'"></span>
      </button>

      <div>
        <a href="/">
          <img *ngIf="logo && !isCollapsed" class="uiClienteLogo" [src]="logo"
            alt="logo" />
        </a>
      </div>

      <ul class="uiClienteMenu" nz-menu nzTheme="light" nzMode="inline">
        <li nz-menu-item class="uiClienteMenuItem"
          nz-tooltip [nzTooltipTitle]="isCollapsed ? 'Analizza' : ''"
          nzTooltipPlacement="right"
          [nzSelected]="router.url.includes('/questionari')">
          <a (click)="navigate('/questionari')">
            <span class="icon-text">
              <i nz-icon nzType="control" nzTheme="outline"></i>
              <span class="text">Analizza</span>
            </span>
          </a>
        </li>
        <li   nz-menu-item class="uiClienteMenuItem"
          [nzSelected]="router.url.includes('/richiedi')"
          nz-tooltip [nzTooltipTitle]="isCollapsed ? 'Richeidi' : ''"
          nzTooltipPlacement="right"
          [nzDisabled]='true'>
          <a (click)="navigate('/richiedi')">
            <span class="icon-text">
              <i nz-icon nzType="shop" nzTheme="outline"></i>
              <span class="text">Richiedi</span>
            </span>
          </a>
        </li>
        <li nz-menu-item class="uiClienteMenuItem"
          [nzSelected]="router.url.includes('/imposta')"
          nz-tooltip [nzTooltipTitle]="isCollapsed ? 'Imposta' : ''"
          nzTooltipPlacement="right"
          >
          <a (click)="navigate('/imposta')">
            <span class="icon-text">
              <i nz-icon nzType="setting" nzTheme="outline"></i>
              <span class="text">Imposta</span>
            </span>
          </a>
        </li>
      </ul>
    </nz-sider>

    <nz-layout class="uiAdminInnerLayout">
      <nz-header class="uiClienteHeader">
        <div nz-row class="uiCienteHeaderRow">
          <div class="uiClienteHeaderLeftCol" nz-col nzSpan="8">
            <div class="uiTitleContainer"> <a href="/"><img
                  class="uiUncollapsedLogo" src="../../../assets/logo-light.png"
                  alt="logo"></a></div>
          </div>

        </div>
        <button class="logoutButton" *ngIf="auth.isLoggedIn()" nz-button
          nz-popconfirm
          nzPopconfirmTitle="Sei sicuro di volere uscire?"
          nzShape="circle"
          (nzOnConfirm)="auth.logout()"><span nz-icon
            nzType="logout"></span></button>
      </nz-header>
      <nz-content class="uiAdminContent">
        <div class="uiAdminInnerContent">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </div>

</nz-content>