<nz-layout class="uiPageInnerLayout" (window:scroll)="onScroll()">
    <nz-input-group id="searchInput" nzSearch class="search-input">
        <input
            placeholder="Cerca..."
            nz-input
            (input)="onInput($event)"
            (keyup.enter)="search()"
            [nzAutocomplete]="auto"
            [(ngModel)]="searchText"
            #searchInput />
        <button nz-button nzSize="small" nzType="text" (click)="clearSearch()"
            class="clear-button" *ngIf="searchText">
            <i nz-icon nzType="close-circle"></i>
        </button>
        <nz-autocomplete [nzDataSource]="options" nzBackfill
            #auto></nz-autocomplete>
    </nz-input-group>

    <nz-tabset nzCentered (nzSelectedIndexChange)="tabChanged($event)">
        <!-- Dynamic tabs from typeQuestionnaire -->
        <nz-tab *ngFor="let tab of typeQuestionnaire; let i = index"
            [nzTitle]="tab + ' ( ' + tabsCount[i] + ' )'">
            <ng-template #tabContent>
                <ng-container *ngTemplateOutlet="cardContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </nz-tab>
    </nz-tabset>

    <ng-template #avatarTemplateAnalisi>
        <div class="avatar-bottom-right">
            <nz-avatar
                nzSrc="../../../assets/analisi-logo.png"></nz-avatar>
        </div>
    </ng-template>

    <ng-template #avatarTemplateClima>
        <div class="avatar-bottom-right">
            <nz-avatar
                nzSrc="../../../assets/clima-logo.png">
            </nz-avatar>
        </div>
    </ng-template>

    <ng-template #avatarTemplateSelezioni>
        <div  class="avatar-bottom-right">
            <nz-avatar
            nzSrc="../../../assets/selezioni-logo.png"></nz-avatar>
        </div>
    </ng-template>

    <ng-template #cardContent>
        <div class="grid-container">
            <div class="grid-data" *ngFor="let data of filteredItems"
                (click)="showAnalysis(data)">
                <nz-card [nzActions]="getActions(data)">
                    <nz-skeleton [nzActive]="true" [nzLoading]="loading"
                        [nzAvatar]="{ size: 'large' }">
                        <nz-card-meta
                            [nzAvatar]="getAvatarTemplate(data)"
                            [nzTitle]="data.vers.titolo.ITA"
                            [nzDescription]="'Scadenza: ' + (data.scadenza.seconds * 1000 | date: 'dd/MM/yyyy')">
                        </nz-card-meta>
                        <nz-row class="bottom-row">
                            <nz-col>
                                <nz-statistic
                                    [nzValue]="getSumLicenceUsed(data).toString().concat('/', data.totali)"
                                    [nzTitle]="'Utilizzate'"></nz-statistic>
                            </nz-col>
                        </nz-row>
                    </nz-skeleton>
                </nz-card>
            </div>
            <div class="loading-message" *ngIf="loading"><span nz-icon
                    [nzType]="'sync'" [nzSpin]="true"></span></div>
            <div class="no-datas-message"
                *ngIf=" !loading && filteredItems.length === 0">
                <nz-empty></nz-empty> </div>
        </div>
    </ng-template>

    <ng-template #clearButton>
        <button nz-button nzSize="small" nzType="text" (click)="clearSearch()"
            class="clear-button">
            <span nz-icon nzType="close-circle"></span>
        </button>
    </ng-template>

    <nz-row *ngIf="!haveLicence" nzJustify="center" nzAlign="middle"
        style="height: 100vh;">
        <nz-col nzSpan="12">
            <nz-empty
                nzNotFoundContent="Nessuna licenza associata"
                style="font-size: 24px;"></nz-empty>
        </nz-col>
    </nz-row>

    <div class="waiter-overlay" *ngIf="loading">
        <div class="waiter">
            <span class="loader">
                <i class="loader-dot"></i>
                <i class="loader-dot"></i>
                <i class="loader-dot"></i>
                <i class="loader-dot"></i>
            </span>
        </div>
    </div>

</nz-layout>
